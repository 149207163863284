import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { EntityList } from '@common/EntityList';
import { unitSizes } from '@common/enums';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { DayNumberService } from '@services/day-number.service';
import { AllocationService } from '@services/allocation.service';
import { AllocationDialogController } from './allocation-dialog.controller';
import { CopyAllocationDialogController } from './copyallocation-dialog.controller';
import { BaseController } from 'src/app/base.controller';

export class AllocationsController extends BaseController {
  public entityList: EntityList<fuse.allocationDto>;
  public showActive = true;
  public showArchived = false;
  public nameFilter = '';
  public referenceFilter = '';
  public filteredAllocations: fuse.allocationDto[];
  public volumeHugeUnit: string;
  public currentDate: Date;
  public currentDay: number;
  public isCurrentEnabled = false;

  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _allocationService: AllocationService;
  private _dayNumberService: DayNumberService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    AllocationService: AllocationService,
    DayNumberService: DayNumberService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._state = $state;
    this._allocationService = AllocationService;
    this._dayNumberService = DayNumberService;

    this.volumeHugeUnit = UnitOfMeasureService.getUnitLabel('Volume', unitSizes.huge);

    this.currentDate = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    this.currentDay = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.currentDate);
  }

  $onInit() {
    this.getAllocations();
  }

  private getAllocations() {
    const after = (data: fuse.allocationDto[]) => {
      this.entityList = new EntityList(data);
    };

    return this._allocationService.getAllocations(after);
  }

  public changeDate() {
    this.currentDay = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.currentDate);
    this.toggleCurrentAs();
  }

  public gotoAllocation(allocationId: number) {
    this._state.go('app.water.allocations.detail', { id: allocationId });
  }

  public add() {
    this._mdDialog
      .show({
        controller: AllocationDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/allocations/allocation-dialog.html',
        parent: angular.element(document.body),
        locals: {
          allocations: this.entityList.entities,
          assetId: null,
        },
      })
      .then((res) => {
        if (res) {
          this._state.go('app.water.allocations.detail', {
            id: res,
          });
        }
      });
  }

  public edit(allocation: fuse.allocationDto) {
    this._mdDialog
      .show({
        controller: AllocationDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/allocations/allocation-dialog.html',
        parent: angular.element(document.body),
        locals: {
          allocations: this.entityList.entities,
          assetId: allocation.id,
        },
      })
      .then((res) => {
        if (res) {
          this.getAllocations();
        }
      });
  }

  public copy(allocation: fuse.allocationDto) {
    this._mdDialog
      .show({
        clickOutsideToClose: false,
        escapeToClose: false,
        controller: CopyAllocationDialogController,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/water/allocations/copyallocation-dialog.html',
        locals: {
          allocations: this.entityList.entities,
          assetId: allocation.id,
        },
      })
      .then((res) => {
        if (res) {
          this.getAllocations();
        }
      });
  }

  public clearFilter(): void {
    this.nameFilter = '';
    this.referenceFilter = '';
  }

  public toggleCurrentAs() {
    this.entityList.entities.forEach((allocation) => {
      if (this.isCurrentEnabled) {
        if (allocation.effectiveFromDay <= this.currentDay && this.currentDay <= allocation.effectiveToDay) {
          allocation.isCurrentAs = true;
        } else {
          allocation.isCurrentAs = false;
        }
      } else {
        allocation.isCurrentAs = false;
      }
    });
  }

  public openKnowledgeBase() {
    const baseUrlWithoutTrailingSlash = AppSettings.app.knowledgeBaseUrl.replace(/\/$/, '');

    window.open(`${baseUrlWithoutTrailingSlash}/books/water-supply-module`, '_blank');
  }
}

angular.module('app.water').controller('AllocationsController', AllocationsController);
