// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#copy-allocation md-input-container {
  margin: 16px 0;
  padding: 0;
  vertical-align: bottom;
}
#copy-allocation md-input-container .md-errors-spacer {
  min-height: 0px;
}
#copy-allocation label {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/allocations/copyallocation-dialog.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,UAAA;EACA,sBAAA;AAAJ;AAEI;EACE,eAAA;AAAN;AAIE;EACE,iBAAA;AAFJ","sourcesContent":["#copy-allocation {\r\n  md-input-container {\r\n    margin: 16px 0;\r\n    padding: 0;\r\n    vertical-align: bottom;\r\n\r\n    .md-errors-spacer {\r\n      min-height: 0px;\r\n    }\r\n  }\r\n\r\n  label {\r\n    font-weight: bold;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
