import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { LanguageService } from '@services/language.service';
import { BaseController } from 'src/app/base.controller';

export class SiteGraphDialogController extends BaseController {
  public title: string;
  public sitePattern: string;
  private siteIrrigationInfos: fuse.siteIrrigationInfoDto[];
  public siteIrrigationSummary: fuse.siteIrrigationInfoDto[];
  public forecastRainUsePct: number;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    siteInfo: fuse.irrigationPlanSite,
    siteIrrigationInfos: fuse.siteIrrigationInfoDto[],
    forecastRainUsePct: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.siteIrrigationInfos = siteIrrigationInfos;
    this.title = `${siteInfo.siteName} ${LanguageService.instant('COMMON.SMB')}`;
    this.sitePattern = siteInfo.sitePattern;
    this.forecastRainUsePct = forecastRainUsePct;
  }

  $onInit() {
    this.siteIrrigationSummary = this.siteIrrigationInfos;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.water').controller('SiteGraphDialogController', SiteGraphDialogController);
