// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#allocation-dialog md-dialog-content {
  padding: 16px 24px;
}
#allocation-dialog md-dialog-content md-input-container {
  margin: 0 0 8px;
  padding: 8px;
}
#allocation-dialog md-dialog-content md-input-container md-datepicker {
  display: block;
}
#allocation-dialog md-dialog-content md-input-container md-datepicker .md-datepicker-button {
  display: none;
}
#allocation-dialog md-dialog-content md-input-container .md-errors-spacer {
  min-height: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/allocations/allocation-dialog.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEI;EACE,eAAA;EACA,YAAA;AAAN;AAEM;EACE,cAAA;AAAR;AAEQ;EACE,aAAA;AAAV;AAIM;EACE,eAAA;AAFR","sourcesContent":["#allocation-dialog {\r\n  md-dialog-content {\r\n    padding: 16px 24px;\r\n\r\n    md-input-container {\r\n      margin: 0 0 8px;\r\n      padding: 8px;\r\n\r\n      md-datepicker {\r\n        display: block;\r\n\r\n        .md-datepicker-button {\r\n          display: none;\r\n        }\r\n      }\r\n\r\n      .md-errors-spacer {\r\n        min-height: 0px;\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
