import * as angular from 'angular';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { StatusEnum } from '@indicina/swan-shared/enums/StatusEnum';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { AllocationService } from '@services/allocation.service';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

export class AllocationDialogController extends BaseController {
  public title: string;
  public allocation: fuse.allocationDto;
  public allocations = [] as fuse.allocationDto[];
  public allocationForm: angular.IFormController;
  public volumeHugeUnit: string;
  public waterSources = [] as fuse.allocWaterSourceDto[];
  public assetStatuses: string[];
  public error: any;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _allocationService: AllocationService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  private assetId: number;
  private assetClass: fuse.assetClassDto;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    AllocationService: AllocationService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    allocations: fuse.allocationDto[],
    assetId: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._allocationService = AllocationService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.assetId = assetId;
    this.allocations = allocations;
    this.assetStatuses = SWANConstants.AssetStatuses;
    this.volumeHugeUnit = UnitOfMeasureService.getUnitLabel('Volume', unitSizes.huge);
    this.assetClass = SWANConstants.assetClasses.find((a) => a.name == AssetClassNameEnum.Allocation);
  }

  $onInit() {
    const promises = [];
    this.title = this._languageService.instant('ALLOCATION.ADD_ALLOCATION');
    promises.push(
      this._allocationService.getAllocations((res) => {
        this.allocations = res;
      }),
    );

    promises.push(this.getWaterSources());

    this._q.all(promises).then(() => {
      const adjustedTodayDay = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();

      this.allocation = {
        assetClassId: this.assetClass.id,
        status: StatusEnum.Active,
        effectiveFromDate: this._dayNumberService.convertDayNumberToLocaleDate(adjustedTodayDay),
        obsAllocations: [{ comments: this._languageService.instant('ALLOCATION.INITIAL_VOLUME') }],
        waterSources: [],
      } as fuse.allocationDto;
    });
  }

  private getWaterSources(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl('allocation/watersources')).then((res) => {
      this.waterSources = res.data as fuse.allocWaterSourceDto[];
      defer.resolve();
    });
    return defer.promise;
  }

  public closeDialog() {
    this._allocationService.setNoChanges();
    this._mdDialog.hide();
  }

  public checkName() {
    this._allocationService.checkName(this.allocation, this.allocations, this.allocationForm);
  }

  public checkDates() {
    this._allocationService.checkAllocDates(this.allocationForm, this.allocation);
  }

  public save() {
    // check watersource overlap

    this.allocation.waterSources = this.waterSources.filter((ws) => ws.selected);
    this.error = this._allocationService.checkWaterSources(this.allocation, this.allocations);

    if (this.error) {
      return;
    }

    this.allocation.effectiveFromDay = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.allocation.effectiveFromDate);
    this.allocation.effectiveToDay = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.allocation.effectiveToDate);

    this._http.put(CommonHelper.getApiUrl('allocation/allocation'), this.allocation).then(
      (res) => {
        this._mdDialog.hide();
        const assetId = res.data as number;
        this._allocationService.setNoChanges();
        this._state.go('app.water.allocations.detail', { id: assetId });
      },
      () => {
        this._languageService.whoops();
      },
    );
  }
}

angular.module('app.water').controller('AllocationDialogController', AllocationDialogController);
