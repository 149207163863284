// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#water-allocations md-checkbox {
  margin: 0;
}
#water-allocations md-input-container {
  margin: 0 0 8px;
  padding: 0;
}
#water-allocations md-input-container .md-errors-spacer {
  min-height: 0px;
}
#water-allocations md-input-container .md-input {
  color: white;
  border-color: white;
}
#water-allocations md-input-container .md-datepicker-expand-triangle {
  border-top-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/allocations/allocations.scss"],"names":[],"mappings":"AACE;EACE,SAAA;AAAJ;AAGE;EACE,eAAA;EACA,UAAA;AADJ;AAGI;EACE,eAAA;AADN;AAII;EACE,YAAA;EACA,mBAAA;AAFN;AAKI;EACE,sBAAA;AAHN","sourcesContent":["#water-allocations {\r\n  md-checkbox {\r\n    margin: 0;\r\n  }\r\n\r\n  md-input-container {\r\n    margin: 0 0 8px;\r\n    padding: 0;\r\n\r\n    .md-errors-spacer {\r\n      min-height: 0px;\r\n    }\r\n\r\n    .md-input {\r\n      color: white;\r\n      border-color: white;\r\n    }\r\n\r\n    .md-datepicker-expand-triangle {\r\n      border-top-color: #fff;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
