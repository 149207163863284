import * as angular from 'angular';
import * as $ from 'jquery';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { UnitTypes } from '@common/enums';
import { IIdNameItem, IDropListItem } from '@common/models/interfaces';
import { ApiService } from '@services/api.service';
import { DataEntityService } from '@services/data-entity.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { EntityListService } from '@services/entity-list.service';
import { IrrigationControllerService } from '@services/irrigation-controller.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { TimeService } from '@services/time.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { DayNumberService } from '@services/day-number.service';
import { ConfirmProgramController } from './confirm-program-dialog.controller';
import { Asset } from 'src/app/_DBContext/Asset';
import { DataCollectionProfile } from 'src/app/_DBContext/DataCollectionProfile';
import { IrrigationController } from 'src/app/_DBContext/IrrigationController';
import { IrrigationProtocol } from 'src/app/_DBContext/IrrigationProtocol';
import { ProtocolDialogController } from './protocol-dialog.controller';
import { ProgramConflictController } from './program-conflict-dialog.controller';
import { BaseController } from 'src/app/base.controller';

export interface ProgramDay {
  dayNumber: number;
  date: Date;
  selected: boolean;
}
export class IrrigationControllerController extends BaseController {
  public primaryTab: number;

  public isSavingChanges = false;
  public displayUnits: IIdNameItem[];

  public assetId: number;
  public asset: Asset;
  public irrigationController: IrrigationController;
  public profile: DataCollectionProfile;
  public statuses: IDropListItem[];

  public isLoaded = false;
  public isChanged = false;
  public inProgress: boolean = true;
  public nameFilter = '';
  public otherControllers: fuse.listAssetDto[];
  public protocols: IrrigationProtocol[] = [];

  public tabTitle = 'PROGRAMMING';
  public currentTabSource = 'src/app/pages/water/irrigation-controller/programming.html';
  public controllerTabs = ['PROGRAMMING', 'IRRIGATION_REQUESTS', 'SETTINGS', 'PROTOCOLS', 'LOGS'];
  private _tabSource = {
    PROGRAMMING: 'src/app/pages/water/irrigation-controller/programming.html',
    IRRIGATION_REQUESTS: 'src/app/pages/water/irrigation-controller/history.html',
    SETTINGS: 'src/app/pages/water/irrigation-controller/settings.html',
    PROTOCOLS: 'src/app/pages/water/irrigation-controller/protocols.html',
    LOGS: '',
  };
  public timeInputError = 'Must be HH:mm';

  public programDays: ProgramDay[] = [];
  public selectedDays: ProgramDay[] = [];
  public controllerSites: fuse.controllerSitesDto[];

  public maxIrrigation: number;
  public todayDayNumber: number;
  public fluidDepthUnit: uomUnit;

  public historyDate: Date;

  public irrigationVars = {
    irrigationPlanned: { colour: '#4444AA', title: 'CONTROLLERS.WATER_REQUIREMENT' },
    irrigationProgrammed: { colour: '#7070FF', title: 'CONTROLLERS.PROGRAMMED_IRRIGATION' },
    fertigationPlanned: { colour: '#CC7722', title: 'CONTROLLERS.FERTIGATION_REQUIREMENT' },
    fertigationProgrammed: { colour: '#FFAA44', title: 'CONTROLLERS.PROGRAMMED_FERTIGATION' },
  };
  public irrigationKeys = [];
  public programData: fuse.controllerProgram;
  private irrigationLocalStartTimeInMinutes: number;
  public overrides: any;
  public selectedProtocol: any;
  public chart: AmCharts.AmSerialChart;

  public historyNotFound: boolean = false;
  public noIrrigMsg = 'NO_IRRIG';
  public noSitesMsg = 'NO_SITES';
  public historyMsg = this.noIrrigMsg;

  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _apiService: ApiService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _dupeHandlerService: DupeHandlerService;
  private _entityListService: EntityListService;
  private _irrigationControllerService: IrrigationControllerService;
  private _languageService: LanguageService;
  private _timeService: TimeService;

  private httpCanceller: angular.IDeferred<any>; // Used to cancel irrigation history requests to Irriopt if 2+ sent close together

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    ApiService: ApiService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    DupeHandlerService: DupeHandlerService,
    EntityListService: EntityListService,
    IrrigationControllerService: IrrigationControllerService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    TimeService: TimeService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._apiService = ApiService;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._dupeHandlerService = DupeHandlerService;
    this._entityListService = EntityListService;
    this._irrigationControllerService = IrrigationControllerService;
    this._languageService = LanguageService;
    this._timeService = TimeService;

    this.entityManager = DataEntityService.manager;
    this._dupeHandlerService.setDuplicateType('Irrigation Plan');

    this.irrigationKeys = Object.keys(this.irrigationVars);
    this.httpCanceller = this._q.defer();

    this.fluidDepthUnit = UnitOfMeasureService.getUnits(UnitTypes.FluidDepth);

    const context = LocalStorageUtils.contextData;

    this.assetId = context.assetId;
    this.irrigationLocalStartTimeInMinutes = this.account.irrigationLocalStartTimeInMinutes;
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  $onInit() {
    this.fetchSettings(this.assetId);
    this.getControllerSites();
    this.fetchProtocols();
    this._entityListService.getAssetList(this.accountId, AssetClassNameEnum.IrrigationController, (result) => {
      this.otherControllers = result;
    });
  }

  public updateSelectedDays() {
    this.selectedDays = this.programDays.filter((day) => day.selected);
  }

  public protocolsDialog(locals) {
    return this._mdDialog.show({
      controller: ProtocolDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-controller/protocol-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: locals,
    });
  }

  public buildProgram() {
    // controllers with multiple optional parameters have trouble initialising post-upgrade, so passing args as object now instead
    const locals = {
      params: {
        assetId: this.assetId,
        irrigStartTime: this.irrigationLocalStartTimeInMinutes,
        fertigationEnabled: this.irrigationController.FertigationEnabled,
        protocols: this.protocols,
        days: this.selectedDays,
      },
    };
    this.protocolsDialog(locals).then((protocol) => {
      if (!protocol) {
        this.rejectProtocolChanges();
        return;
      }
      this.selectedProtocol = protocol;
      this.initBuildProgram();
    });
  }

  public initBuildProgram() {
    const protocol = this.selectedProtocol;
    const program = {
      assetId: protocol.AssetId,
      windowStart: protocol.WindowStartDisplay,
      windowEnd: protocol.WindowEndDisplay,
      minimumPulseMinutes: protocol.MinimumPulseMinutesDisplay,
      gapMinutes: protocol.GapMinutesDisplay,
      fertigationEnabled: this.irrigationController.FertigationEnabled,
      fertigationPulse: protocol.FertigationPulse,
      pulses: protocol.Pulses,

      days: this.selectedDays.map((day) => day.dayNumber),
      siteIds: this.controllerSites.map((site) => site.siteId),
      overrides: this.overrides,
    } as fuse.controllerProtocolDto;

    const successCallback = (response) => {
      this.programData = response as fuse.controllerProgram;
      if (this.programData.controllerConflicts) {
        if (this.overrides != null) {
          // Conflicts should have been resolved by this point
          this._languageService.error('CONTROLLERS.BUILD_ERROR');
        }
        this.conflictsDialog(this.programData.controllerConflicts);
      } else {
        this.confirmProgram();
      }
    }
    const failCallback = (saveFailed) => {
      this._languageService.error('CONTROLLERS.BUILD_PROGRAM_ERROR');
    }

    this._apiService.postGeneric('controllers/buildProgram', program, successCallback, failCallback);
  }

  /** Changes to program protocols made during send-program-to-controller shouldn't be saved */
  public rejectProtocolChanges() {
    this.protocols.forEach((proto) => proto.entityAspect?.rejectChanges());
  }

  public conflictsDialog(conflicts: fuse.controllerConflict[]) {
    this._mdDialog
      .show({
        controller: ProgramConflictController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/irrigation-controller/program-conflict-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: { conflicts: conflicts, fertigationEnabled: this.irrigationController.FertigationEnabled },
      })
      .then((response) => {
        if (response) {
          this.overrides = response;
          this.initBuildProgram();
        }
      });
  }

  public confirmProgram() {
    const locals = {
      program: this.programData,
    };
    const sendSuccess = (response) => {
      this._languageService.success(response);
      this.getControllerSites();
    }
    const sendError = (error) => {
      if (error.status == 403) {
        this._languageService.error('CONTROLLERS.SEND_PROGRAM_ERROR');
        this.getControllerSites();
        return;
      }
      const alert = this._mdDialog
        .alert()
        .title(this._languageService.instant('CONTROLLERS.SEND_ERROR'))
        .htmlContent(this._languageService.instant(error.data.Message))
        .parent(angular.element(document.body))
        .ok(this._languageService.instant('COMMON.CLOSE'))
        .targetEvent();
      this._mdDialog.show(alert).then(() => {
        this._mdDialog.hide();
        this.inProgress = false;
      });
    }
    this._mdDialog
      .show({
        controller: ConfirmProgramController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/irrigation-controller/confirm-program-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: locals,
      })
      .then((program) => {
        if (program) {
          this.inProgress = true;
          this._apiService.postGeneric('controllers/sendProgram', program, sendSuccess, sendError);
        }
        this.rejectProtocolChanges();
        this.overrides = null;
      });
  }

  public deleteProgram() {
    const days = this.selectedDays.map((d) => d.date).join('<br>');
    let msg = '<br>' + this._languageService.instant('CONTROLLERS.CONFIRM_MSG') + `<br>${days}<br>`;
    const dayNums = this.selectedDays.map((d) => d.dayNumber);
    if (dayNums.find((d) => d == this.todayDayNumber)) {
      msg += '<p>' + this._languageService.instant('CONTROLLERS.CONFIRM_TODAY');
    }

    const program = {
      assetId: this.assetId,

      // Placeholders - these values are not used
      windowStart: '00:00',
      windowEnd: '00:00',
      minimumPulseMinutes: '00:00',
      fertigationPulse: '',
      pulses: 0,

      days: this.selectedDays.map((day) => day.dayNumber),
      siteIds: this.controllerSites.map((site) => site.siteId),
    };

    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('CONTROLLERS.DELETE_IRRIGATION'))
      .htmlContent(msg)
      .ariaLabel('disable irrigation')
      .multiple(true)
      .ok(this._languageService.instant('COMMON.DELETE'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));
    this._mdDialog.show(confirm).then(() => {
      this.inProgress = true;
      this._apiService.postGeneric(
        'controllers/deleteProgram',
        program,
        (response) => {
          this._languageService.success(response);
          this.getControllerSites();
        },
        (error) => {
          console.log(error);
          this._languageService.error(error.data.Message);
          this.getControllerSites();
        },
      );
    });
  }

  public addNewProtocol(ev: MouseEvent) {
    const locals = {
      params: {
        assetId: this.assetId,
        irrigStartTime: this.irrigationLocalStartTimeInMinutes,
        fertigationEnabled: this.irrigationController.FertigationEnabled,
      },
    };
    this.protocolsDialog(locals).then((protocol) => {
      if (protocol) {
        const entityType = this.entityManager.metadataStore.getEntityType('IrrigationProtocol') as breeze.EntityType;
        const newProtocol = entityType.createEntity(protocol);

        this.entityManager.addEntity(newProtocol);
        this.entityManager.saveChanges([newProtocol]).then(
          (result) => {
            this._languageService.success('CONTROLLERS.PROTOCOL_SAVED');
            this.fetchProtocols();
          },
          (error) => {
            console.log(error);
          },
        );
      }
    });
  }

  public editProtocol(protocol: IrrigationProtocol) {
    const locals = {
      params: {
        assetId: null,
        irrigStartTime: this.irrigationLocalStartTimeInMinutes,
        fertigationEnabled: this.irrigationController.FertigationEnabled,
        protocol: protocol,
        protocols: null,
        days: null,
      },
    };

    this.protocolsDialog(locals).then((protocol) => {
      if (protocol) {
        this.entityManager.saveChanges().then(
          (result) => {
            this._languageService.success('CONTROLLERS.PROTOCOL_SAVED');
            this.fetchProtocols();
          },
          (error) => {
            console.log(error);
          },
        );
      }
    });
  }

  public deleteProtocol(protocol: IrrigationProtocol) {
    const confirm = this._mdDialog
      .confirm()
      .title('Delete protocol?')
      .htmlContent(this._languageService.instant('CONTROLLERS.DELETE_PROTOCOL', { name: protocol.Name }))
      .ariaLabel('delete protocol')
      .multiple(true)
      .ok(this._languageService.instant('COMMON.DELETE'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));

    this._mdDialog.show(confirm).then(() => {
      protocol.entityAspect.setDeleted();

      this._dataEntityService.saveChanges().then(
        (success) => {
          this._languageService.success('CONTROLLERS.PROTOCOL_DELETED', 'COMMON.SUCCESS', { name: protocol.Name });
          this.fetchProtocols();
        },
        (error) => {
          console.log(error);
          this._languageService.error('CONTROLLERS.DELETE_PROTOCOL_FAIL');
          this.fetchProtocols();
        },
      );
    });
  }

  private getControllerSites() {
    const afterFunc = (data) => {
      this.controllerSites = data as fuse.controllerSitesDto[];

      if (this.controllerSites?.length) {
        // Translate valves values.
        this.controllerSites.forEach((site) => {
          site.valves = site.valves.map((v) => this._languageService.instant(v));
        });

        // setup week days list
        const days = this.controllerSites.map((s) =>
          s.days.map((d) => d.dayNumber)
        )
        .reduce((acc, curr) => acc.concat(curr))
        .filter((d) => !!d);

        this.todayDayNumber = ArrayUtils.min(days);
        this.historyDate = this._dayNumberService.convertDayNumberToDate(this.todayDayNumber);
        this.programDays =
          ArrayUtils.range(0, ArrayUtils.max(days) - this.todayDayNumber).map(day => ({
            dayNumber: this.todayDayNumber + day,
            date: this._dayNumberService.convertDayNumberToDate(this.todayDayNumber + day),
            selected: false,
          })
        );
      }

      this.scaleIrrigation();
      this.inProgress = false;
    };

    this._apiService.getGeneric(
      'controllers/getControllerSites',
      { controllerId: this.assetId },
      afterFunc,
      'CONTROLLERS.ERROR_LOADING_SITES',
    );
  }

  private scaleIrrigation() {
    let max = 0;

    if (this.controllerSites) {
      // Find maximum value of any irrigation/fertigation for all sites day days
      this.controllerSites.forEach((site) => {
        site.days.forEach((day) => {
          const vals = this.irrigationKeys.map((v) => Number(day[v])).concat(max);
          max = Math.max(...vals);
        });
      });
    }
    this.maxIrrigation = max;
  }

  // Programming page: highlight day as 'outdated' if programmed value does not match planned
  public outdatedClass(day: fuse.programDayDto) {
    const outdated =
      (day.irrigationPlanned ?? 0) != (day.irrigationProgrammed ?? 0) ||
      (this.irrigationController?.FertigationEnabled && (day.fertigationPlanned ?? 0) != (day.fertigationProgrammed ?? 0));
    if (outdated) return 'outdated';
    return '';
  }

  // Programming page: style element size/colour to reflect irrigation/fertigation value for that day
  public getBarStyle(day: fuse.programDayDto, type: string) {
    // Scale irrigation/fertigation by maximum for this week
    const width = this.maxIrrigation !== 0 ? Math.floor((day[type] / this.maxIrrigation) * 100) : 0;

    return {
      'background-color': this.irrigationVars[type].colour,
      border: '1px solid black',
      height: '12px',
      margin: '2px',
      width: `${width}%`
    };
  }

  private fetchSettings(assetId: number) {
    const defer = this._q.defer();

    const pred = breeze.Predicate.create();
    const param = { assetId: assetId };

    const promise = breeze.EntityQuery.from('Asset')
      .expand('ControllerProfiles, IrrigationController')
      .withParameters(param)
      .where(pred)
      .using(this.entityManager)
      .execute();

    promise
      .then((data) => {
        this.asset = data.results[0] as Asset;
        this.irrigationController = this.asset.IrrigationController;
        this.entityManager.attachEntity(this.irrigationController);
        this.updateFertigationSettings();
        this.profile = this.asset.ControllerProfiles[0];
        defer.resolve();
      })
      .catch((reason) => {
        console.log(reason);
        defer.reject(reason);
      });
    return defer.promise;
  }

  public updateFertigationSettings() {
    if (!this.irrigationController.FertigationEnabled) {
      this.irrigationKeys = ['irrigationPlanned', 'irrigationProgrammed'];
    } else {
      this.irrigationKeys = Object.keys(this.irrigationVars);
    }
  }

  public parseTimesForDisplay() {
    const timeInputs = ['WindowStart', 'WindowEnd', 'MinimumPulseMinutes', 'GapMinutes'];
    this.protocols.forEach((protocol) => {
      timeInputs.forEach((input) => {
        protocol[input + 'Display'] = this._timeService.toReadable(protocol[input]);
      });
    });
  }

  public setTab(name: string) {
    this.tabTitle = name;
    this.currentTabSource = this._tabSource[name];

    if (name == 'IRRIGATION_REQUESTS') {
      this.getDailyIrrigation();
    }
  }

  private fetchProtocols() {
    const defer = this._q.defer<void>();

    breeze.EntityQuery.from('IrrigationProtocol')
      .withParameters({ assetId: this.assetId })
      .using(this.entityManager)
      .execute()
      .then(
        (data) => {
          this.protocols = data.results as IrrigationProtocol[];
          this.parseTimesForDisplay();
          defer.resolve();
        },
        (error) => {
          console.log(error);
          defer.reject();
        },
      );
  }

  public saveChanges(type = 'Irrigation Controller') {
    this._dataEntityService.saveChanges(true, this._dupeHandlerService.duplicatesOnly).then(
      (success) => {
        this._languageService.success('COMMON.CHANGES_SAVED');
        this.updateFertigationSettings(); // May need to show/hide fertigation if have changed settings
      },
      (error) => {
        this._dupeHandlerService.setDuplicateType(type);
        this._dupeHandlerService.showError(error);
      },
    );
  }

  public gotoList() {
    this._state.go('app.water.irrigation-controllers');
  }

  public gotoDetail(assetId: number) {
    this._irrigationControllerService.checkAndGotoController(assetId);
  }

  public gotoSiteDetail(site: fuse.controllerSitesDto) {
    LocalStorageUtils.updateContextData((context) => {
      context.siteId = site.siteId;
      context.assetId = site.siteId;
    });

    this._state.go('app.account.sites.detail', { id: site.siteId, viewSchedule: true });
  }

  public rejectChanges(): void {
    this._dataEntityService.rejectChanges();
  }
  public dailyIrrigation: fuse.dailyProgram[];

  public getDailyIrrigation() {
    const afterFunc = (data) => {
      this.dailyIrrigation = data;
      this.historyChart();
    };
    if (this.historyDate == null) {
      this.historyNotFound = true;
      this.historyMsg = this.noSitesMsg;
    } else {
      const dateStr = this.historyDate.toString('yyyy-MM-dd');
      this.httpCanceller = this._apiService.getGeneric(
        'controllers/getDailyIrrigation',
        { assetId: this.assetId, date: dateStr },
        afterFunc,
        true,
        this.httpCanceller,
      );
    }
  }

  public updateDay(days) {
    // weird hack to force md-datepicker value to recognise external update
    this.historyDate = new Date(this.historyDate.setDate(this.historyDate.getDate() + days));
    this.getDailyIrrigation();
  }

  private historyChart() {
    // If no data found for this date, flag and clear graph
    if (!this.dailyIrrigation.length) {
      this.chart?.clear();
      this.historyNotFound = true;
      this.historyMsg = this.noIrrigMsg;
      return;
    }

    this.historyNotFound = false;

    let anyFertig = false;
    this.dailyIrrigation.forEach((di) => {
      di.segments.forEach((s) => {
        if (s.fertigate) {
          anyFertig = true;
          s.colour = this.irrigationVars.fertigationPlanned.colour;
          if (s.optimised) s.colour = this.irrigationVars.fertigationProgrammed.colour;
        } else {
          s.colour = this.irrigationVars.irrigationPlanned.colour;
          if (s.optimised) s.colour = this.irrigationVars.irrigationProgrammed.colour;
        }
      });
    });

    // Legend contents will vary depending on whether fertigation is in use
    // Treating irriopt data as source-of-truth here, not whether fertigation enabled on controller
    const getLegend = () => {
      let legend = [
        {
          title: this._languageService.instant('CONTROLLERS.IRRIGATION_REQUESTS'),
          color: this.irrigationVars.irrigationPlanned.colour,
        },
        {
          title: this._languageService.instant('CONTROLLERS.PROGRAMMED_IRRIGATION'),
          color: this.irrigationVars.irrigationProgrammed.colour,
        },
      ];

      if (anyFertig) {
        legend = legend.concat([
          { title: this._languageService.instant('CONTROLLERS.FERTIGATION'), color: this.irrigationVars.fertigationPlanned.colour },
          {
            title: this._languageService.instant('CONTROLLERS.PROGRAMMED_FERTIGATION'),
            color: this.irrigationVars.fertigationProgrammed.colour,
          },
        ]);
      }

      return legend;
    };

    // Dummy segments added to chart to force day to show full 24 hours
    const start = [
      {
        start: this.irrigationLocalStartTimeInMinutes,
        end: this.irrigationLocalStartTimeInMinutes,
        duration: 0,
        depth: 0,
        colour: '#999999',
        fertigate: false,
      },
    ] as fuse.irrigationPulse[];
    const end = [
      {
        start: this.irrigationLocalStartTimeInMinutes + 1440,
        end: this.irrigationLocalStartTimeInMinutes + 1440,
        duration: 0,
        depth: 0,
        colour: '#999999',
        fertigate: false,
      },
    ] as fuse.irrigationPulse[];
    const startDate = this.historyDate.toString('yyyy-MM-dd');

    this.dailyIrrigation[0].segments = start.concat(this.dailyIrrigation[0].segments).concat(end);

    const chartOption = {
      dataProvider: this.dailyIrrigation,

      categoryField: 'valveId',
      segmentsField: 'segments',
      colorField: 'colour',
      startField: 'start',
      endField: 'end',
      startDate: startDate,

      type: 'gantt',
      theme: 'none',
      marginRight: 70,
      period: 'mm',
      dataDateFormat: 'YYYY-MM-DD',
      balloonDateFormat: 'JJ:NN',
      columnWidth: 0.5,
      valueAxis: {
        type: 'date',
      },
      graph: {
        fillAlphas: 1,
        balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
          let start = graphDataItem.graph.segmentData.start % 1440;
          let end = graphDataItem.graph.segmentData.end % 1440;
          if (start == end) return null;
          if (graphDataItem.graph.segmentData.duration < 0) {
            // Not sure why this is happening - likely a bug, but rough fix for now
            const temp = end;
            end = start;
            start = temp;
          }
          const startTime = this._timeService.toReadable(start);
          const endTime = this._timeService.toReadable(end);
          const name = graphDataItem.category;
          const label = this._languageService.instant('CONTROLLERS.VALVE_LABEL', {
            name: name,
            startTime: startTime,
            endTime: endTime,
          });
          return label;
        },
      },
      legend: {
        position: 'top',
        data: getLegend(),
      },
      rotate: true,

      valueScrollbar: {
        autoGridCount: true,
      },
      chartCursor: {
        cursorColor: '#55bb76',
        valueBalloonsEnabled: false,
        cursorAlpha: 0,
        valueLineAlpha: 0.5,
        valueLineBalloonEnabled: true,
        valueLineEnabled: true,
        zoomable: false,
        valueZoomable: true,
      },
    };

    this.chart = AmCharts.makeChart('irrigation-controller-chart', chartOption) as AmCharts.AmSerialChart;

    $('#irrigation-controller-chart').height(this.dailyIrrigation.length * 20 + 120);
  }
}

angular.module('app.water').controller('IrrigationControllerController', IrrigationControllerController);
