import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { unitSizes } from '@common/enums';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';
import { ISetIrrigationSetting, WaterConstants } from '../WaterConstants';

export class SetPlanIrrigationDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  public settingForm: angular.IFormController;
  public irrigationPercent: number;
  public irrigationApplication: number;
  public irrigationHHMM: string;
  private irrigationRuntime: number;
  public isFertigationEnabled: boolean;
  public fertigationApplication: number;
  public fertigationHHMM: string;
  private fertigationRuntime: number;
  public irrigationUnitId: number;
  public irrigationUnits = WaterConstants.irrigationUnits;
  public fertigationUnitId: number;
  public fertigationUnits = WaterConstants.fertigationUnits;
  public fluidDepthNormalUnit: string;
  private isIrrigationValid = false;
  public title: string;
  public irrigationLockStatus = null as boolean;
  public isFertigateEntire = false;
  public isFertigateFixed = false;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    irrigationUnitId: number,
    fertigationUnitId: number,
    isFertigationEnabled: boolean,
    localeDate: Date,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    if (!localeDate) {
      this.title = this._languageService.instant('WATER.IRRIGATION.SET_PLAN_DIALOG.SET_ALL_TITLE');
    } else {
      this.title = `${this._languageService.instant('WATER.IRRIGATION.SET_PLAN_DIALOG.SET_ONE_DAY_TITLE')} (${DateUtils.Locale.asDateDayAndMonth(localeDate)})`;
    }

    this.isFertigationEnabled = isFertigationEnabled;
    this.irrigationUnitId = irrigationUnitId;
    this.fertigationUnitId = fertigationUnitId;

    this.fluidDepthNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
  }

  public handleChangeIrrigationInputs() {
    this.isIrrigationValid = false;

    const clearPropsExcept = (propertyNameToKeep: string) => {
      const relevantPropNames = ArrayUtils.except(['irrigationApplication', 'irrigationHHMM', 'irrigationPercent'], [propertyNameToKeep]);

      for (let tagetPropertyName of Object.keys(this).filter(propName => relevantPropNames.includes(propName))) {
        this[tagetPropertyName] = undefined;
      }
    };

    if (this.irrigationUnitId == 0 && this.irrigationApplication != null) {
      clearPropsExcept('irrigationApplication');

      this.isIrrigationValid = true;
    }

    if (this.irrigationUnitId == 1 && this.irrigationHHMM != null) {
      clearPropsExcept('irrigationHHMM');

      this.irrigationRuntime = this.convertToRuntime(this.irrigationHHMM);

      if (this.irrigationRuntime != null) {
        this.isIrrigationValid = true;
      }

      if (this.irrigationRuntime === undefined) {
        this.settingForm.irrigationHHMM.$error = { valid: true };
        this.settingForm.irrigationHHMM.$setValidity('valid', false);
      } else {
        this.settingForm.irrigationHHMM.$error = {};
        this.settingForm.irrigationHHMM.$setValidity('valid', true);
      }
    }

    if (this.irrigationUnitId == 2 && this.irrigationPercent != null) {
      clearPropsExcept('irrigationPercent');

      this.isIrrigationValid = true;
    }
  }

  public handleChangeFertigateDuringEntireIrrigation() {
    if (this.isFertigateEntire) {
      this.isFertigateFixed = false;
    }
  }

  public handleChangeFertigateFixedAmountAs() {
    if (this.isFertigateFixed) {
      this.isFertigateEntire = false;
    }
  }

  public handleChangeFertigationInputs() {
    if (this.fertigationUnitId == 0) {
      return;
    }

    if (this.fertigationUnitId == 1 && this.fertigationHHMM != null) {
      this.fertigationRuntime = this.convertToRuntime(this.fertigationHHMM);
    }

    if (this.fertigationRuntime === undefined) {
      this.settingForm.fertigationHHMM.$error = { valid: true };
      this.settingForm.fertigationHHMM.$setValidity('valid', false);
    } else {
      this.settingForm.fertigationHHMM.$error = {};
      this.settingForm.fertigationHHMM.$setValidity('valid', true);
    }
  }

  public validateForm(): boolean {
    if (this.irrigationApplication != null || this.irrigationRuntime != null || this.irrigationPercent != null) {
      if (this.isIrrigationValid == false) {
        return false;
      }
    }

    if (this.isFertigateFixed) {
      if (this.fertigationApplication == null && this.fertigationRuntime == null) {
        return false;
      }
    }

    if (
      this.isIrrigationValid == false &&
      this.isFertigateEntire == false &&
      this.isFertigateFixed == false &&
      this.irrigationLockStatus == null
    ) {
      return false;
    }

    return true;
  }

  public set() {
    const setting = {
      irrigationLockStatus: this.irrigationLockStatus,
    } as ISetIrrigationSetting;

    if (this.irrigationApplication != null || this.irrigationRuntime != null || this.irrigationPercent != null) {
      setting.isIrrigationChanged = true;
      setting.irrigationApplication = this.irrigationApplication;
      setting.irrigationRuntime = this.irrigationRuntime;
      setting.irrigationPercent = this.irrigationPercent;
    }

    if (this.isFertigateEntire) {
      setting.isFertigateEntire = true;
    }

    if (this.isFertigateFixed) {
      setting.isFertigateFixed = true;
      setting.fertigationApplication = this.fertigationApplication;
      setting.fertigationRuntime = this.fertigationRuntime;
    }

    this._mdDialog.hide(setting);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  private convertToRuntime(overrideHHMM: string): number {
    if (overrideHHMM == null || overrideHHMM == '') {
      return null;
    }

    const hhmm = overrideHHMM.split(':');

    if (hhmm.length != 2) {
      return undefined;
    }

    const hh = parseFloat(hhmm[0]);
    const mm = parseFloat(hhmm[1]);

    if (isNaN(hh) || isNaN(mm)) {
      return undefined;
    }

    return hh + mm / 60;
  }
}

angular.module('app.water').controller('SetPlanIrrigationDialogController', SetPlanIrrigationDialogController);
