import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { UnitTypes } from '@common/enums';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

/** Depending on arguments, may be used to 1) add new controller program protocol, 2) edit existing protocol, 3) send program to controller */
export class ConfirmProgramController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  public name: string;
  public nameExists: boolean;

  public windows: fuse.pulseStartWindow[];
  public warnings: string[];
  public program: fuse.controllerProgram;
  public pulses = [];
  public dates = [] as string[];
  public windowsByDate = [] as fuse.pulseStartWindow[][];

  public title = 'Confirm Program';
  public buttonText = 'Send To Controller';
  public fluidDepthUnit: uomUnit;
  public volumeUnit: uomUnit;
  public daysString: string;
  public conflicts: fuse.controllerConflict[];
  public runtimeTables = {};

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    program: fuse.controllerProgram,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.fluidDepthUnit = UnitOfMeasureService.getUnits(UnitTypes.FluidDepth);
    this.volumeUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume);

    this.program = program;
    this.windows = program.windows;
    this.conflicts = program.controllerConflicts;

    let lastDate = [] as fuse.pulseStartWindow[];

    this.windows?.forEach((window) => {
      // Organise windows by date for summary list
      if (!lastDate.length || window.date != lastDate[0].date) {
        lastDate = [];
        this.windowsByDate.push(lastDate);
        this.dates.push(window.dateString);
      }

      lastDate.push(window);

      window['timeRange'] = `${this.formatTime(window.startTime)} - ${this.formatTime(window.endTime)}`;

      // Organise windows by pulse for runtime list
      window.valvePulses.forEach((pulse) => {
        if (pulse.applicationMM > 0) {
          pulse['date'] = window.date;
          pulse['timeRange'] = window['timeRange'];

          this.pulses.push(pulse);

          if (!this.runtimeTables[window.dateString]) {
            this.runtimeTables[window.dateString] = [];
          }

          this.runtimeTables[window.dateString].push(pulse);
        }
      });

      window['irrigCount'] = window.valvePulses.filter((vp) => vp.applicationMM > 0 && vp.fertigate == false).length;
      window['fertigCount'] = window.valvePulses.filter((vp) => vp.applicationMM > 0 && vp.fertigate == true).length;

      this.dates.forEach((date) => {
        this.runtimeTables[date] = ArrayUtils.sortByCustomComparer(this.runtimeTables[date],
          (x: fuse.valvePulse, y: fuse.valvePulse) => {
            // Sort logic for irrigation runtime table - values sorted by date, line/valveId, and time of day.
            if (x.lineId != y.lineId) {
              return x.lineId - y.lineId;
            }

            if (x.valveId != y.valveId) {
              return x.valveId - y.valveId;
            }

            if (x['timeRange'] > y['timeRange']) {
              return 1;
            }

            if (x['timeRange'] == y['timeRange']) {
              return 0;
            }

            return -1;
          });
      });
    });
  }

  // Trims timespans which may be in Day.HH:mm:ss format down to HH:mm format
  public formatTime(timeString: string): string {
    const dayPlace = timeString.indexOf('.');
    let secsPlace = timeString.lastIndexOf(':');

    if (timeString.split(':').length < 2) {
      secsPlace = timeString.length;
    }

    return timeString.slice(dayPlace + 1, secsPlace);
  }

  public sendProgram() {
    this._mdDialog.hide(this.program);
  }

  public valveStr(v) {
    return `${v['LineId']}-${v['ValveId']}`;
  }

  public valveList(valves: string[]) {
    return valves.map((v) => this.valveStr(v)).join(', ');
  }

  public closeDialog() {
    this._mdDialog.hide(false);
  }
}

angular.module('app.water').controller('ConfirmProgramController', ConfirmProgramController);
