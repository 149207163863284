import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes, UnitTypes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { AllocationService, SubAllocation, Transaction } from '@services/allocation.service';
import { DataEntityService } from '@services/data-entity.service';
import { DialogParams, LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { BaseController, PostSaveActions } from 'src/app/base.controller';

interface FormData {
  changed: boolean;
  dtoProp?: string;
  form: angular.IFormController;
  primaryTab?: PrimaryTabEnum;
}

enum PrimaryTabEnum {
  Summary,
  SubAllocations,
  Budgets,
  Transactions,
}

export class AllocationController extends BaseController {
  public hasDataChanges: boolean;
  public allocations: fuse.allocationDto[];
  public allocation: fuse.allocationDto;
  public primaryTab = PrimaryTabEnum.Summary;
  public waterSources: fuse.allocWaterSourceDto[];
  public waterSourcesEmpty: boolean = false;
  public transactions: Transaction[];
  public monthBudgets: SubAllocation[];
  public subAllocations: SubAllocation[];
  private allocationId: number;

  public assetStatuses: string[];
  public allocationForm: angular.IFormController;
  public summaryForm: angular.IFormController;
  public subAllocationsForm: angular.IFormController;
  public budgetsForm: angular.IFormController;
  public transactionsForm: angular.IFormController;
  public subAllocError = null;
  public subAllocWarn = null;
  public transactError: string;
  public minimumDate: Date;
  public allowance: number;
  public used: number;
  public balance: number;
  public selectedSubId: number;
  public chartTitle: string;
  public subAllocTotal: number;
  public budgetTotal: number;
  public budgetError: string;

  private _http: angular.IHttpService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _allocationService: AllocationService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  private volumeLargeUnit: uomUnit;
  private volumeHugeUnit: uomUnit;

  private _subForms: Record<string, FormData>;

  constructor(
    $http: angular.IHttpService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    AllocationService: AllocationService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._q = $q;
    this._state = $state;
    this._allocationService = AllocationService;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.allocationId = Number(this._state.params.id);

    AllocationService.initUnits(); // in case we've changed user unit preferences since last load

    this.assetStatuses = SWANConstants.AssetStatuses;
    this.volumeLargeUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.large);
    this.volumeHugeUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.huge);

    $scope.$watch('vm.allocationForm.$dirty',
      (isAllocationFormDirty: boolean) => {
        this.hasDataChanges = isAllocationFormDirty || this._dataEntityService.hasDirtyCustomForm;
        this._dataEntityService.hasDirtyCustomForm = isAllocationFormDirty;
      }
    );

    this._notifyingService.subscribe(NotifyEvents.App.SaveChanges.WaterAllocation, this.scope, (_event: angular.IAngularEvent, data: PostSaveActions) => {
      this.saveChanges(data);
    });
  }

  public get hasDirtyCustomForm(): boolean {
    return this._dataEntityService.hasDirtyCustomForm;
  }

  private get summaryFormName(): string {
    return this.getFormName(this.summaryForm);
  }

  private get subAllocationsFormName(): string {
    return this.getFormName(this.subAllocationsForm);
  }

  private get budgetsFormName(): string {
    return this.getFormName(this.budgetsForm);
  }

  private get transactionsFormName(): string {
    return this.getFormName(this.transactionsForm);
  }

  $onInit() {
    const promises = [
      this._allocationService.getAllocations((res) => {
        this.allocations = res;
      }),
      this.getWaterSources(),
      this.getAllocation(this.allocationId)
    ];

    this._q.all(promises).then(() => {
      this.setupAll();
    });
  }

  public change() {
    this._dataEntityService.hasDirtyCustomForm = true;
  }

  public changeSummaryForm(): void {
    this._subForms[this.summaryFormName].changed = true;
    this._dataEntityService.hasDirtyCustomForm = true;
  }

  private setSourcesAndCharts() {
    this.waterSources.forEach((ws) => (ws.selected = this.allocation.waterSources.some((sel) => ws.id == sel.id)));
    this.selectedSubId = 0;
    this._allocationService.displayUsageSummaryChart(this.monthBudgets, !!this.monthBudgets.length);

    if (this.monthBudgets && this.subAllocations?.length) {
      this.createSubAllocChart(this.subAllocations[this.selectedSubId]);
    }
  }

  private createSubAllocChart(sub: SubAllocation) {
    this.selectedSubId = sub.id;
    this.chartTitle = sub.comments;
    this._allocationService.displayUsageSubAllocSummaryChart(sub, sub.budgetKL, this.allocation.name);
  }

  private getWaterSources(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl('allocation/watersources')).then((res) => {
      this.waterSources = res.data as any;
      defer.resolve();
    });

    return defer.promise;
  }

  private getAllocation(assetId: number): angular.IPromise<void> {
    return this._allocationService.getAllocation(assetId, (allocation: fuse.allocationDto) => {
      this.allocation = allocation;
      this.transactions = this.allocation.obsAllocations as Transaction[];
      this.monthBudgets = this.allocation.monthBudgets as SubAllocation[];
      this.subAllocations = this.allocation.subAllocations as SubAllocation[];
    });
  }

  public clearErrors() {
    this._dataEntityService.hasDirtyCustomForm = false;

    for (const subFormName in this._subForms) {
      this._subForms[subFormName].changed = false;
    };

    this.waterSourcesEmpty = false;
  }

  public checkWaterSources() {
    const error = this._allocationService.checkWaterSources(this.allocation, this.allocations);

    this.waterSourcesEmpty = !this.waterSources.some((w) => w.selected);

    if (this.waterSourcesEmpty) {
      this.gotoTab(PrimaryTabEnum.Summary);

      return false;
    }

    if (error) {
      const params = { title: 'COMMON.CHANGES_NOT_SAVED', content: error, ok: 'COMMON.CLOSE' } as DialogParams;
      const message = this._languageService.basicAlert(params).targetEvent();
      this._languageService.show(message);
      this.gotoTab(PrimaryTabEnum.Summary);

      return false;
    }

    return true;
  }

  private errorCrossChecks() {
    if (!this.allocationForm) {
      return;
    }

    const validateAllocationForm = () => this.validateForm({ form: this.allocationForm } as FormData);

    return [
      this.checkName(),
      this.checkDates(),
      validateAllocationForm(),
      this.checkBudgetTotal(false),
      this.checkSubAllocationTotal(),
    ].every((x) => x);
  }

  public validateAll(): boolean {
    let isValidAll = this.errorCrossChecks();

    if (!isValidAll) {
      return false;
    }

    // Validate forms and goto its tab if any errors.
    for (const subFormName in this._subForms) {
      const isValidForm = this.validateForm(this._subForms[subFormName])

      if (!isValidForm) {
        isValidAll = false;

        break;
      }
    };

    if (!isValidAll) {
      return false;
    }

    // Set and check water sources
    this.allocation.waterSources = this.waterSources.filter((ws) => ws.selected);

    if (!this.checkWaterSources()) {
      return false;
    }

    return true;
  }

  public saveChanges(postSaveActions: PostSaveActions = null) {
    if (!this.validateAll()) {
      this._languageService.warning('COMMON.PLEASE_FIX');

      return;
    }

    this._allocationService.updateRelatedDayNumbers(this.allocation);

    // If associated data for water sources/transactions/sub-allocations/budgets hasn't been changed, set to null so upsertAllocation won't update it
    for (const subFormName in this._subForms) {
      const subForm = this._subForms[subFormName];

      if (subFormName !== this.summaryFormName) { // NOTE: Excluding reset of 'dtoProp' for unchanged summary sub form as otherwise the 'waterSources' array is sent as 'null' to the PUT 'allocation' endpoint which is not catered for and causes a 500 error.
        if (!subForm.changed) {
          this.allocation[subForm.dtoProp] = null;
        }
      }
    };

    if (!this._subForms[this.budgetsFormName].changed && (this.allocationForm.fromDate.$touched || this.allocationForm.toDate.$touched)) {
      this.allocation.monthBudgets = this.monthBudgets;
    }

    this._http.put(CommonHelper.getApiUrl('allocation/allocation'), this.allocation).then((res) => {
      this._dataEntityService.hasDirtyCustomForm = false;
      this._languageService.success('COMMON.CHANGES_SAVED');

      if (super.executeAnyPostSaveActions(postSaveActions)) {
        return;
      }

      this.reset();
    },
    () => {
      this._languageService.whoops();
    });
  }

  private getFormName(form: angular.IFormController): string {
    return form.$name.split('.')[1];
  }

  private checkSubAllocDates(): boolean {
    this._dataEntityService.hasDirtyCustomForm = true;
    this.subAllocError = null;

    if (!this.checkFormDates(this.subAllocationsForm, PrimaryTabEnum.SubAllocations)) {
      this.subAllocError = 'ALLOCATION.ERROR_SUBALLOC_DATE';

      return false;
    }

    if (!this.checkSuballocOverlaps()) {
      this.subAllocError = 'ALLOCATION.ERROR_SUBALLOC_OVERLAP';

      return false;
    }

    return true;
  }

  private checkTransactions(): boolean {
    this._dataEntityService.hasDirtyCustomForm = true;
    this.transactError = null;

    let valid = this.checkFormDates(this.transactionsForm, PrimaryTabEnum.Transactions);

    if (!valid) {
      this.transactError = this._languageService.instant('ALLOCATION.ERROR_TRANS_DATE');
    }

    let transactTotal = 0;
    let negTotalError = false;

    this.transactions.forEach((tr) => {
      transactTotal += tr.volumeKL ?? 0;
      tr.subTotal = transactTotal;

      if (transactTotal < 0 && !negTotalError) {
        negTotalError = true;

        const error = this._languageService.instant('ALLOCATION.ERROR_NEGATIVE_TOTAL', { date: (tr.date as any).format('d M Y') });

        this.transactError = !this.transactError ? error : `${this.transactError}<br>${error}`;
        this.transactionsForm.$setDirty();
        this.gotoTab(PrimaryTabEnum.Transactions);

        valid = false;
      }
    });

    this.allocation.totalAllowance = transactTotal;
    this.allocation.balance = this.allocation.totalAllowance - this.allocation.runningUsed;

    this.checkBudgetTotal(true);

    return valid;
  }

  private checkFormDates(form: angular.IFormController, primaryTab: PrimaryTabEnum): boolean {
    let valid = true;

    form.$$controls
      .filter((c) => c.$name.startsWith('date'))
      .forEach((c) => {
        if (c.$modelValue < this.allocation.effectiveFromDate || c.$modelValue > this.allocation.effectiveToDate) {
          c.$setTouched();
          valid = false;
        }
        c.$setValidity('valid_date', valid);
      });

    if (!valid) {
      this.gotoTab(primaryTab);
    }

    return valid;
  }

  private validateForm(formData: FormData): boolean {
    const form = formData.form;
    const primaryTab = formData.primaryTab;

    if (primaryTab == PrimaryTabEnum.Budgets && !this.allocation.hasBudget) {
      return true;
    }

    form.$$controls.forEach((v) => {
      v.$validate();

      if (!v.$valid) {
        v.$setTouched();
      }

      if (v.$touched) {
        const formName = this.getFormName(form);
        const subForm = this._subForms[formName];

        if (subForm) {
          subForm.changed = true;
        }
      }
    });

    if (!form.$valid) {
      if (primaryTab) {
        this.gotoTab(primaryTab);
      }

      return false;
    }

    return true;
  }

  public reset() {
    this.getAllocation(this.allocationId).then((r) => {
      this.setupAll();
    });
  }

  private setupAll() {
    this._subForms = {};

    this._subForms[this.summaryFormName] = {
      form: this.summaryForm,
      primaryTab: PrimaryTabEnum.Summary,
      dtoProp: 'waterSources',
    } as FormData;
    this._subForms[this.subAllocationsFormName] = {
      form: this.subAllocationsForm,
      primaryTab: PrimaryTabEnum.SubAllocations,
      dtoProp: 'subAllocations',
    } as FormData;
    this._subForms[this.budgetsFormName] = {
      form: this.budgetsForm,
      primaryTab: PrimaryTabEnum.Budgets,
      dtoProp: 'monthBudgets',
     } as FormData;
    this._subForms[this.transactionsFormName] = {
      form: this.transactionsForm,
      primaryTab: PrimaryTabEnum.Transactions,
      dtoProp: 'obsAllocations',
    } as FormData;

    this.errorCrossChecks();
    this.clearErrors();
    this.setSourcesAndCharts();
  }

  public gotoAllocations() {
    this._state.go('app.water.allocations');
  }

  public gotoAllocation(assetId: number) {
    this._state.go('app.water.allocations.detail', { id: assetId });
  }

  public checkName() {
    return this._allocationService.checkName(this.allocation, this.allocations, this.allocationForm);
  }

  public addSubAlloc() {
    this.subAllocations.push({
      effectiveFromDate: this.allocation.effectiveFromDate,
      effectiveToDate: this.allocation.effectiveToDate,
    } as SubAllocation);
  }

  public deleteSubAlloc(item: SubAllocation) {
    const index = this.subAllocations.findIndex((a) => a == item);

    this.subAllocations.splice(index, 1);
    this._subForms[this.subAllocationsFormName].changed = true;

    this.createSubAllocChart(this.subAllocations[0]);
    this.checkSubAllocDates();
  }

  public changeChart(subAllocation: SubAllocation) {
    this.chartTitle = subAllocation.comments;

    this.createSubAllocChart(subAllocation);
  }

  public checkDates() {
    if (!this.checkAllocDates()) {
      return false;
    }

    return [
      this.checkSubAllocDates(),
      this.checkTransactions(),
      this.checkSuballocOverlaps(),
    ].every((x) => x);
  }

  private checkAllocDates() {
    this._dataEntityService.hasDirtyCustomForm = true;

    return this._allocationService.checkAllocDates(this.allocationForm, this.allocation);
  }

  private getOverlap(i: number, j: number) {
    const sub1 = this.subAllocations[i];
    const sub2 = this.subAllocations[j];

    const start1 = sub1.effectiveFromDate;
    const end1 = sub1.effectiveToDate;
    const start2 = sub2.effectiveFromDate;
    const end2 = sub2.effectiveToDate;

    // one sub completely inside other
    if ((start1 <= start2 && end1 >= end2) || (start2 <= start1 && end2 >= end1)) {
      return [`dateto${i}`, `datefrom${j}`, `dateto${j}`, `datefrom${i}`];
    }

    if (start1 <= end2 && end1 >= start2) {
      // end of sub1 overlaps start of sub2
      if (start1 <= start2) {
        return [`dateto${i}`, `datefrom${j}`];
      }

      // end of sub2 overlaps start of sub1
      return [`dateto${j}`, `datefrom${i}`];
    }

    return false;
  }

  private checkSuballocOverlaps() {
    let valid = true;

    this.subAllocations = ArrayUtils.sortByNumber(this.subAllocations, x => x.fromDayNumber);
    this.allocation.subAllocations = this.subAllocations;

    const dateControls = this.subAllocationsForm.$$controls.filter((c) => c.$name.startsWith('date'));

    dateControls.forEach((c) => {
      c.$setValidity('overlap', true);
    });

    for (let i = 0; i < this.subAllocations.length - 1; i++) {
      for (let j = i + 1; j < this.subAllocations.length; j++) {
        const overlap = this.getOverlap(i, j);

        if (overlap) {
          this.subAllocationsForm.$$controls
            .filter((c) => overlap.some((t) => t == c.$name))
            .forEach((c) => {
              c.$setValidity('overlap', false);
              c.$setTouched();
              valid = false;
            });
        }
      }
    }

    return valid;
  }

  public checkSubAllocationTotal() {
    this.subAllocTotal = ArrayUtils.sum(this.subAllocations.map((a) => a.budgetKL));
    this.subAllocWarn = null;

    if (this.allocation.totalAllowance < this.subAllocTotal) {
      const subTotal = this.volumeHugeUnit.fromBaseText(this.subAllocTotal);
      const total = this.volumeHugeUnit.fromBaseText(this.allocation.totalAllowance);

      this.subAllocWarn = this._languageService.instant('ALLOCATION.ERROR_SUBALLOC_TOTAL', { subTotal, total });
    }

    this.change();

    return true; // Warning only, can still save if sub-alloc total > alloc total
  }

  public checkBudgetTotal(isFromTrans: boolean) {
    if (!this.allocation.hasBudget) {
      return true;
    }

    this.budgetTotal = ArrayUtils.sum(this.monthBudgets.map((a) => a.budgetKL));
    this.budgetError = null;

    const valid = this.allocation.totalAllowance >= this.budgetTotal;

    if (!valid) {
      const subTotal = this.volumeHugeUnit.fromBaseText(this.budgetTotal);
      const total = this.volumeHugeUnit.fromBaseText(this.allocation.totalAllowance);

      this.budgetError = this._languageService.instant('ALLOCATION.ERROR_BUDGET_TOTAL', { subTotal, total });
      this.budgetsForm.$setDirty();

      if (isFromTrans == false) {
        this.gotoTab(PrimaryTabEnum.Budgets);
      }
    }

    this.budgetsForm.$$controls.forEach((element) => {
      element.$setValidity('total', valid);
    });

    this.change();

    return valid;
  }

  public textColor(month: fuse.subAllocationDto) {
    if (month.budgetKL == null) {
      return;
    }

    if (month.usedKL <= month.budgetKL * 0.8) {
      return;
    }

    if (month.usedKL > month.budgetKL * 0.8 && month.usedKL <= month.budgetKL) {
      return 'warning-text';
    }

    return 'error-text';
  }

  public addTrans() {
    this.transactions.push({} as Transaction);
    this._dataEntityService.hasDirtyCustomForm = true;
  }

  public deleteTrans(item: Transaction) {
    const date = item.date ? ` (${DateUtils.Locale.asDateMedium(item.date)})` : '';
    const confirm = this._languageService
      .confirm()
      .title('COMMON.CONFIRM')
      .htmlContent('ALLOCATION.DELETE_TRANSACTION', { date: date })
      .parent(angular.element(document.body))
      .ok('COMMON.CONFIRM')
      .cancel('COMMON.CANCEL');

    this._languageService.show(confirm).then(() => {
      const index = this.transactions.findIndex((a) => a == item);
      this._subForms[this.transactionsFormName].changed = true;
      this.transactions.splice(index, 1);
      this._dataEntityService.hasDirtyCustomForm = true;
      this.checkTransactions();
    });
  }

  private gotoTab(primaryTab: PrimaryTabEnum) {
    this.primaryTab = primaryTab;
  }
}

angular.module('app.water').controller('AllocationController', AllocationController);
