// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#irrigation-add-override {
  width: 800px;
}
#irrigation-add-override .md-datepicker-button {
  margin-top: 0;
}
#irrigation-add-override md-input-container {
  margin: 4px;
  width: 200px;
}
#irrigation-add-override form > div {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/irrigation-plan/addOverride-dialog.scss"],"names":[],"mappings":"AACA;EACI,YAAA;AAAJ;AACI;EACI,aAAA;AACR;AACI;EACI,WAAA;EACA,YAAA;AACR;AACI;EACI,WAAA;AACR","sourcesContent":["\r\n#irrigation-add-override {\r\n    width: 800px;\r\n    .md-datepicker-button {\r\n        margin-top: 0;\r\n    }\r\n    md-input-container {\r\n        margin: 4px;\r\n        width: 200px;\r\n    }\r\n    form > div {\r\n        width: 100%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
