import * as angular from 'angular';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { ConversionUtils } from '@indicina/swan-shared/utils/ConversionUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { NumberUtils } from '@indicina/swan-shared/utils/NumberUtils';
import { StringUtils } from '@indicina/swan-shared/utils/StringUtils';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { DayNumberService } from '@services/day-number.service';
import { WaterService } from '@services/water.service';
import { AssetClass } from 'src/app/_DBContext/AssetClass';
import { IrrigationPlan } from 'src/app/_DBContext/IrrigationPlan';
import { PrintIrrigationPlanDialogController } from '../irrigation-plan/print-irrigation-plan-dialog.controller';
import { WaterConstants, IIrrigationReportRowSetting, IIrrigationReportSetting } from '../WaterConstants';
import { AddPlanDialogController } from './addplan-dialog.controller';
import { BaseController } from 'src/app/base.controller';

export class IrrigationPlansController extends BaseController {
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _dupeHandlerService: DupeHandlerService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _unitOfMeasureService: UnitOfMeasureService;
  private _waterService: WaterService;

  public browserTimezoneLabel: string;
  public iActive = 0;
  public iArchived = 0;
  public showActive = true;
  public showArchived = false;
  private irrigationPlans = [] as fuse.irrigationPlanInfoDto[];
  public nameFilter = '';
  public filteredPlans: fuse.irrigationPlanInfoDto[];
  public displayPlans: fuse.irrigationPlanInfoDto[];

  public pageSize = 20;
  public currentPage = 1;
  private reportLogo: string;
  private logoWidth: number;
  private logoHeight: number;
  public isSelectAllDisplayPlans = false;

  private adjustedTodayDayNumber: number;
  private irriFromDayNumber: number;
  private irriToDayNumber: number;
  private accountName: string;
  private fluidDepthNormalUnit: string;
  private volumeNormalUnit: string;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    DupeHandlerService: DupeHandlerService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    WaterService: WaterService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._dupeHandlerService = DupeHandlerService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._unitOfMeasureService = UnitOfMeasureService;
    this._waterService = WaterService;

    this._dupeHandlerService.setDuplicateType('Irrigation Plan');

    this.entityManager = DataEntityService.manager;
    this.browserTimezoneLabel = DateUtils.getBrowserTimezoneLabel();

    this.volumeNormalUnit = UnitOfMeasureService.getUnitLabel('Volume', unitSizes.normal);
    this.fluidDepthNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
    this.accountName = this.account.name;
    this.irrigationPlans = [];

    this.adjustedTodayDayNumber = DayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.irriFromDayNumber = this.adjustedTodayDayNumber - 3;
    this.irriToDayNumber = this.adjustedTodayDayNumber + 7;

    if (WaterService.getKeepFilter() == true) {
      this.nameFilter = WaterService.getNameFilter();
      this.showActive = WaterService.getActiveState();
      this.showArchived = WaterService.getArchivedState();
      WaterService.setKeepFilter(false);
    } else {
      WaterService.setNameFilter(this.nameFilter);
      WaterService.setActiveState(this.showActive);
      WaterService.setArchivedState(this.showArchived);
    }
  }

  $onInit() {
    let logoUrl = 'assets/images/logos/SWAN-Landscape-Logo-Colour.png';

    if (this.accountCustomStyling.NavigationLogoURL) {
      logoUrl = this.accountCustomStyling.NavigationLogoURL;
    }

    this._http.get(logoUrl, { responseType: 'blob' }).then((res) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.reportLogo = reader.result as string;
      };

      reader.readAsDataURL(res.data as Blob);
    });

    const img = new Image();

    img.addEventListener('load', () => {
      this.logoWidth = img.width;
      this.logoHeight = img.height;
    });

    img.src = logoUrl;

    const promises = [] as angular.IPromise<void>[];

    promises.push(this.getIrrigationPlans());

    this._q.all(promises);

    this.setWatches();
  }

  private setWatches() {
    this.scope.$watchGroup(['vm.pageSize', 'vm.currentPage', 'vm.nameFilter'], () => {
      if (this.irrigationPlans.length) {
        this.filterPlans();
      }
    });
  }

  private getIrrigationPlans(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = {
      accountId: this.accountId,
      fromDayNumber: this.adjustedTodayDayNumber,
    };

    this._http.get(CommonHelper.getApiUrl('water/irrigationPlanSummaries'), { params: params }).then((response) => {
      if (response.data == null) {
        this._languageService.whoops();
      } else {
        this.irrigationPlans = response.data as fuse.irrigationPlanInfoDto[];

        this.irrigationPlans.forEach((planInfo) => {
          planInfo.selected = false;
          if (planInfo.status == 'Archived') this.iArchived++;
          else this.iActive++;
        });

        this.filterPlans();
      }

      defer.resolve();
    })
    .catch((response) => {
      defer.reject(response);
    });

    return defer.promise;
  }

  public activeStatusClass(status: string) {
    if (status === WaterConstants.activeState) {
      return 'icon-checkbox-marked-circle swanGreen';
    } else if (status === WaterConstants.archivedState) {
      return 'icon-cancel red-500-fg';
    } else {
      return 'icon-minus-circle swanOrange';
    }
  }

  public soilMoistureStatusClass(soilMoisture) {
    if (soilMoisture.high > 0) {
      return 'soilTargetWetBackground';
    }

    if (soilMoisture.ok > 0) {
      return 'soilTargetOkBackground';
    }

    if (soilMoisture.low > 0) {
      return 'soilTargetDryBackground';
    }
  }

  private filterPlans() {
    this._waterService.setNameFilter(this.nameFilter);
    this._waterService.setActiveState(this.showActive);
    this._waterService.setArchivedState(this.showArchived);

    if (this.irrigationPlans) {
      this.filteredPlans = this.irrigationPlans.filter((a) => a.assetName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1);

      if (!this.showActive) {
        this.filteredPlans = this.filteredPlans.filter((a) => a.status !== 'Active');
      }

      if (!this.showArchived) {
        this.filteredPlans = this.filteredPlans.filter((a) => a.status !== 'Archived');
      }

      this.displayPlans = this.filteredPlans.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);

      this.irrigationPlans.forEach((plan) => {
        if (this.displayPlans.some((a) => a.assetId == plan.assetId) == false) {
          plan.selected = false;
        }
      });

      this.updateTable();
    }
  }

  public updateTable(): void {
    this.selectPlanChanged();
  }

  public addNewPlan(ev: MouseEvent) {
    this._mdDialog.show({
      controller: AddPlanDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-plans/addplan-dialog.html',
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: false,
      locals: {
        irrigationPlans: this.irrigationPlans,
      },
    }).then((res) => {
      if (res) {
        this.createNewPlan(res as fuse.irrigationPlanInfoDto);
      }
    });
  }

  private createNewPlan(newPlanInfo: fuse.irrigationPlanInfoDto) {
    const assetClassQuery = new breeze.EntityQuery('AssetClass');

    this.entityManager.executeQuery(assetClassQuery).then(
      (data) => {
        const assetClassEntities = data.results as AssetClass[]; //this.entityManager.getEntities("AssetClass");
        const irrigationPlanAssetClass = assetClassEntities.find((a) => a.Name == 'Irrigation Plan');
        const assetType: any = this.entityManager.metadataStore.getEntityType('Asset');
        const newAssetRec = {
          OwnerAccountId: this.accountId,
          AssetClassId: irrigationPlanAssetClass.Id,
          Name: newPlanInfo.assetName,
          Status: WaterConstants.activeState,
          Comment: '',
        };
        const newAsset: any = assetType.createEntity(newAssetRec);

        this.entityManager.addEntity(newAsset);

        const irrigationPlanType: any = this.entityManager.metadataStore.getEntityType('IrrigationPlan');
        const newIrrigationPlan = irrigationPlanType.createEntity() as IrrigationPlan;

        newIrrigationPlan.AssetId = newAsset.AssetId;
        newIrrigationPlan.MinimumVolume = newPlanInfo.minimumVolume;
        newIrrigationPlan.MaximumVolume = newPlanInfo.maximumVolume;

        this.entityManager.addEntity(newIrrigationPlan);

        this._dataEntityService.saveChanges(true, this._dupeHandlerService.duplicatesOnly).then(
          () => {
            const planId = newAsset.AssetId;

            LocalStorageUtils.updateContextData((context) => {
              context.planId = planId;
            });

            this._state.go('app.water.irrigation-plans.detail', { id: planId });
            this._languageService.success('WATER.IRRIGATION.PLAN_ADDED');
          },
          (saveFailed) => {
            this._dupeHandlerService.showError(saveFailed);
            this._dataEntityService.rejectChanges();
          },
        );
      },
      () => {},
    );
  }

  public gotoPlanDetail(planId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.planId = planId;
      context.assetId = planId;
    });

    this._state.go('app.water.irrigation-plans.detail', { id: planId });
  }

  public selectAllDisplayPlans() {
    if (this.isSelectAllDisplayPlans) {
      this.displayPlans.forEach((plan) => {
        if (plan.status == WaterConstants.activeState) {
          plan.selected = true;
        }
      });
    } else {
      this.displayPlans.forEach((plan) => {
        plan.selected = false;
      });
    }
  }

  public selectPlanChanged() {
    if (this.displayPlans.some((a) => a.status == WaterConstants.activeState) == false) {
      this.isSelectAllDisplayPlans = false;
      return;
    }

    if (this.displayPlans.some((a) => a.status == WaterConstants.activeState && a.selected == false)) {
      this.isSelectAllDisplayPlans = false;
    } else {
      this.isSelectAllDisplayPlans = true;
    }
  }

  private daySum(planSchedule: fuse.irrigationPlanScheduleDto[], dayNumber: number): number {
    if (planSchedule == null) {
      return null;
    }

    return planSchedule.filter((a) => a.dayNumber == dayNumber).reduce((sum, item) => sum + item.irrigationVolume, 0);
  }

  private weeklySum(planSchedule: fuse.irrigationPlanScheduleDto[], dayNumber: number): number {
    if (planSchedule == null) {
      return null;
    }

    let weeklySum = 0;

    for (let i = 0; i < 7; i++) {
      const value = planSchedule
        .filter((a) => a.dayNumber == dayNumber + i)
        .reduce((sum, item) => sum + item.irrigationVolume, 0);

      if (value !== undefined) {
        weeklySum += value;
      }
    }

    return weeklySum;
  }

  private weeklyMillimeterSum(planSchedule: fuse.irrigationPlanScheduleDto[], siteName: string, dayNumber: number): number {
    if (planSchedule == null) {
      return null;
    }

    let weeklySum = 0;

    for (let i = 0; i < 7; i++) {
      const value = planSchedule.find((a) => a.siteName == siteName && a.dayNumber == dayNumber + i)?.irrigationMillimeter;

      if (value !== undefined) {
        weeklySum += value;
      }
    }

    return weeklySum;
  }

  private weeklyRuntimeRound(planSchedule: fuse.irrigationPlanScheduleDto[], siteName: string, dayNumber: number): string {
    if (planSchedule == null) {
      return null;
    }

    let weeklySum = 0;

    for (let i = 0; i < 7; i++) {
      const value = planSchedule.find((a) => a.siteName == siteName && a.dayNumber == dayNumber + i)?.irrigationHours;

      if (value !== undefined) {
        weeklySum += value;
      }
    }

    return ConversionUtils.convertToHHMM(weeklySum);
  }

  private weeklyVolumeSum(planSchedule: fuse.irrigationPlanScheduleDto[], siteName: string, dayNumber: number): number {
    if (planSchedule == null) {
      return null;
    }

    let weeklySum = 0;

    for (let i = 0; i < 7; i++) {
      const value = planSchedule.find((a) => a.siteName == siteName && a.dayNumber == dayNumber + i)?.irrigationVolume;

      if (value !== undefined) {
        weeklySum += value;
      }
    }

    return weeklySum;
  }

  private splitString(input: string, length: number): string[] {
    const outArray = [];

    if (input == null || input.length < length) {
      outArray.push(input);
      return outArray;
    }

    const chunks = input.split(' ');

    for (let i = 0; i < chunks.length; i++) {
      if (chunks[i].length > length) {
        const firstPart = chunks[i].substring(0, length);
        const remainPart = chunks[i].substring(length);

        chunks.splice(i, 1);
        chunks.splice(i, 0, firstPart);
        chunks.splice(i + 1, 0, remainPart);
      }
    }

    let combineStr = '';

    for (let i = 0; i < chunks.length; i++) {
      if (combineStr.length + chunks[i].length <= length) {
        combineStr += chunks[i] + ' ';
      } else {
        outArray.push(combineStr);
        i--;
        combineStr = '';
      }
    }

    outArray.push(combineStr);

    return outArray;
  }

  public openPrintIrrigationPlansDialog() {
    this._mdDialog.show({
      controller: PrintIrrigationPlanDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-plan/print-irrigation-plan-dialog.html',
      clickOutsideToClose: false,
    }).then((response) => {
      if (response?.print) {
        this.getIrrigationScheduleInfo();
      }
    });
  }

  private getIrrigationScheduleInfo() {
    const params = {
      accountId: this.accountId,
      planIds: this.filteredPlans.filter((a) => a.selected == true).map((a) => a.assetId),
      fromDayNumber: this.irriFromDayNumber,
      toDayNumber: this.irriToDayNumber,
    };

    this._http.get(CommonHelper.getApiUrl('water/GetIrrigationPlanSchedule'), { params: params }).then((response) => {
      if (response.data == null) {
        this._languageService.whoops();
      } else {
        const scheduleInfos = response.data as fuse.irrigationPlanScheduleDto[];

        this.printIrrigationReport(scheduleInfos);
      }
    });
  }

  private printIrrigationReport(scheduleInfos: fuse.irrigationPlanScheduleDto[]) {
    const irrigationReportSetting = this._localStorageService.get('irrigationReportSetting') as IIrrigationReportSetting;
    const siteReportSettings = [] as IIrrigationReportRowSetting[];
    let siteLines = 0;

    siteReportSettings.push({
      id: siteLines++,
      baseName: WaterConstants.irrigationReport_soilMoisture,
      name: WaterConstants.irrigationReport_soilMoisture,
    });

    if (irrigationReportSetting.irrigationMillimeter) {
      siteReportSettings.push({
        id: siteLines++,
        baseName: WaterConstants.irrigationReport_irrigation,
        name: `${WaterConstants.irrigationReport_irrigation}, (${this.fluidDepthNormalUnit})`,
      });
    }

    if (irrigationReportSetting.irrigationRuntime) {
      siteReportSettings.push({
        id: siteLines++,
        baseName: WaterConstants.irrigationReport_runtime,
        name: WaterConstants.irrigationReport_runtime,
      });
    }

    if (irrigationReportSetting.irrigationVolume) {
      siteReportSettings.push({
        id: siteLines++,
        baseName: WaterConstants.irrigationReport_volume,
        name: `${WaterConstants.irrigationReport_volume}, (${this.volumeNormalUnit})`,
      });
    }

    if (irrigationReportSetting.irrigationProportion) {
      siteReportSettings.push({
        id: siteLines++,
        baseName: WaterConstants.irrigationReport_proportion,
        name: WaterConstants.irrigationReport_proportion,
      });
    }

    const docDefinition = {
      info: { title: this._languageService.normInstant('COMMON.IRRIGATION_REPORT') },
      pageMargins: WaterConstants.irrigationReport_oneLineMargin,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      header: () => {
        return {
          alignment: 'justify',
          columns: [
            {
              text: this.accountName,
              style: 'page_header',
              width: 360,
            },
            {
              text: this._languageService.normInstant('COMMON.IRRIGATION_SCHEDULE'),
              style: 'page_header',
            },
            {
              image: this.reportLogo,
              width: (this.logoWidth * 30) / this.logoHeight,
              height: 30,
            },
          ],
          margin: [40, 20],
        };
      },
      footer: (currentPage: number, pageCount: number) => {
        return {
          margin: [40, 40],
          columns: [
            {
              text: `${this._languageService.normInstant('COMMON.PRINTED')} ${new Date().toString('HH:mm dd-MMM-yyyy')}`,
              alignment: 'left',
            },
            {
              text: `${currentPage.toString()} ${this._languageService.normInstant('COMMON.OF')} ${pageCount}`,
              alignment: 'right',
            },
          ],
        };
      },
      content: [],
      styles: {
        page_header: {
          fontSize: 18,
          bold: true,
        },
        page_header_small: {
          fontSize: 12,
        },
        header: {
          fontSize: 9,
          bold: true,
        },
        header_underline: {
          fontSize: 9,
          decoration: 'underline',
          bold: true,
          alignment: 'center',
        },
        subheader: {
          fontSize: 9,
          bold: true,
        },
        subheader_center: {
          fontSize: 9,
          bold: true,
          alignment: 'center',
        },
        cell: {
          alignment: 'center',
          fontSize: 9,
        },
        boldcell: {
          alignment: 'center',
          fontSize: 9,
          bold: true,
        },
        greyCell: {
          alignment: 'center',
          fontSize: 9,
          color: 'grey',
        },
        leftcell: {
          fontSize: 9,
        },
        rightcell: {
          fontSize: 9,
          alignment: 'right',
        },
      },
    };

    if (siteLines == 2) {
      docDefinition.pageMargins = WaterConstants.irrigationReport_twoLineMargin;
    } else if (siteLines == 3) {
      docDefinition.pageMargins = WaterConstants.irrigationReport_threeLineMargin;
    } else if (siteLines == 4) {
      docDefinition.pageMargins = WaterConstants.irrigationReport_fourLineMargin;
    } else if (siteLines == 5) {
      docDefinition.pageMargins = WaterConstants.irrigationReport_fiveLineMargin;
    }

    this.filteredPlans.filter((a) => a.selected).forEach((plan) => {
      const planContent = {
        layout: {
          hLineWidth: (i, node) => {
            if ((i - 3) % siteLines === 0 || i === node.table.body.length) {
              return 2;
            } else {
              return 1;
            }
          },
          vLineWidth: (i, node) => {
            return i === 5 ? 1 : i > 2 ? 1 : 1;
          },
          hLineColor: (i, node) => {
            return i === 2 || i === node.table.body.length ? 'black' : 'gray';
          },
          vLineColor: (i, node) => {
            return i === 5 || i === 12 ? 'black' : 'gray';
          },
        },
        pageBreak: 'after',
        table: {
          dontBreakRows: true,
          headerRows: 3,
          widths: [80, 100, '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
          body: [],
        },
      };

      const planSchedule = scheduleInfos.filter((a) => a.planId == plan.assetId);
      const tableBody = [];
      const tableHeaderLine1 = [];

      tableHeaderLine1.push(
        {
          border: [false, false, false, false],
          colSpan: 2,
          text: [
            {
              fontSize: 12,
              text: `${this._languageService.normInstant('COMMON.PLAN')}: `,
            },
            {
              bold: true,
              fontSize: 12,
              text: StringUtils.truncate(plan.assetName, 30),
            },
          ],
        },
        {},
      );

      tableHeaderLine1.push(
        {
          border: [false, false, true, false],
          colSpan: 3,
          style: 'header_underline',
          text: this._languageService.normInstant('COMMON.IRRIGATION_ACTUAL'),
        },
        {},
        {},
      );
      tableHeaderLine1.push(
        {
          border: [false, false, true, false],
          colSpan: 7,
          style: 'header_underline',
          text: `${this._languageService.normInstant('COMMON.IRRIGATION_SCHEDULED')} ${DateUtils.Locale.asDateDefault(this._dayNumberService.convertDayNumberToDate(this.irriFromDayNumber + 3))} to ${DateUtils.Locale.asDateDefault(this._dayNumberService.convertDayNumberToDate(this.irriToDayNumber - 1))}`,
        },
        {},
        {},
        {},
        {},
        {},
        {},
      );

      tableHeaderLine1.push({
        border: [false, false, false, false],
        text: '',
      });

      tableBody.push(tableHeaderLine1);

      const tableHeaderLine2 = [];

      tableHeaderLine2.push({
        border: [false, false, false, false],
        text: '',
      });

      tableHeaderLine2.push({
        border: [false, false, false, false],
        text: '',
      });

      for (let i = 0; i < 10; i++) {
        tableHeaderLine2.push({
          border: [false, false, i == 2 || i == 9 ? true : false, false],
          style: 'cell',
          text: DateUtils.Locale.asDateDayWithWeekday(this._dayNumberService.convertDayNumberToDate(this.irriFromDayNumber + i)),
        });
      }

      tableHeaderLine2.push({
        border: [false, false, false, false],
        style: 'subheader_center',
        text: this._languageService.normInstant('COMMON.TOTAL'),
      });

      tableBody.push(tableHeaderLine2);

      const tableHeaderLine3 = [];

      tableHeaderLine3.push({
        border: [false, false, false, true],
        style: 'subheader',
        text: this._languageService.normInstant('COMMON.SITE'),
      });

      tableHeaderLine3.push({
        border: [false, false, false, true],
        style: 'subheader',
        text: `${this._languageService.normInstant('COMMON.IRRIGATION_DAILY_TOTAL')} (${this.volumeNormalUnit}):`,
      });

      for (let i = 0; i < 10; i++) {
        const value = this.daySum(planSchedule, this.irriFromDayNumber + i);
        const toUomValue = this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, value);

        tableHeaderLine3.push({
          border: [false, false, i == 2 || i == 9 ? true : false, true],
          style: 'cell',
          text: toUomValue,
        });
      }

      const weeklyValue = this.weeklySum(planSchedule, this.irriFromDayNumber + 3);
      const toWeeklyUomValue = this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, weeklyValue);

      tableHeaderLine3.push({
        border: [false, false, false, true],
        style: 'boldcell',
        text: toWeeklyUomValue,
      });

      tableBody.push(tableHeaderLine3);
      //#endregion

      const siteNames = ArrayUtils.sortStrings(
        planSchedule
        .map((a) => a.siteName)
        .filter((value, index, self) => self.indexOf(value) === index)
      );

      siteNames.forEach((siteName) => {
        let skipSite = false;

        if (irrigationReportSetting.printAllSites == false) {
          if (
            planSchedule.some(
              (a) => a.siteName == siteName && a.irrigationMillimeter > 0 && a.dayNumber > this.irriFromDayNumber + 2,
            ) == false
          ) {
            skipSite = true;
          }
        }

        if (skipSite == false) {
          const siteNamePart = this.splitString(siteName, 14);

          siteReportSettings.forEach((line) => {
            const siteLine = [];

            siteLine.push({
              bold: true,
              border: [false, false, false, line.id != siteLines - 1 ? false : true],
              style: 'leftcell',
              text: siteNamePart[line.id],
            });

            siteLine.push({
              border: [true, false, true, true],
              style: 'leftcell',
              text: this._languageService.normInstant('COMMON.' + line.name),
            });

            for (let i = 0; i < 10; i++) {
              const cellBorder = [true, false, true, true];

              let cellFillColor = '#dddddd';
              let cellStyle = 'greyCell';

              const siteInfo = planSchedule.find((a) => a.siteName == siteName && a.dayNumber == this.irriFromDayNumber + i);

              if (siteInfo != null) {
                if (i > 2 && siteInfo.irrigationMillimeter != 0) {
                  cellFillColor = '#ffffff';
                  cellStyle = 'cell';
                }

                if (line.baseName == WaterConstants.irrigationReport_soilMoisture) {
                  const backColor =
                    siteInfo.soilMoisture < siteInfo.soilMoistureLower
                      ? '#FABD33'
                      : siteInfo.soilMoisture < siteInfo.soilMoistureUpper
                        ? '#3FA441'
                        : '#0084CA';

                  if (irrigationReportSetting.soilMoisture) {
                    siteLine.push({
                      border: cellBorder,
                      fillColor: backColor,
                      style: 'cell',
                      text: `${NumberUtils.round(siteInfo.soilMoisture, 1)}%`,
                    });
                  } else {
                    siteLine.push({
                      border: cellBorder,
                      fillColor: backColor,
                      style: 'cell',
                      text: '',
                    });
                  }
                } else if (line.baseName == WaterConstants.irrigationReport_irrigation) {
                  const value = siteInfo.irrigationMillimeter;
                  const toUomValue = this._unitOfMeasureService.convertFromBase('Fluid Depth', unitSizes.normal, value);

                  siteLine.push({
                    border: cellBorder,
                    fillColor: cellFillColor,
                    style: cellStyle,
                    text: toUomValue,
                  });
                } else if (line.baseName == WaterConstants.irrigationReport_runtime) {
                  siteLine.push({
                    border: cellBorder,
                    fillColor: cellFillColor,
                    style: cellStyle,
                    text: ConversionUtils.convertToHHMM(siteInfo.irrigationHours),
                  });
                } else if (line.baseName == WaterConstants.irrigationReport_proportion) {
                  siteLine.push({
                    border: cellBorder,
                    fillColor: cellFillColor,
                    style: cellStyle,
                    text: `${NumberUtils.round(siteInfo.irrigationProportion)}%`,
                  });
                } else if (line.baseName == WaterConstants.irrigationReport_volume) {
                  const value = siteInfo.irrigationVolume;
                  const toUomValue = this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, value);

                  siteLine.push({
                    border: cellBorder,
                    fillColor: cellFillColor,
                    style: cellStyle,
                    text: toUomValue,
                  });
                }
              } else {
                siteLine.push({
                  border: cellBorder,
                  fillColor: cellFillColor,
                  style: 'cell',
                  text: '',
                });
              }
            }

            if (line.baseName == WaterConstants.irrigationReport_soilMoisture) {
              siteLine.push({
                border: [true, false, false, true],
                text: '',
              });
            } else if (line.baseName == WaterConstants.irrigationReport_irrigation) {
              const value = this.weeklyMillimeterSum(planSchedule, siteName, this.irriFromDayNumber + 3);
              const toUomValue = this._unitOfMeasureService.convertFromBase('Fluid Depth', unitSizes.normal, value);

              siteLine.push({
                border: [true, false, false, true],
                style: 'boldcell',
                text: toUomValue,
              });
            } else if (line.baseName == WaterConstants.irrigationReport_runtime) {
              siteLine.push({
                border: [true, false, false, true],
                style: 'boldcell',
                text: this.weeklyRuntimeRound(planSchedule, siteName, this.irriFromDayNumber + 3),
              });
            } else if (line.baseName == WaterConstants.irrigationReport_proportion) {
              siteLine.push({
                border: [true, false, false, true],
                text: '',
              });
            } else if (line.baseName == WaterConstants.irrigationReport_volume) {
              const value = this.weeklyVolumeSum(planSchedule, siteName, this.irriFromDayNumber + 3);
              const toUomValue = this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, value);

              siteLine.push({
                border: [true, false, false, true],
                style: 'boldcell',
                text: toUomValue,
              });
            }

            tableBody.push(siteLine);
          });
        }
      });

      planContent.table.body = tableBody;
      docDefinition.content.push(planContent);
    });

    //remove last table pagebreak
    docDefinition.content[docDefinition.content.length - 1].pageBreak = 'false';

    const pdfFileName = this.accountName + '.pdf';

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(docDefinition).download(pdfFileName);
  }

  public toggleButton(button: string): void {
    if (button == 'Active') {
      this.showActive = !this.showActive;
    }

    if (button == 'Archived') {
      this.showArchived = !this.showArchived;
    }

    this.filterPlans();
  }

  public clearFilter(): void {
    this.nameFilter = '';
  }
}

angular.module('app.water').controller('IrrigationPlansController', IrrigationPlansController);
