import * as angular from 'angular';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { EntityList } from '@common/EntityList';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { IrrigationControllerService } from '@services/irrigation-controller.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { AddControllerDialogController } from './addcontroller-dialog.controller';
import { BaseController } from 'src/app/base.controller';

export class IrrigationControllersController extends BaseController {
  public assetList: EntityList<fuse.irrigationControllerDto>;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _dataEntityService: DataEntityService;
  private _dupeHandlerService: DupeHandlerService;
  private _irrigationControllerService: IrrigationControllerService;
  private _languageService: LanguageService;

  private adjustedTodayDayNumber: number;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    DupeHandlerService: DupeHandlerService,
    IrrigationControllerService: IrrigationControllerService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._q = $q;
    this._http = $http;
    this._mdDialog = $mdDialog;
    this._dataEntityService = DataEntityService;
    this._dupeHandlerService = DupeHandlerService;
    this._irrigationControllerService = IrrigationControllerService;
    this._languageService = LanguageService;

    this._dupeHandlerService.setDuplicateType(AssetClassNameEnum.IrrigationController);
  }

  $onInit() {
    this.getData();
  }

  private getData(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = {
      accountId: this.accountId,
      fromDayNumber: this.adjustedTodayDayNumber,
    };

    this._http.get(CommonHelper.getApiUrl('controllers/getIrrigationControllers'), { params: params }).then((response) => {
      this.assetList = new EntityList(response.data as fuse.irrigationControllerDto[]);

      defer.resolve();
    })
    .catch((response) => {
      this._languageService.whoops();

      defer.reject(response);
    });

    return defer.promise;
  }

  public addNewController(ev: MouseEvent) {
    this._mdDialog
      .show({
        controller: AddControllerDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/irrigation-controllers/addcontroller-dialog.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
      })
      .then((res) => {
        if (res) {
          this.createNew(res as fuse.dataCollectorProfileDto);
        }
      });
  }

  private createNew(collectionProfile: fuse.dataCollectorProfileDto) {
    const defer = this._q.defer<void>();
    const onFail = () => {
      this.getData();
    }
    this._http.post(CommonHelper.getApiUrl('controllers/createIrrigationController'), collectionProfile).then(
      (response) => {
        const assetId = response.data as number;

        LocalStorageUtils.updateContextData((context) => {
          context.assetId = assetId;
        });

        this._irrigationControllerService.checkAndGotoController(assetId, onFail);
        this._languageService.success('CONTROLLERS.CONTROLLER_ADDED');
        defer.resolve();
      },
      (saveFailed) => {
        this._dupeHandlerService.showError(saveFailed);
        this._dataEntityService.rejectChanges();
        defer.reject();
      },
    );
  }

  public mismatchIcons(status) {
    if (status) return 'icon-alert-circle red-500-fg';
    return 'icon-checkbox-marked-circle green-500-fg';
  }

  public mismatchText(status) {
    if (status) return 'CONTROLLERS.ATTENTION_REQUIRED';
    return 'CONTROLLERS.OK';
  }

  public gotoDetail(assetId: number) {
    this._irrigationControllerService.checkAndGotoController(assetId);
  }
}

angular.module('app.water').controller('IrrigationControllersController', IrrigationControllersController);
