import * as angular from 'angular';
import { unitSizes } from '@common/enums';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { WaterConstants } from '../WaterConstants';
import { BaseController } from 'src/app/base.controller';

export class AddOverrideDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  public filterDates: (date: Date) => boolean;

  public fromDate: Date;
  public endDate: Date;
  public overridePolicy: string;
  public overrideObserveDay: boolean;
  public maximumVolume: number;
  public minimumVolume: number;
  public overrideMaximumVolume: number;
  public overrideComment: string;
  public minimumDate: Date; //used in html
  public irrigationPolicies = WaterConstants.irrigationPolicies;
  public observeSchedules = WaterConstants.observeSchedules;
  private existedDates: Date[];
  public volumeNormalUnit: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    existedDates: Date[],
    maximumVolume: number,
    minimumVolume: number,
    DayNumberService: DayNumberService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.filterDates = (date: Date): boolean => {
      return this.existedDates.every((a) => a.getTime() != date.getTime());
    };

    this.volumeNormalUnit = UnitOfMeasureService.getUnitLabel('Volume', unitSizes.normal);
    this.existedDates = existedDates;
    this.minimumVolume = minimumVolume;
    this.maximumVolume = maximumVolume;

    this.minimumDate = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    this.fromDate = this.minimumDate.clone();
    this.endDate = this.fromDate.clone();
  }

  public addOverride() {
    for (let date = this.fromDate.clone(); date <= this.endDate; date.addDays(1)) {
      if (this.existedDates.some((a) => a.getTime() == date.getTime())) {
        this._languageService.error('WATER.IRRIGATION.ADD_OVERRIDE_DIALOG.DATE_OVERLAPPED');
        return;
      }
    }
    const newOverride = {
      fromDate: this.fromDate,
      dayNumber: this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.fromDate),
      endDate: this.endDate,
      endDayNumber: this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.endDate),
      minimumVolume: this.minimumVolume,
      maximumVolume: this.maximumVolume,
      comments: this.overrideComment,
      irrigationPolicy: this.overridePolicy,
      observeScheduleDays: this.overrideObserveDay,
    } as fuse.irrigationPlanOverrideDto;
    this._mdDialog.hide({ newOverride: newOverride });
  }

  public closeDialog() {
    if (this.scope['addForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(() => {
        this._mdDialog.hide();
      });
    } else {
      this._mdDialog.hide();
    }
  }

  public changeDate() {
    if (this.endDate.getTime() < this.fromDate.getTime()) {
      this.endDate = this.fromDate.clone();
    }
  }
}

angular.module('app.water').controller('AddOverrideDialogController', AddOverrideDialogController);
