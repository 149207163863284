import * as angular from 'angular';
import { StatusEnum } from '@indicina/swan-shared/enums/StatusEnum';
import { AllocationService } from '@services/allocation.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { ApiService } from '@services/api.service';
import { BaseController } from 'src/app/base.controller';

export class CopyAllocationDialogController extends BaseController {
  public allocation: fuse.allocationDto;
  public allocationForm: angular.IFormController;
  public validYears = [] as number[];
  public destYear: number;
  public error: string = null;
  public volUnit: uomUnit;
  public initialVolume: number;

  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _allocationService: AllocationService;
  private _apiService: ApiService;
  private _languageService: LanguageService;

  private _allocations: fuse.allocationDto[];
  private _assetId: number;
  private _originalFrom: Date;
  private _originalTo: Date;
  private _yearOffset: number;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    AllocationService: AllocationService,
    ApiService: ApiService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    assetId: number,
    allocations: fuse.allocationDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._state = $state;
    this._allocationService = AllocationService;
    this._apiService = ApiService;
    this._languageService = LanguageService;

    this.volUnit = AllocationService.volumeHugeUnit;
    this._assetId = assetId;
    this._allocations = allocations;
  }

  $onInit() {
    const baseAlloc = this._allocations.find((a) => a.id == this._assetId);
    this._apiService.getGeneric('allocation/rolloverInitial', { assetId: this._assetId }, (res) => (this.initialVolume = res));
    // Make partial copy as base for rollover allocation
    this.allocation = {
      name: baseAlloc.name,
      status: StatusEnum.Active,
      reference: baseAlloc.reference,
      effectiveFromDay: baseAlloc.effectiveFromDay,
      effectiveToDay: baseAlloc.effectiveToDay,
      waterSources: baseAlloc.waterSources,
    } as fuse.allocationDto;
    this._allocationService.setAllocationDates(this.allocation);
    this._originalFrom = this.allocation.effectiveFromDate;
    this._originalTo = this.allocation.effectiveToDate;
    const currentYear = this.allocation.effectiveFromDate.getFullYear();
    for (let i = 1; i <= 10; i++) {
      this.validYears.push(currentYear + i);
    }
  }

  public setRolloverDates() {
    this._yearOffset = this.destYear - this._originalFrom.getFullYear(); // send to backend
    // used to check date validity in front end
    this.allocation.effectiveFromDate = this._originalFrom.clone().addYears(this._yearOffset);
    this.allocation.effectiveToDate = this._originalTo.clone().addYears(this._yearOffset);

    this._allocationService.setAllocFromToDays(this.allocation);
  }

  public copy() {
    this.error = this._allocationService.checkWaterSources(this.allocation, this._allocations);

    if (this.error || !this.checkName() || this.allocationForm.$invalid) {
      return;
    }

    const rollAllocation = {
      assetId: this._assetId,
      name: this.allocation.name,
      initialVolume: this.initialVolume,
      reference: this.allocation.reference,
      yearOffset: this._yearOffset,
      initialComment: this._languageService.instant('ALLOCATION.INITIAL_VOLUME'),
    } as fuse.rolloverAllocationDto;

    this._apiService.postGeneric('allocation/rolloverallocation', rollAllocation, (res) => {
      const assetId = res as number;

      this.closeDialog();
      this._state.go('app.water.allocations.detail', { id: assetId });
    });
  }

  public closeDialog() {
    this._allocationService.setNoChanges();
    this._mdDialog.hide();
  }

  public checkName() {
    return this._allocationService.checkName(this.allocation, this._allocations, this.allocationForm);
  }
}

angular.module('app.water').controller('CopyAllocationDialogController', CopyAllocationDialogController);
