// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#add-site-in-plan {
  width: 800px;
}
#add-site-in-plan md-checkbox {
  margin-bottom: 0;
}
#add-site-in-plan table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
#add-site-in-plan table thead tr {
  height: 48px;
  background-color: #666;
}
#add-site-in-plan table thead tr th {
  color: #fff;
  text-align: center;
}
#add-site-in-plan table tbody tr {
  border-bottom: 1px solid #ccc;
  height: 32px;
}
#add-site-in-plan table tbody tr td {
  text-align: center;
}
#add-site-in-plan table tbody tr td > div {
  display: flex;
  justify-content: left;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/irrigation-plan/addSiteInPlan-dialog.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,gBAAA;AAER;AAAI;EACI,WAAA;EACA,iBAAA;EACA,yBAAA;AAER;AAAY;EACI,YAAA;EACA,sBAAA;AAEhB;AADgB;EACI,WAAA;EACA,kBAAA;AAGpB;AAEY;EACI,6BAAA;EACA,YAAA;AAAhB;AACgB;EACI,kBAAA;AACpB;AAAoB;EACI,aAAA;EACA,qBAAA;AAExB","sourcesContent":["#add-site-in-plan {\r\n    width: 800px;\r\n    md-checkbox {\r\n        margin-bottom: 0;\r\n    }\r\n    table {\r\n        width: 100%;\r\n        border-spacing: 0;\r\n        border-collapse: collapse;\r\n        thead {\r\n            tr {\r\n                height: 48px;\r\n                background-color: #666;\r\n                th {\r\n                    color: #fff;\r\n                    text-align: center;\r\n                }\r\n            }\r\n        }\r\n        tbody {\r\n            tr {\r\n                border-bottom: 1px solid #ccc;\r\n                height: 32px;\r\n                td {\r\n                    text-align: center;\r\n                    > div {\r\n                        display: flex;\r\n                        justify-content: left;\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
