import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { WaterConstants } from '../WaterConstants';
import { WaterHelpers } from '../water.helpers';
import { BaseController, PostSaveActions } from 'src/app/base.controller';

export class IrrigationOverrideController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;

  public selectedGroups: number[];
  public startDate: Date;
  public endDate: Date;
  private oriDataEntries = [] as fuse.dataEntryDto[];
  public dataEntries: fuse.dataEntryDto[];
  public filteredDataEntries: fuse.dataEntryDto[];
  public groups: fuse.groupProfileDto[];
  public volumeUnits: uomUnit[];
  public isSelectAll = false;
  public nameFilter = '';
  public minDate = SWANConstants.MinDate;
  public maxDate: Date;
  public isAnyChange = false;
  public irrigationUnits = angular.copy(WaterConstants.irrigationOverrideUnits);
  public irrigationUnitId = 0;
  public isVirtualFlowMeterExisted: boolean;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;

    this.scope['dataEntriesForm'] = {};

    const adjustedTodayDayNumber = DayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    const endDayNumber = adjustedTodayDayNumber - 1;
    const startDayNumber = endDayNumber - LocalStorageService.dataEntryDays + 1;
    this.startDate = DayNumberService.convertDayNumberToDate(startDayNumber);
    this.endDate = DayNumberService.convertDayNumberToDate(endDayNumber);
    this.maxDate = this.endDate.clone();
    const fluidDepthNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
    const volumeNormalUnit = UnitOfMeasureService.getUnitLabel('Volume', unitSizes.normal);
    this.irrigationUnits.forEach((unit) => {
      unit.name = unit.name.replace('DEPTH_UNIT', fluidDepthNormalUnit);
      unit.name = unit.name.replace('VOLUME_UNIT', volumeNormalUnit);
      unit.name = unit.name.replace('day', LanguageService.instant('COMMON.DAY'));
    });
    $scope.$watchGroup(['vm.nameFilter'], () => {
      if (this.dataEntries?.length) {
        this.filterDataEntries();
      }
    });
    NotifyingService.subscribe(NotifyEvents.App.SaveChanges.WaterIrrigationOverride, this.scope, (_event: angular.IAngularEvent, data: PostSaveActions) => {
      this.saveChanges(data);
    });
    this.irrigationUnitId = this._localStorageService.get('irrigationUnitId') as number;
    if (this.irrigationUnitId == null || this.irrigationUnitId > 2) {
      this.irrigationUnitId = 0;
    }
  }

  $onInit() {
    this.getAccountDataEntryInfo(this.startDate, this.endDate);
  }

  public changeStartDate() {
    let tempDate = this.startDate.clone().addDays(this._localStorageService.dataEntryDays - 1);
    if (tempDate > this.maxDate) {
      tempDate = this.maxDate.clone();
    }
    if (tempDate < this.endDate) {
      this.endDate = tempDate;
    }
    if (this.startDate > this.endDate) {
      this.endDate = tempDate;
    }
    this.getAccountDataEntryInfo(this.startDate, this.endDate);
  }

  public changeEndDate() {
    const tempDate = this.endDate.clone().addDays(-this._localStorageService.dataEntryDays + 1);
    if (tempDate > this.startDate) {
      this.startDate = tempDate;
    }
    if (this.endDate < this.startDate) {
      this.startDate = tempDate;
    }
    this.getAccountDataEntryInfo(this.startDate, this.endDate);
  }

  private getAccountDataEntryInfo(startDay: Date, endDay: Date): void {
    const startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(startDay);
    const endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(endDay);
    this._http
      .get(CommonHelper.getApiUrl(`water/accountDataEntryInfo?startdaynumber=${startDayNumber}&enddaynumber=${endDayNumber}`))
      .then(
        (res) => {
          if (res) {
            const accountDataEntryInfo = res.data as fuse.accountDataEntryInfo;
            this.oriDataEntries = accountDataEntryInfo.dataEntries;
            this.oriDataEntries.forEach((dataEntry) => {
              dataEntry.date = this._dayNumberService.convertDayNumberToLocaleDate(dataEntry.dayNumber);
              if (dataEntry.applicationActual != null) {
                dataEntry.volumeActual = dataEntry.applicationActual * dataEntry.area * 10;
                dataEntry.runtimeActual = WaterHelpers.getRuntimeForIrrigationRate(dataEntry.applicationActual, dataEntry.applicationRate);
              }
              if (dataEntry.applicationPlanned != null) {
                dataEntry.volumePlanned = dataEntry.applicationPlanned * dataEntry.area * 10;
                dataEntry.runtimePlanned = WaterHelpers.getRuntimeForIrrigationRate(dataEntry.applicationPlanned, dataEntry.applicationRate);
              }
            });
            this.dataEntries = angular.copy(this.oriDataEntries);
            this.groups = accountDataEntryInfo.groups;
            this.isVirtualFlowMeterExisted = accountDataEntryInfo.isVirtualFlowMeterExisted;
            this.filterDataEntries();
          }
        },
        (error) => {
          this._languageService.whoops();
        },
      );
  }

  private convertToRuntime(overrideHHMM: string): number {
    if (overrideHHMM == null || overrideHHMM == '') {
      return null;
    }
    const hhmm = overrideHHMM.split(':');
    if (hhmm.length != 2) {
      return undefined;
    }
    const hh = parseFloat(hhmm[0]);
    const mm = parseFloat(hhmm[1]);
    if (isNaN(hh) || isNaN(mm)) {
      return undefined;
    }
    return hh + mm / 60;
  }

  public changeDataEntry(dataEntry: fuse.dataEntryDto) {
    if (this.irrigationUnitId == 0) {
      if (dataEntry.volumeActual != null) {
        dataEntry.applicationActual = dataEntry.volumeActual / dataEntry.area / 10;
        dataEntry.runtimeActual = WaterHelpers.getRuntimeForIrrigationRate(dataEntry.applicationActual, dataEntry.applicationRate);
      } else {
        dataEntry.applicationActual = null;
        dataEntry.runtimeActual = null;
      }
    } else if (this.irrigationUnitId == 1) {
      if (dataEntry.applicationActual != null) {
        dataEntry.volumeActual = dataEntry.applicationActual * dataEntry.area * 10;
        dataEntry.runtimeActual = WaterHelpers.getRuntimeForIrrigationRate(dataEntry.applicationActual, dataEntry.applicationRate);
      } else {
        dataEntry.volumeActual = null;
        dataEntry.runtimeActual = null;
      }
    } else if (this.irrigationUnitId == 2) {
      if (dataEntry.runtimeActual != null) {
        const runtime = this.convertToRuntime(dataEntry.runtimeActual);
        if (runtime == undefined) {
          dataEntry.isValid = false;
        } else {
          dataEntry.isValid = true;
          dataEntry.applicationActual = runtime * dataEntry.applicationRate;
          dataEntry.volumeActual = dataEntry.applicationActual * dataEntry.area * 10;
        }
      } else {
        dataEntry.isValid = true;
        dataEntry.volumeActual = null;
        dataEntry.applicationActual = null;
      }
    }
    this.checkDataEntries();
  }

  public checkDataEntries() {
    let isAnyChange = false;
    for (let i = 0; i < this.oriDataEntries.length; i++) {
      const dataEntry = this.dataEntries[i];
      const oriDataEntry = this.oriDataEntries[i];
      if (this.irrigationUnitId == 0) {
        if (dataEntry.volumeActual != oriDataEntry.volumeActual) {
          dataEntry.isChanged = true;
          isAnyChange = true;
        } else {
          dataEntry.isChanged = false;
        }
      } else if (this.irrigationUnitId == 1) {
        if (dataEntry.applicationActual != oriDataEntry.applicationActual) {
          dataEntry.isChanged = true;
          isAnyChange = true;
        } else {
          dataEntry.isChanged = false;
        }
      } else if (this.irrigationUnitId == 2) {
        if (dataEntry.runtimeActual != oriDataEntry.runtimeActual) {
          dataEntry.isChanged = true;
          isAnyChange = true;
        } else {
          dataEntry.isChanged = false;
        }
      }
    }
    this.isAnyChange = isAnyChange;
    this._dataEntityService.hasDirtyCustomForm = isAnyChange;
  }

  public toggleSelectAll() {
    if (this.isSelectAll) {
      this.filteredDataEntries.forEach((a) => {
        a.isSelected = true;
      });
    } else {
      this.filteredDataEntries.forEach((a) => {
        a.isSelected = false;
      });
    }
  }

  public toggleSelect() {
    if (this.filteredDataEntries.some((a) => a.isSelected == false)) {
      this.isSelectAll = false;
      return;
    } else {
      this.isSelectAll = true;
    }
  }

  private filterDataEntries(): void {
    const startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.startDate);
    const endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.endDate);
    this.filteredDataEntries = this.dataEntries.filter((a) => a.dayNumber >= startDayNumber && a.dayNumber <= endDayNumber);

    if (this.selectedGroups?.length) {
      const groups = this.groups.filter((a) => this.selectedGroups.some((b) => b == a.groupId));
      const siteIds = [] as number[];
      groups.forEach((group) => {
        siteIds.push(...group.sites);
      });
      this.filteredDataEntries = this.filteredDataEntries.filter((a) => siteIds.some((b) => b == a.siteId));
    }

    if (this.nameFilter != null && this.nameFilter != '') {
      this.filteredDataEntries = this.filteredDataEntries.filter((a) => a.name.toLowerCase().includes(this.nameFilter.toLowerCase()));
    }

    this.toggleSelect();
  }

  public setActualToPlaned(): void {
    this.filteredDataEntries
      .filter((a) => a.isSelected && a.isValidSite)
      .forEach((dataEntry) => {
        if (this.irrigationUnitId == 0) {
          dataEntry.volumeActual = dataEntry.volumePlanned;
          if (dataEntry.volumeActual != null) {
            dataEntry.applicationActual = dataEntry.volumeActual / dataEntry.area / 10;
            dataEntry.runtimeActual = WaterHelpers.getRuntimeForIrrigationRate(dataEntry.applicationActual, dataEntry.applicationRate);
          } else {
            dataEntry.applicationActual = null;
            dataEntry.runtimeActual = null;
          }
        } else if (this.irrigationUnitId == 1) {
          dataEntry.applicationActual = dataEntry.applicationPlanned;
          if (dataEntry.applicationActual != null) {
            dataEntry.volumeActual = dataEntry.applicationActual * dataEntry.area * 10;
            dataEntry.runtimeActual = WaterHelpers.getRuntimeForIrrigationRate(dataEntry.applicationActual, dataEntry.applicationRate);
          } else {
            dataEntry.volumeActual = null;
            dataEntry.runtimeActual = null;
          }
        } else if (this.irrigationUnitId == 2) {
          dataEntry.runtimeActual = dataEntry.runtimePlanned;
          if (dataEntry.runtimeActual != null) {
            const runtime = this.convertToRuntime(dataEntry.runtimeActual);
            if (runtime == undefined) {
              dataEntry.isValid = false;
            } else {
              dataEntry.isValid = true;
              dataEntry.applicationActual = runtime * dataEntry.applicationRate;
              dataEntry.volumeActual = dataEntry.applicationActual * dataEntry.area * 10;
            }
          } else {
            dataEntry.isValid = true;
            dataEntry.volumeActual = null;
            dataEntry.applicationActual = null;
          }
        }
      });
    this.checkDataEntries();
  }

  public setActualToZero(): void {
    this.filteredDataEntries
      .filter((a) => a.isSelected && a.isValidSite)
      .forEach((dataEntry) => {
        dataEntry.volumeActual = 0;
        dataEntry.applicationActual = 0;
        dataEntry.runtimeActual = '00:00';
      });
    this.checkDataEntries();
  }

  public rejectChanges(): void {
    for (let i = 0; i < this.oriDataEntries.length; i++) {
      const dataEntry = this.dataEntries[i];
      const oriDataEntry = this.oriDataEntries[i];
      dataEntry.volumeActual = oriDataEntry.volumeActual;
      dataEntry.applicationActual = oriDataEntry.applicationActual;
      dataEntry.runtimeActual = oriDataEntry.runtimeActual;
    }
    this.filterDataEntries();
    this.checkDataEntries();
    this.toggleSelect();
  }

  public saveChanges(postSaveActions: PostSaveActions = null): void {
    if (this.scope['dataEntriesForm'].$valid == false) {
      const alert = this._languageService.fixErrorsDialog();
      this._mdDialog.show(alert).then(() => {
        this._mdDialog.hide();
      });
      return;
    }
    const changedDataEntries = this.filteredDataEntries.filter((a) => a.isChanged);
    this._http.post(CommonHelper.getApiUrl('water/saveirrigationoverrides'), changedDataEntries).then(
      (res) => {
        if (res) {
          this._dataEntityService.hasDirtyCustomForm = false;
          this._languageService.showSaveSuccess();

          if (super.executeAnyPostSaveActions(postSaveActions)) {
            return;
          }

          this.oriDataEntries = angular.copy(this.dataEntries);
          this.checkDataEntries();
          this.toggleSelect();
        } else {
          this._languageService.whoops();
        }
      },
      () => {
        this._languageService.whoops();
      },
    );
  }

  public clearFilter(): void {
    this.nameFilter = '';
  }

  public changeIrrigationUnit() {
    this._localStorageService.set('irrigationUnitId', this.irrigationUnitId);
  }
}

angular.module('app.water').controller('IrrigationOverrideController', IrrigationOverrideController);
