import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { UnitTypes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

/** Depending on arguments, may be used to 1) add new controller program protocol, 2) edit existing protocol, 3) send program to controller */
export class ProgramConflictController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  public name: string;
  public nameExists: boolean;

  public windows: fuse.pulseStartWindow[];
  public warnings: string[];
  public program: fuse.controllerProgram;
  public pulses = [];
  public dates = [];

  public fluidDepthUnit: uomUnit;
  public volumeUnit: uomUnit;
  public daysString: string;
  public conflicts: fuse.controllerConflict[];
  public fertigationEnabled: boolean;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    conflicts: fuse.controllerConflict[],
    fertigationEnabled: boolean,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.fluidDepthUnit = UnitOfMeasureService.getUnits(UnitTypes.FluidDepth);
    this.volumeUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume);

    this.conflicts = conflicts;
    this.fertigationEnabled = fertigationEnabled;
  }

  public sendProgram() {
    this._mdDialog.hide(this.conflicts);
  }

  public closeDialog() {
    this._mdDialog.hide(false);
  }
}

angular.module('app.water').controller('ProgramConflictController', ProgramConflictController);
