import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { ApiService } from '@services/api.service';
import { BaseController } from 'src/app/base.controller';

export class AddControllerDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  public profileList: fuse.dataCollectorProfileDto[];
  public noProfilesAvailable = false;
  public selectedProfileId = null;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    ApiService: ApiService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    ApiService.getGeneric('controllers/getControllerProfiles', {}, (data) => {
      this.profileList = data as fuse.dataCollectorProfileDto[];
      this.noProfilesAvailable = !this.profileList?.length;
    });
  }

  public addController() {
    const selectedProfile = this.profileList.find((p) => p.id == this.selectedProfileId);
    this._mdDialog.hide(selectedProfile);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.water').controller('AddControllerDialogController', AddControllerDialogController);
