// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#allocation-summary-tab {
  padding: 8px;
}
#allocation-summary-tab #watersource-table {
  max-height: calc(100vh - 310px);
  min-height: 220px;
  overflow: auto;
}
#allocation-summary-tab #watersource-table table {
  border-collapse: collapse;
}
#allocation-summary-tab #watersource-table table tr td {
  padding: 2px 0;
}
#allocation-summary-tab #watersource-table table tr td md-checkbox {
  margin: 0;
}
#allocation-summary-tab #watersource-table table tr td md-input-container {
  margin: 0;
  padding: 4px;
  width: 50px;
}
#allocation-summary-tab #comments {
  margin: 8px;
  border: #555 solid 1px;
}
#allocation-summary-tab #usage-summary-chart {
  width: 100%;
  height: 460px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/allocation/components/allocation-summary-tab.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,+BAAA;EACA,iBAAA;EACA,cAAA;AACJ;AACI;EACE,yBAAA;AACN;AAEQ;EACE,cAAA;AAAV;AAEU;EACE,SAAA;AAAZ;AAGU;EACE,SAAA;EACA,YAAA;EACA,WAAA;AADZ;AAQE;EACE,WAAA;EACA,sBAAA;AANJ;AASE;EACE,WAAA;EACA,aAAA;AAPJ","sourcesContent":["#allocation-summary-tab {\r\n  padding: 8px;\r\n\r\n  #watersource-table {\r\n    max-height: calc(100vh - 310px);\r\n    min-height: 220px;\r\n    overflow: auto;\r\n\r\n    table {\r\n      border-collapse: collapse;\r\n\r\n      tr {\r\n        td {\r\n          padding: 2px 0;\r\n\r\n          md-checkbox {\r\n            margin: 0;\r\n          }\r\n\r\n          md-input-container {\r\n            margin: 0;\r\n            padding: 4px;\r\n            width: 50px;\r\n          }\r\n        }\r\n      }\r\n    }\r\n  }\r\n\r\n  #comments {\r\n    margin: 8px;\r\n    border: #555 solid 1px;\r\n  }\r\n\r\n  #usage-summary-chart {\r\n    width: 100%;\r\n    height: 460px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
