import * as angular from 'angular';
import { unitSizes } from '@common/enums';
import { SWANConstants } from '@common/SWANConstants';
import { IIdNameItem } from '@common/models/interfaces';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { WaterConstants } from '../WaterConstants';
import { BaseController } from 'src/app/base.controller';

export class IrrigationUpdateMultiSitesDialogController extends BaseController {
  public regexTimeInput: RegExp;
  public irrigationSiteSettings: IIdNameItem[];
  public patterns = WaterConstants.patterns;
  public smbPreferences = WaterConstants.smbPreferences;
  public fluidDepthNormalUnit: string;
  public isPatternChecked: boolean;
  public pattern: string;
  public isPreferenceChecked: boolean;
  public smbPreference: string;
  public isPriorityChecked: boolean;
  public priority: number;
  public isMinimumChecked: boolean;
  public applicationMinimum: number;
  public applicationMinimumRuntime: string;
  public isIncrementChecked: boolean;
  public applicationIncrement: number;
  public applicationIncrementRuntime: string;
  public isPercentChecked: boolean;
  public applicationPercent: number;
  public applicationPercentRuntime: string;
  public isMaximumChecked: boolean;
  public applicationMaximum: number;
  public applicationMaximumRuntime: string;
  public applicationType: number;
  public applicationMinimumForm: angular.IFormController;
  public applicationIncrementForm: angular.IFormController;
  public applicationPercentForm: angular.IFormController;
  public applicationMaximumForm: angular.IFormController;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    applicationType: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.regexTimeInput = SWANConstants.RegexTimeInput;
    this.applicationType = applicationType;
    this.fluidDepthNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
  }

  public updateSettings() {
    const irrigationSiteSettings = [] as IIdNameItem[];

    const storeSettingValue = (name: string, value: string | number): void => {
      irrigationSiteSettings.push({ name, value } as IIdNameItem);
    };

    if (this.isPatternChecked) {
      storeSettingValue('pattern', this.pattern);
    }

    if (this.isPreferenceChecked) {
      storeSettingValue('smbPreference', this.smbPreference);
    }

    if (this.isPriorityChecked) {
      storeSettingValue('priority', this.priority);
    }

    if (this.isMinimumChecked) {
      const value = this.applicationType == 0 ? this.applicationMinimum : this.applicationMinimumRuntime;

      storeSettingValue('applicationMinimum', value);
    }

    if (this.isIncrementChecked) {
      const value = this.applicationType == 0 ? this.applicationIncrement : this.applicationIncrementRuntime;

      storeSettingValue('applicationIncrement', value);
    }

    if (this.isPercentChecked) {
      const value = this.applicationType == 0 ? this.applicationPercent : this.applicationPercentRuntime;

      storeSettingValue('application100Percent', value);
    }

    if (this.isMaximumChecked) {
      const value = this.applicationType == 0 ? this.applicationMaximum : this.applicationMaximumRuntime;

      storeSettingValue('applicationMaximum', value);
    }

    this._mdDialog.hide({ irrigationSiteSettings: irrigationSiteSettings });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public isDialogValid(): boolean {
    let isValid = false;
    if (this.isPatternChecked) {
      if (this.pattern == null) {
        return false;
      } else {
        isValid = true;
      }
    }
    if (this.isPreferenceChecked) {
      if (this.smbPreference == null) {
        return false;
      } else {
        isValid = true;
      }
    }
    if (this.isPriorityChecked) {
      if (this.priority == null) {
        return false;
      } else {
        isValid = true;
      }
    }
    if (this.isMinimumChecked) {
      if (this.applicationMinimumForm == null || this.applicationMinimumForm.$invalid) {
        return false;
      } else {
        isValid = true;
      }
    }
    if (this.isIncrementChecked) {
      if (this.applicationIncrementForm == null || this.applicationIncrementForm.$invalid) {
        return false;
      } else {
        isValid = true;
      }
    }
    if (this.isPercentChecked) {
      if (this.applicationPercentForm == null || this.applicationPercentForm.$invalid) {
        return false;
      } else {
        isValid = true;
      }
    }
    if (this.isMaximumChecked) {
      if (this.applicationMaximumForm == null || this.applicationMaximumForm.$invalid) {
        return false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  }
}

angular.module('app.water').controller('IrrigationUpdateMultiSitesDialogController', IrrigationUpdateMultiSitesDialogController);
