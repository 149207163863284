// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#irrigation-update-multi-sites md-dialog-content > form > div {
  padding: 10px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#irrigation-update-multi-sites md-dialog-content > form > div md-checkbox {
  margin-bottom: 0;
}
#irrigation-update-multi-sites md-dialog-content > form > div md-select {
  width: 160px;
}
#irrigation-update-multi-sites md-dialog-content > form > div md-input-container {
  margin: 0 !important;
  padding: 0;
  vertical-align: bottom;
}
#irrigation-update-multi-sites md-dialog-content > form > div md-input-container .md-errors-spacer {
  min-height: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/irrigation-plan/irrigationUpdateMultiSites-dialog.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,4CAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;AAGI;EACE,YAAA;AADN;AAII;EACE,oBAAA;EACA,UAAA;EACA,sBAAA;AAFN;AAIM;EAEE,0BAAA;AAHR","sourcesContent":["#irrigation-update-multi-sites {\r\n  md-dialog-content>form>div {\r\n    padding: 10px 8px;\r\n    border-bottom: 1px solid rgba(0, 0, 0, 0.12);\r\n\r\n    md-checkbox {\r\n      margin-bottom: 0;\r\n    }\r\n\r\n    md-select {\r\n      width: 160px;\r\n    }\r\n\r\n    md-input-container {\r\n      margin: 0 !important;\r\n      padding: 0;\r\n      vertical-align: bottom;\r\n\r\n      .md-errors-spacer {\r\n        //display: inline;\r\n        min-height: 0px !important;\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
