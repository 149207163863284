import * as angular from 'angular';
import * as moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { ConversionUtils } from '@indicina/swan-shared/utils/ConversionUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { NumberUtils } from '@indicina/swan-shared/utils/NumberUtils';
import { StringUtils } from '@indicina/swan-shared/utils/StringUtils';
import { IrrigationPlan } from 'src/app/_DBContext/IrrigationPlan';
import { IrrigationPlanOverride } from 'src/app/_DBContext/IrrigationPlanOverride';
import { Site } from 'src/app/_DBContext/Site';
import { SiteAsset } from 'src/app/_DBContext/SiteAsset';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { IIdNameItem } from '@common/models/interfaces';
import { DataEntityService } from '@services/data-entity.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { AccountsService } from '@services/administration/accounts.service';
import { DayNumberService } from '@services/day-number.service';
import { FetchDataService } from '@services/fetch-data.service';
import { WaterService } from '@services/water.service';
import { WaterConstants, IIrrigationReportSetting, ISetIrrigationSetting, IIrrigationReportRowSetting } from '../WaterConstants';
import { WaterHelpers } from '../water.helpers';
import { AddOverrideDialogController } from './addOverride-dialog.controller';
import { AddSiteInPlanDialogController } from './addSiteInPlan-dialog.controller';
import { IrrigationOptimiseDialogController } from './irrigation-optimise-dialog.controller';
import { IrrigationOptimiseCompleteDialogController } from './optimise-complete-dialog.controller';
import { SetPlanIrrigationDialogController } from './setPlanIrrigation-dialog.controller';
import { SiteGraphDialogController } from './siteGraph-dialog.controller';
import { IrrigationHelpDialogController } from './irrigationHelp-dialog.controller';
import { IrrigationUpdateMultiSitesDialogController } from './irrigationUpdateMultiSites-dialog.controller';
import { PrintIrrigationPlanDialogController } from './print-irrigation-plan-dialog.controller';
import { BaseController, PostSaveActions } from 'src/app/base.controller';

enum IrrigationPrimaryTabEnums {
  IrrigationPlan,
  IrrigationSetting,
  IrrigationSites,
  Logs,
}

export class IrrigationPlanController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _timeout: angular.ITimeoutService;
  private _accountService: AccountsService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _dupeHandlerService: DupeHandlerService;
  private _fetchDataService: FetchDataService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _notifyingService: NotifyingService;
  private _unitOfMeasureService: UnitOfMeasureService;
  private _waterService: WaterService;

  public regexTimeInput: RegExp;
  private irrigationAssetClass: fuse.assetClassDto;
  public accountDetail: fuse.accountDetail;
  public adjustedTodayDayNumber: number;
  private accountSites = [] as fuse.irrigationPlanSite[]; //sites in the account
  private irrigationPlans = [] as fuse.irrigationPlanProfileDto[];
  public primaryTab: number;

  public searchPlan: string = ''; //used in html
  public irrigationUnitId: number;
  public irrigationPlanIsLoaded = false;
  public isSavingChanges = false;
  public expandIsProcessing = false;
  public irrigationUnits = angular.copy(WaterConstants.irrigationUnits);
  public fertigationUnitId: number;
  public fertigationUnits = angular.copy(WaterConstants.fertigationUnits);

  public hasIrrigationOptimiseSubscription: boolean;

  public planNameExists = false;
  public newMinimumVolume: number;
  public newMaximumVolume: number;
  public planId: number;
  public planLastPublishedDate: Date;
  public planInfo: IrrigationPlan;
  public thePlanOverrides = [] as fuse.irrigationPlanOverrideDto[];
  public observeSchedules = WaterConstants.observeSchedules;
  public plan100PercentVolume: number;
  public planMaximumVolume: number;
  public planTheoreticalVolume: number;

  private irriFromDayNumber: number;
  private irriEndDayNumber: number;
  private planIrrigationInfos = [] as fuse.planIrrigationInfo[];
  private sitesSettings = [] as fuse.irrigationSiteSettingDto[];
  private collapsed = true;

  public patterns = WaterConstants.patterns;
  public smbPreferences = WaterConstants.smbPreferences;
  public planIsChanged = false;

  public irrigationPolicies = WaterConstants.irrigationPolicies;
  public isSelectAllCheckedSites = false;
  private reportLogo: string;
  private logoWidth: number;
  private logoHeight: number;
  public isSpinning = false;
  public fluidDepthNormalUnit: string;
  public volumeNormalUnit: string;
  public areaNormalUnit: string;
  public applicationMinimumValue: number;
  public fertigationEnabled: boolean;
  public applicationType: number;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    $timeout: angular.ITimeoutService,
    AccountsService: AccountsService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    DupeHandlerService: DupeHandlerService,
    FetchDataService: FetchDataService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    WaterService: WaterService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._timeout = $timeout;
    this._accountService = AccountsService;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._dupeHandlerService = DupeHandlerService;
    this._fetchDataService = FetchDataService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._notifyingService = NotifyingService;
    this._unitOfMeasureService = UnitOfMeasureService;
    this._waterService = WaterService;

    this._dupeHandlerService.setDuplicateType('Irrigation Plan');

    this.entityManager = DataEntityService.manager;
    this.regexTimeInput = SWANConstants.RegexTimeInput;
    this.irrigationAssetClass = SWANConstants.assetClasses.find((a) => a.name == AssetClassNameEnum.IrrigationPlan);
    this.scope['sitesForm'] = {};
    this.volumeNormalUnit = UnitOfMeasureService.getUnitLabel('Volume', unitSizes.normal);
    this.fluidDepthNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
    this.areaNormalUnit = UnitOfMeasureService.getUnitLabel('Area', unitSizes.normal);

    this.irrigationUnits.forEach((unit) => {
      unit.name = unit.name.replace('DEPTH_UNIT', this.fluidDepthNormalUnit);
      unit.name = unit.name.replace('day', this._languageService.instant('COMMON.DAY'));
    });

    this.fertigationUnits.forEach((unit) => {
      unit.name = unit.name.replace('DEPTH_UNIT', this.fluidDepthNormalUnit);
      unit.name = unit.name.replace('day', this._languageService.instant('COMMON.DAY'));
    });

    const context = LocalStorageUtils.contextData;
    const minimumValue = UnitOfMeasureService.convertFromBaseWithDecimal('Fluid Depth', unitSizes.normal, 0.1, 5);

    this.applicationMinimumValue = Math.ceil(minimumValue * 10000) / 10000;
    this.planId = context.planId;
    this.adjustedTodayDayNumber = DayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.irriFromDayNumber = this.adjustedTodayDayNumber - 3;
    this.irriEndDayNumber = this.adjustedTodayDayNumber + 7;

    this._notifyingService.subscribe(NotifyEvents.App.SaveChanges.WaterIrrigationPlan, this.scope, (_event: angular.IAngularEvent, data: PostSaveActions) => {
      this.saveChanges(data);
    });
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  $onInit() {
    let logoUrl = 'assets/images/logos/SWAN-Landscape-Logo-Colour.png';

    if (this.accountCustomStyling.NavigationLogoURL) {
      logoUrl = this.accountCustomStyling.NavigationLogoURL;
    }

    this._http.get(logoUrl, { responseType: 'blob' }).then((res) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.reportLogo = reader.result as string;
      };

      reader.readAsDataURL(res.data as Blob);
    });

    const img = new Image();

    img.addEventListener('load', () => {
      this.logoWidth = img.width;
      this.logoHeight = img.height;
    });

    img.src = logoUrl;

    this.searchPlan = '';
    this.irrigationUnitId = 0;
    this.fertigationUnitId = 0;
    this.applicationType = 0;

    let irrigationReportSetting = this._localStorageService.get('irrigationReportSetting') as IIrrigationReportSetting;

    if (irrigationReportSetting == null) {
      irrigationReportSetting = {
        displayUnitId: 0,
        applicationType: 0,
      } as IIrrigationReportSetting;

      this._localStorageService.set('irrigationReportSetting', irrigationReportSetting);
    } else {
      if (
        irrigationReportSetting.displayUnitId == null ||
        !Number.isInteger(irrigationReportSetting.displayUnitId) ||
        irrigationReportSetting.displayUnitId < 0 ||
        irrigationReportSetting.displayUnitId > 2
      ) {
        // do nothing
      } else {
        this.irrigationUnitId = irrigationReportSetting.displayUnitId;
        if (this.irrigationUnitId != 2) {
          this.fertigationUnitId = this.irrigationUnitId;
        }
      }

      if (
        irrigationReportSetting.applicationType == null ||
        !Number.isInteger(irrigationReportSetting.applicationType) ||
        irrigationReportSetting.applicationType < 0 ||
        irrigationReportSetting.applicationType > 1
      ) {
        // do nothing
      } else {
        this.applicationType = irrigationReportSetting.applicationType;
      }

      const promises = [] as angular.IPromise<void>[];

      promises.push(this.getAccountDetail());

      this._q.all(promises).then(() => {
        this.initialize();
      });
    }
  }

  private initialize() {
    this._dataEntityService.clear();

    const promises = [] as angular.IPromise<void>[];

    promises.push(this.getIrrigationPlans());
    promises.push(this.getAccountIrriSites());
    promises.push(this.getAccountSiteSettings(this.irriFromDayNumber, this.irriEndDayNumber));
    promises.push(this.fetchIrrigationPlanInfo(this.planId));
    promises.push(this.fetchAccountPlanSiteAssets());
    promises.push(this.fetchThePlanOverrides(this.planId));
    promises.push(this.fetchAccountSites()); //for breeze entities

    this._q.all(promises).then(() => {
      this.getPlanSiteDailySummaries(this.planId, this.irriFromDayNumber, this.irriEndDayNumber); //from past 3 days to future 7 days (include today)
      this.calculatePlanMaximumVolume();

      this._timeout(() => {
        this.setControlsDirty();
      }, 100);
    })
    .catch((reason) => {
      console.log(reason);
    });
  }

  private getAccountDetail(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._accountService.getSystemAccountById(this.accountId).then((result) => {
      this.accountDetail = result;
      this.hasIrrigationOptimiseSubscription = this.hasSubscription(WaterConstants.IrrigationOptimiseSubscription);
      defer.resolve();
    })
    .catch((response) => {
      defer.reject(response);
    });

    return defer.promise;
  }

  private getAccountSiteSettings(fromDayNumber: number, toDayNumber: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId, fromDayNumber: fromDayNumber, toDayNumber: toDayNumber };

    this._http.get(CommonHelper.getApiUrl('user/GetAccountSiteSetting'), { params: params }).then((response) => {
      if (!response.data) {
        this._languageService.whoops();
      } else {
        this.sitesSettings = ArrayUtils.sortByNumber(response.data as fuse.irrigationSiteSettingDto[], x => x.dayNumber, 'desc');
      }

      defer.resolve();
    })
    .catch((reason) => {
      defer.reject(reason);
    });

    return defer.promise;
  }

  private getPlanSiteDailySummaries(
    thePlanId: number,
    fromDayNumber: number,
    toDayNumber: number,
  ): angular.IPromise<void> {
    this.isSpinning = true;

    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId, planId: thePlanId, fromDayNumber: fromDayNumber, toDayNumber: toDayNumber };

    this._http.get(CommonHelper.getApiUrl('user/GetPlanSiteDailySummaries'), { params: params }).then(
      (result) => {
        this.isSpinning = false;

        if (result.data == null) {
          this._languageService.whoops();
        } else {
          this.planIrrigationInfos = [] as fuse.planIrrigationInfo[];
          this.createIrrigationPlanInfos(result.data as fuse.siteIrrigationInfoDto[]);
          this.amendPastIrrigationPlanInfos();
          this.planIrrigationInfos = ArrayUtils.sortByNumberWithMutation(this.planIrrigationInfos, x => x.dayNumber);
          this.irrigationPlanIsLoaded = true;
          this.planIsChanged = false;
          this._dataEntityService.hasDirtyCustomForm = false;
          this.checkSiteCards();
        }

        defer.resolve();
      },
      () => {
        this.isSpinning = false;
        defer.reject();
      },
    );

    return defer.promise;
  }

  private amendPastIrrigationPlanInfos() {
    const localeTodayPlanIrrigationInfo = this.planIrrigationInfos.find((a) => a.dayNumber == this.adjustedTodayDayNumber);

    if (localeTodayPlanIrrigationInfo != null) {
      this.planIrrigationInfos.forEach((planIrrigationInfo: fuse.planIrrigationInfo) => {
        if (planIrrigationInfo.dayNumber < this.adjustedTodayDayNumber) {
          this.accountSites.forEach((site: fuse.irrigationPlanSite) => {
            if (
              planIrrigationInfo.siteIrrigationInfos.some(
                (a) => a.siteId == site.siteId && a.dayNumber == planIrrigationInfo.dayNumber,
              ) == false
            ) {
              const siteIrrigationInfo = {
                siteId: site.siteId,
                siteName: site.siteName,
                dayNumber: planIrrigationInfo.dayNumber,
                localeDate: planIrrigationInfo.localeDate,
              } as fuse.siteIrrigationInfoDto;
              planIrrigationInfo.siteIrrigationInfos.push(siteIrrigationInfo);
            }
          });
        }
      });
    }
  }

  private fetchAccountPlanSiteAssets(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._fetchDataService.fetchAccountPlanSiteAssets(this.accountId).then(() => {
      defer.resolve();
    })
    .catch((reason) => {
      console.log(reason);
      defer.reject(reason);
    });

    return defer.promise;
  }

  private fetchAccountSites(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._fetchDataService.fetchAccountImuSites(this.accountId).then((imuSites) => {
      imuSites.forEach((imuSite: Site) => {
        const site = this.accountSites.find((a) => a.siteId == imuSite.AssetId);

        if (site != null) {
          site.sitePattern = imuSite.IrrigationPattern;
          site.sitePriority = imuSite.Priority;
          site.siteSmbPreference = imuSite.SmbPreference;
          site.siteApplication100Pct = imuSite.Application100Pct;
          site.siteApplicationIncrement = imuSite.ApplicationIncrements;
          site.siteApplicationMaximum = imuSite.ApplicationMaximum;
          site.siteApplicationMinimum = imuSite.ApplicationMinimum;
          site.siteApplication100PctRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplication100Pct, site.irrigationRate);
          site.siteApplicationIncrementRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationIncrement, site.irrigationRate);
          site.siteApplicationMaximumRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationMaximum, site.irrigationRate);
          site.siteApplicationMinimumRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationMinimum, site.irrigationRate);
        }
      });

      defer.resolve();
    })
    .catch((reason) => {
      console.log(reason);
      defer.reject(reason);
    });

    return defer.promise;
  }

  private fetchIrrigationPlanInfo(planId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._fetchDataService.fetchPlanInfo(planId).then((data) => {
      this.planInfo = data[0] as IrrigationPlan;
      this.newMinimumVolume = this.planInfo.MinimumVolume;
      this.newMaximumVolume = this.planInfo.MaximumVolume;
      this.planLastPublishedDate = this.planInfo.LastPublishedDate;
      defer.resolve();
    })
    .catch((reason) => {
      console.log(reason);
      defer.reject(reason);
    });

    return defer.promise;
  }

  private fetchThePlanOverrides(planId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._fetchDataService.fetchThePlanOverrides(planId).then((overrides) => {
        this.thePlanOverrides = [];

        overrides.forEach((override: IrrigationPlanOverride) => {
          this.thePlanOverrides.push({
            id: override.Id,
            assetId: override.AssetId,
            irrigationPolicy: override.IrrigationPolicy,
            observeScheduleDays: override.ObserveScheduleDays,
            maximumVolume: override.MaximumVolume,
            minimumVolume: override.MinimumVolume,
            comments: override.Comments,
            localeDate: this._dayNumberService.convertDayNumberToLocaleDate(override.DayNumber),
            dayNumber: override.DayNumber,
            fromDate: this._dayNumberService.convertDayNumberToLocaleDate(override.DayNumber),
            endDayNumber: override.EndDayNumber,
            endDate: this._dayNumberService.convertDayNumberToLocaleDate(override.EndDayNumber),
            isReadOnly: override.EndDayNumber < this.adjustedTodayDayNumber ? true : false,
          } as fuse.irrigationPlanOverrideDto);
        });

        defer.resolve();
      })
      .catch((reason) => {
        console.log(reason);
        defer.reject(reason);
      });

    return defer.promise;
  }

  private getIrrigationPlans(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId };

    this._http.get(CommonHelper.getApiUrl('water/irrigationPlanProfiles'), { params: params }).then((response) => {
      if (response.data == null) {
        this._languageService.whoops();
      } else {
        this.irrigationPlans = [];
        (response.data as fuse.irrigationPlanProfileDto[]).forEach((planInfo) => {
          if (planInfo.status == 'Active') this.irrigationPlans.push(planInfo);
        });
      }

      defer.resolve();
    })
    .catch((reason) => {
      console.log(reason);
      defer.reject(reason);
    });

    return defer.promise;
  }

  private checkFertigation() {
    this.fertigationEnabled = !this.accountSites.filter((s) => s.isInPlan).every((s) => !s.fertigationEnabled);
  }

  private getAccountIrriSites(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId };

    this._http.get(CommonHelper.getApiUrl('water/GetAccountIrriSites'), { params: params }).then((response) => {
      if (response.data == null) {
        this._languageService.whoops();
      } else {
        if (!this.accountSites.length) {
          this.accountSites = response.data as fuse.irrigationPlanSite[];

          this.accountSites.forEach((site) => {
            site.siteApplication100PctRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplication100Pct, site.irrigationRate);
            site.siteApplicationIncrementRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationIncrement, site.irrigationRate);
            site.siteApplicationMaximumRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationMaximum, site.irrigationRate);
            site.siteApplicationMinimumRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationMinimum, site.irrigationRate);
          });
        } else {
          const sites = response.data as fuse.irrigationPlanSite[];

          sites.forEach((site) => {
            const accountSite = this.accountSites.find((a) => a.siteId == site.siteId);

            if (accountSite != null) {
              accountSite.sitePattern = site.sitePattern;
              accountSite.sitePriority = site.sitePriority;
              accountSite.sitePriority = site.sitePriority;
              accountSite.siteApplication100Pct = site.siteApplication100Pct;
              accountSite.siteApplicationMinimum = site.siteApplicationMinimum;
              accountSite.siteApplicationMaximum = site.siteApplicationMaximum;
              accountSite.siteApplicationIncrement = site.siteApplicationIncrement;
              accountSite.existIrriPlanId = site.existIrriPlanId;
              accountSite.existIrriPlanName = site.existIrriPlanName;
              accountSite.validMillimeterValues = site.validMillimeterValues;
              accountSite.validPercentValues = site.validPercentValues;
              accountSite.validTimeValues = site.validTimeValues;
            }
          });
        }

        this.accountSites.forEach((site: fuse.irrigationPlanSite) => {
          site.systemCapacity = site.irrigationRate * 24;
          site.isInPlan = site.existIrriPlanId != null && site.existIrriPlanId == this.planId;
          site.collapsed = this.collapsed;
        });

        this.checkFertigation();
      }

      defer.resolve();
    })
    .catch((reason) => {
      console.log(reason);
      defer.reject(reason);
    });

    return defer.promise;
  }

  public gotoPlans() {
    this._waterService.setKeepFilter(true);

    this._state.go('app.water.irrigation-plans');
  }

  public gotoPlanDetail(plan: fuse.irrigationPlanInfoDto) {
    LocalStorageUtils.updateContextData((context) => {
      context.planId = plan.assetId;
    });

    this._state.go('app.water.irrigation-plans.detail', { id: plan.assetId });
  }

  public addSiteInPlanDialog(ev: MouseEvent) {
    const dialogSites = angular.copy(this.accountSites.filter((a) => a.isInPlan != true));

    this._mdDialog
      .show({
        controller: AddSiteInPlanDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/irrigation-plan/addSiteInPlan-dialog.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        locals: {
          dialogSites: dialogSites,
        },
      })
      .then((res) => {
        if (res) {
          if (res.dataRefreshRequired) {
            res.dialogSites.forEach((dialogSite: fuse.irrigationPlanSite) => {
              if (dialogSite.isInPlan) {
                const site = this.accountSites.find((a) => a.siteName == dialogSite.siteName);

                site.isInPlan = true;

                const siteAssetInitialValues = {
                  ParentAssetId: this.planId, // plan - Asset Id
                  ChildAssetId: site.siteId, // site Asset Id
                  DataInputId: this.irrigationAssetClass.dataInputId,
                  Coefficient: 1,
                  Priority: 1,
                } as SiteAsset;

                const siteAssetType = this.entityManager.metadataStore.getEntityType(
                  'SiteAsset',
                ) as breeze.EntityType;
                const newSiteAsset = siteAssetType.createEntity(siteAssetInitialValues) as SiteAsset;

                this.entityManager.addEntity(newSiteAsset);

                if (dialogSite.existIrriPlanId != null) {
                  //we need remove this site from old irrigation plan
                  const siteAssetPredict: breeze.Predicate = breeze.Predicate.create('ChildAssetId', '==', dialogSite.siteId).and(
                    'ParentAssetId',
                    '==',
                    dialogSite.existIrriPlanId,
                  );
                  const query = breeze.EntityQuery.from('SiteAsset').where(siteAssetPredict).toType('SiteAsset');
                  const siteAssetEntities: breeze.Entity[] = this.entityManager.executeQueryLocally(
                    query,
                  ) as SiteAsset[]; // query the cache (synchronous)

                  if (siteAssetEntities.length == 1) {
                    siteAssetEntities[0].entityAspect.setDeleted();
                  }
                }
              }
            });

            this.checkFertigation();
            this.calculatePlanMaximumVolume();

            this._timeout(() => {
              this.setControlsDirty();
            }, 100);
          }
        }
      });
  }

  private setControlsDirty() {
    angular.forEach(this.scope['sitesForm'], (childForm) => {
      let minimumValue: number;
      let incrementValue: number;
      let percentValue: number;
      let maximumValue: number;
      let irrigationRate: number;

      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          if (field.$name.includes('irrigationRate')) {
            irrigationRate = field.$modelValue;
          }

          if (field.$name.includes('applicationMinimum')) {
            if (this.applicationType == 0) {
              minimumValue = field.$modelValue;
            } else {
              minimumValue = this.convertToMillimeter(field.$modelValue, irrigationRate);
            }

            if (minimumValue < 0) {
              field.$setValidity('min', false);
            } else {
              field.$setValidity('min', true);

              if (minimumValue > 0 && minimumValue < 0.1) {
                field.$setValidity('valid', false);
              } else {
                field.$setValidity('valid', true);
              }
            }

            field.$setTouched();
          }

          if (field.$name.includes('applicationIncrement')) {
            if (this.applicationType == 0) {
              incrementValue = field.$modelValue;
            } else {
              incrementValue = this.convertToMillimeter(field.$modelValue, irrigationRate);
            }

            if (incrementValue / irrigationRate < 0.02) {
              field.$setValidity('valid', false);
            } else {
              field.$setValidity('valid', true);
            }

            field.$setTouched();
          }

          if (field.$name.includes('applicationPercent')) {
            if (this.applicationType == 0) {
              percentValue = field.$modelValue;
            } else {
              percentValue = this.convertToMillimeter(field.$modelValue, irrigationRate);
            }

            if (percentValue < minimumValue) {
              field.$setValidity('min', false);
            } else {
              field.$setValidity('min', true);
            }

            if (percentValue < incrementValue) {
              field.$setValidity('min2', false);
            } else {
              field.$setValidity('min2', true);
            }

            field.$setTouched();
          }
          if (field.$name.includes('applicationMaximum')) {
            if (this.applicationType == 0) {
              maximumValue = field.$modelValue;
            } else {
              maximumValue = this.convertToMillimeter(field.$modelValue, irrigationRate);
            }

            if (minimumValue != null && incrementValue != null && incrementValue != 0) {
              if (maximumValue < percentValue) {
                field.$setValidity('min', false);
              } else {
                field.$setValidity('min', true);
                const validValueCount = minimumValue == 0 ? 24 : 23;
                let valueNumber = (maximumValue - minimumValue) / incrementValue;

                if (valueNumber % 1 != 0) {
                  valueNumber = Math.floor(valueNumber - 0.000001);
                }

                if (valueNumber > validValueCount) {
                  field.$setValidity('valid', false);
                } else {
                  field.$setValidity('valid', true);
                }
              }
            }

            field.$setTouched();
          }
        }
      });
    });

    this.scope.$apply();
  }

  public updateSiteInPlan(item: fuse.irrigationPlanSite) {
    if (this.applicationType != 0) {
      item.siteApplication100Pct = this.convertToMillimeter(item.siteApplication100PctRuntime, item.irrigationRate);
      item.siteApplicationIncrement = this.convertToMillimeter(item.siteApplicationIncrementRuntime, item.irrigationRate);
      item.siteApplicationMaximum = this.convertToMillimeter(item.siteApplicationMaximumRuntime, item.irrigationRate);
      item.siteApplicationMinimum = this.convertToMillimeter(item.siteApplicationMinimumRuntime, item.irrigationRate);
    } else {
      item.siteApplication100PctRuntime = WaterHelpers.getRuntimeForIrrigationRate(item.siteApplication100Pct, item.irrigationRate);
      item.siteApplicationIncrementRuntime = WaterHelpers.getRuntimeForIrrigationRate(item.siteApplicationIncrement, item.irrigationRate);
      item.siteApplicationMaximumRuntime = WaterHelpers.getRuntimeForIrrigationRate(item.siteApplicationMaximum, item.irrigationRate);
      item.siteApplicationMinimumRuntime = WaterHelpers.getRuntimeForIrrigationRate(item.siteApplicationMinimum, item.irrigationRate);
    }

    const query = breeze.EntityQuery.from('Site');
    const accountSiteEntities = this.entityManager.executeQueryLocally(query) as Site[]; // query the cache (synchronous)
    const siteEntity = accountSiteEntities.find((a) => a.AssetId == item.siteId);

    siteEntity.Application100Pct = item.siteApplication100Pct;
    siteEntity.ApplicationIncrements = item.siteApplicationIncrement;
    siteEntity.ApplicationMaximum = item.siteApplicationMaximum;
    siteEntity.ApplicationMinimum = item.siteApplicationMinimum;
    siteEntity.IrrigationPattern = item.sitePattern;
    siteEntity.Priority = item.sitePriority;
    siteEntity.SmbPreference = item.siteSmbPreference;

    this.calculatePlanMaximumVolume();

    this._timeout(() => {
      this.setControlsDirty();
    }, 100);
  }

  public deleteSiteFromPlan(item: fuse.irrigationPlanSite) {
    const confirm = this._languageService
      .confirm()
      .title('COMMON.CONFIRM')
      .htmlContent('WATER.IRRIGATION.CONFIRM.REMOVE_SITE', { site: item.siteName })
      .parent(angular.element(document.body))
      .ok('COMMON.REMOVE')
      .cancel('COMMON.CANCEL');

    this._languageService.show(confirm).then(() => {
      const site = this.accountSites.find((a) => a.siteId == item.siteId);

      site.isInPlan = false;

      const planSiteAssetPredict: breeze.Predicate = breeze.Predicate.create('ParentAssetId', '==', this.planId).and(
        'ChildAssetId',
        '==',
        item.siteId,
      );
      const query = breeze.EntityQuery.from('SiteAsset').where(planSiteAssetPredict);
      const siteAssetEntities: breeze.Entity[] = this.entityManager.executeQueryLocally(query) as SiteAsset[]; // query the cache (synchronous)

      if (siteAssetEntities.length == 1) {
        siteAssetEntities[0].entityAspect.setDeleted();
      }

      this.calculatePlanMaximumVolume();
    });

    this.checkFertigation();
  }

  public rejectChanges(): void {
    this.planNameExists = false;
    this._dataEntityService.clear();

    const promises = [];

    promises.push(this.getIrrigationPlans());
    promises.push(this.getAccountIrriSites());
    promises.push(this.getAccountSiteSettings(this.irriFromDayNumber, this.irriEndDayNumber));
    promises.push(this.fetchAccountSites()); //for breeze entities
    promises.push(this.fetchIrrigationPlanInfo(this.planId));
    promises.push(this.fetchAccountPlanSiteAssets());
    promises.push(this.fetchThePlanOverrides(this.planId));

    this._q.all(promises).then(() => {
      this._dataEntityService.rejectChanges();
      this.calculatePlanMaximumVolume();

      setTimeout(() => {
        this.setControlsDirty();
      });
    })
    .catch((reason) => {
      console.log(reason);
    });
  }

  public saveChanges(postSaveActions: PostSaveActions = null): void {
    // NOTE: In case the 'planIsChanged' flag is 'true' and 'saveChanges' has been invoked it is an indication that the 'changeAccount' event has occured before the irrigation plan
    // optimisation changes were saved, thus we only need to take care of publishing the irrigation plan (as no other changes are allowed to be made if optimisation process is not saved).
    if (this.planIsChanged) {
      // Prevent to show the confirmation dialog on publishing an irrigation plan for the account change use case.
      this.publishIrrigationPlan(false).then(() => {
        postSaveActions?.changeAccount?.();
      });

      return;
    }

    let isValidChanges = true;

    //Setting tab
    if (
      this.planInfo.Asset.Name == null ||
      this.planNameExists ||
      this.newMinimumVolume == undefined ||
      this.newMaximumVolume == undefined
    ) {
      isValidChanges = false;
      this.viewIrrigationSetting();
    }

    if (
      this.thePlanOverrides.some(
        (a) => a.irrigationPolicy == undefined || a.observeScheduleDays == undefined || a.minimumVolume == null,
      )
    ) {
      isValidChanges = false;
      this.viewIrrigationSetting();
    }

    // if applicationType is hh:mm, need convert to millimeter
    if (this.applicationType != 0) {
      this.accountSites.forEach((site) => {
        site.siteApplicationMinimum = this.convertToMillimeter(site.siteApplicationMinimumRuntime, site.irrigationRate);
        site.siteApplicationIncrement = this.convertToMillimeter(site.siteApplicationIncrementRuntime, site.irrigationRate);
        site.siteApplication100Pct = this.convertToMillimeter(site.siteApplication100PctRuntime, site.irrigationRate);
        site.siteApplicationMaximum = this.convertToMillimeter(site.siteApplicationMaximumRuntime, site.irrigationRate);
      });
    }

    //site table
    if (
      this.accountSites.some(
        (a) =>
          a.isInPlan &&
          (a.sitePattern == null ||
            a.siteSmbPreference == null ||
            a.sitePriority == null ||
            a.siteApplicationMinimum == null ||
            a.siteApplicationIncrement == null ||
            a.siteApplication100Pct == null ||
            a.siteApplicationMaximum == null),
      )
    ) {
      isValidChanges = false;
      this.viewIrrigationSites();
    }

    if (this.accountSites.some((a) => a.isInPlan && a.siteApplicationMinimum != 0 && a.siteApplicationMinimum < 0.1)) {
      isValidChanges = false;
      this.viewIrrigationSites();
    }

    if (this.accountSites.some((a) => a.isInPlan && a.siteApplication100Pct > a.siteApplicationMaximum)) {
      isValidChanges = false;
      this.viewIrrigationSites();
    }

    this.accountSites.filter((a) => a.isInPlan).forEach((site) => {
      if (site.siteApplicationIncrement / site.irrigationRate < 0.02) {
        isValidChanges = false;
        this.viewIrrigationSites();
      }

      const validValueCount = site.siteApplicationMinimum == 0 ? 24 : 23;
      let valueNumber = (site.siteApplicationMaximum - site.siteApplicationMinimum) / site.siteApplicationIncrement;

      if (valueNumber % 1 != 0) {
        valueNumber = Math.floor(valueNumber - 0.000001);
      }

      if (valueNumber > validValueCount) {
        isValidChanges = false;

        this.viewIrrigationSites();
      }
    });

    if (isValidChanges == false) {
      const alert = this._languageService
        .alert()
        .title('COMMON.ALERT')
        .htmlContent('COMMON.PLEASE_FIX')
        .parent(angular.element(document.body))
        .ok('COMMON.OK');

      this._languageService.show(alert).then(() => {
        return;
      });
    } else {
      this.isSavingChanges = true;

      this._dataEntityService.saveChanges(true, this._dupeHandlerService.duplicatesOnly).then(() => {
        if (super.executeAnyPostSaveActions(postSaveActions)) {
          return;
        }

        this._languageService.showSaveSuccess();
        this._dataEntityService.clear();

        const promises = [];

        promises.push(this.getIrrigationPlans());
        promises.push(this.getAccountIrriSites());
        promises.push(this.getAccountSiteSettings(this.irriFromDayNumber, this.irriEndDayNumber));
        promises.push(this.fetchAccountSites()); //for breeze entities
        promises.push(this.fetchIrrigationPlanInfo(this.planId));
        promises.push(this.fetchAccountPlanSiteAssets());
        promises.push(this.fetchThePlanOverrides(this.planId));

        this._q.all(promises).then(() => {
          this.getPlanSiteDailySummaries(this.planId, this.irriFromDayNumber, this.irriEndDayNumber); //from past 3 days to future 7 days (include today)
          this.calculatePlanMaximumVolume();
          this.selectCheckedSiteChanged();

          setTimeout(() => {
            this.isSavingChanges = false;
            this.setControlsDirty();
          }, 100);
        })
        .catch((reason) => {
          this.isSavingChanges = false;
          console.log(reason);
        });
      },
      (error) => {
        this._dupeHandlerService.showError(error);
        this.isSavingChanges = false;
        console.log(error);
      });
    }
  }

  public checkPlanSettings() {
    this.planInfo.MaximumVolume = this.newMaximumVolume;
    this.planInfo.MinimumVolume = this.newMinimumVolume;

    if (this.planInfo.Asset.Name == null) {
      this.planNameExists = false;
    } else {
      if (this.irrigationPlans.some((a) => a.assetName == this.planInfo.Asset.Name && a.assetId != this.planInfo.AssetId)) {
        this.planNameExists = true;
      } else {
        this.planNameExists = false;
      }
    }
  }

  public updatePlanOverride(planOverride: fuse.irrigationPlanOverrideDto) {
    const query = breeze.EntityQuery.from('IrrigationPlanOverride').toType('IrrigationPlanOverride');
    const irrigationPlanOverrides = this.entityManager.executeQueryLocally(query) as IrrigationPlanOverride[]; // query the cache (synchronous)
    const irrigationPlanOverride = irrigationPlanOverrides.find((a) => a.DayNumber == planOverride.dayNumber);

    irrigationPlanOverride.IrrigationPolicy = planOverride.irrigationPolicy;
    irrigationPlanOverride.ObserveScheduleDays = planOverride.observeScheduleDays;
    irrigationPlanOverride.MinimumVolume = planOverride.minimumVolume;
    irrigationPlanOverride.MaximumVolume = planOverride.maximumVolume;
    irrigationPlanOverride.Comments = planOverride.comments;
  }

  public addPlanOverride() {
    const existedDates = [] as Date[];

    this.thePlanOverrides.forEach((override) => {
      for (let date = override.fromDate.clone(); date <= override.endDate; date.addDays(1)) {
        existedDates.push(date.clone());
      }
    });

    this._mdDialog.show({
      controller: AddOverrideDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-plan/addOverride-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        existedDates: existedDates,
        maximumVolume: this.planInfo.MaximumVolume,
        minimumVolume: this.planInfo.MinimumVolume,
      },
    }).then((res) => {
      if (res) {
        const newOverride = res.newOverride as fuse.irrigationPlanOverrideDto;

        this.thePlanOverrides.push(newOverride);

        const newIrrigationOverrideEntity = {
          AssetId: this.planInfo.AssetId,
          DayNumber: newOverride.dayNumber,
          EndDayNumber: newOverride.endDayNumber,
          MaximumVolume: newOverride.maximumVolume,
          MinimumVolume: newOverride.minimumVolume,
          ObserveScheduleDays: newOverride.observeScheduleDays,
          IrrigationPolicy: newOverride.irrigationPolicy,
          Comments: newOverride.comments,
        } as IrrigationPlanOverride;

        const irrigationOverrideType = this.entityManager.metadataStore.getEntityType(
          'IrrigationPlanOverride',
        ) as breeze.EntityType;

        const newIrrigationOverride = irrigationOverrideType.createEntity(
          newIrrigationOverrideEntity,
        ) as IrrigationPlanOverride;

        this.entityManager.addEntity(newIrrigationOverride);
      }
    });
  }

  public deletePlanOverride(item: fuse.irrigationPlanOverrideDto) {
    const confirm = this._languageService
      .confirm()
      .title('COMMON.CONFIRM')
      .htmlContent('WATER.IRRIGATION.CONFIRM.DELETE_OVERRIDE', { date: DateUtils.Locale.asDateDayAndMonth(item.fromDate) })
      .parent(angular.element(document.body))
      .ok('COMMON.CONTINUE')
      .cancel('COMMON.CANCEL');

    this._languageService.show(confirm).then(() => {
      const overrideIndex = this.thePlanOverrides.findIndex((a) => a.dayNumber == item.dayNumber);

      this.thePlanOverrides.splice(overrideIndex, 1);

      const planOverridePredict: breeze.Predicate = breeze.Predicate.create('AssetId', '==', item.assetId).and(
        'DayNumber',
        '==',
        item.dayNumber,
      );
      const query = breeze.EntityQuery.from('IrrigationPlanOverride').where(planOverridePredict).toType('IrrigationPlanOverride');
      const planOverrideEntities: breeze.Entity[] = this.entityManager.executeQueryLocally(
        query,
      ) as IrrigationPlanOverride[]; // query the cache (synchronous)

      // Changed in case multiple sesions created one at the same time (bug).
      planOverrideEntities?.forEach((entity) => entity.entityAspect.setDeleted());
    });
  }

  public siteIrrigationMMChanged(siteInfo: fuse.siteIrrigationInfoDto) {
    const site = this.accountSites.find((a) => a.siteId == siteInfo.siteId);

    site.allIrrigation_mm = null;
    site.allIrrigation_duration = null;
    site.allIrrigation_percent = null;

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    if (siteInfo.irrigation_mm == undefined) {
      siteInfo.irrigationVolume = undefined;
      siteInfo.irrigation_duration = null;
      siteInfo.irrigation_percent = null;
      siteInfo.soilMoisture = undefined;

      const planInfo = this.planIrrigationInfos.find((a) => a.dayNumber == siteInfo.dayNumber);

      planInfo.totalVolume = undefined;
      planInfo.errorMessage = null;

      const planInfoAfterwards = this.planIrrigationInfos.filter((a) => a.dayNumber > siteInfo.dayNumber);

      planInfoAfterwards.forEach((siteInfoAfterwards) => {
        siteInfoAfterwards.siteIrrigationInfos.forEach((siteInfoItem) => {
          if (siteInfoItem.siteId == siteInfo.siteId) {
            siteInfoItem.soilMoisture = undefined;
          }
        });
      });
    } else {
      if (siteInfo.isScheduled == false && siteInfo.irrigation_mm != 0) {
        this._languageService.warning('WATER.IRRIGATION.WARNING.NOT_WATERING_DAY');
      }

      const validIndex = site.validMillimeterValues.findIndex((a) => a == siteInfo.irrigation_mm);

      siteInfo.irrigation_duration = site.validTimeValues[validIndex];
      siteInfo.irrigation_percent = site.validPercentValues[validIndex];
      siteInfo.irrigationDb = siteInfo.irrigation_mm;
      siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;
      siteInfo.warningIrrigation = null;
      siteInfo.warningPercent = null;
      siteInfo.warningRuntime = null;

      const planInfo = this.planIrrigationInfos.find((a) => a.dayNumber == siteInfo.dayNumber);

      planInfo.totalVolume = planInfo.siteIrrigationInfos.reduce((sum, siteInfo) => sum + siteInfo.irrigationVolume, 0);

      this.checkPlanInfo(planInfo);

      if (planInfo.irrigationPolicy == 'Must Not') {
        if (siteInfo.irrigationLocked && siteInfo.irrigation_mm != 0) {
          siteInfo.contradiction = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.CONTRADICTION_LOCKED');
        } else {
          siteInfo.contradiction = null;
        }
      } else {
        siteInfo.contradiction = null;
      }

      this.sitePredictSoilMoisture(siteInfo.siteId, siteInfo.dayNumber);
      this.checkSiteCards();
    }
  }

  public siteFertigationMMChanged(siteInfo: fuse.siteIrrigationInfoDto) {
    const site = this.accountSites.find((a) => a.siteId == siteInfo.siteId);

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    if (siteInfo.fertigation_mm == -1) {
      siteInfo.fertigation_duration = -1;
    } else {
      const validIndex = site.validMillimeterValues.findIndex((a) => a == siteInfo.fertigation_mm);

      siteInfo.fertigation_duration = site.validTimeValues[validIndex];

      this.checkSiteCards();
    }
  }

  public siteIrrigationDurationChanged(siteInfo: fuse.siteIrrigationInfoDto) {
    const site = this.accountSites.find((a) => a.siteId == siteInfo.siteId);

    site.allIrrigation_mm = null;
    site.allIrrigation_duration = null;
    site.allIrrigation_percent = null;

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    if (siteInfo.irrigation_duration == null) {
      siteInfo.irrigationVolume = undefined;
      siteInfo.irrigation_mm = null;
      siteInfo.irrigation_percent = null;

      const planInfo = this.planIrrigationInfos.find((a) => a.dayNumber == siteInfo.dayNumber);

      planInfo.totalVolume = undefined;
      planInfo.errorMessage = null;

      const planInfoAfterwards = this.planIrrigationInfos.filter((a) => a.dayNumber > siteInfo.dayNumber);

      planInfoAfterwards.forEach((siteInfoAfterwards) => {
        siteInfoAfterwards.siteIrrigationInfos.forEach((siteInfoItem) => {
          if (siteInfoItem.siteId == siteInfo.siteId) {
            siteInfoItem.soilMoisture = undefined;
          }
        });
      });
    } else {
      if (siteInfo.isScheduled == false && siteInfo.irrigation_duration != 0) {
        this._languageService.warning('WATER.IRRIGATION.WARNING.NOT_WATERING_DAY');
      }

      const validIndex = site.validTimeValues.findIndex((a) => a == siteInfo.irrigation_duration);

      siteInfo.irrigation_mm = site.validMillimeterValues[validIndex];
      siteInfo.irrigation_percent = site.validPercentValues[validIndex];
      siteInfo.irrigationDb = siteInfo.irrigation_mm;
      siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;
      siteInfo.warningIrrigation = null;
      siteInfo.warningPercent = null;
      siteInfo.warningRuntime = null;

      const planInfo = this.planIrrigationInfos.find((a) => a.dayNumber == siteInfo.dayNumber);

      planInfo.totalVolume = planInfo.siteIrrigationInfos.reduce((sum, siteInfo) => sum + siteInfo.irrigationVolume, 0);

      this.checkPlanInfo(planInfo);

      if (planInfo.irrigationPolicy == 'Must Not') {
        if (siteInfo.irrigationLocked && siteInfo.irrigation_mm != 0) {
          siteInfo.contradiction = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.CONTRADICTION_LOCKED');
        } else {
          siteInfo.contradiction = null;
        }
      } else {
        siteInfo.contradiction = null;
      }

      this.sitePredictSoilMoisture(siteInfo.siteId, siteInfo.dayNumber);
      this.checkSiteCards();
    }
  }

  public siteFertigationDurationChanged(siteInfo: fuse.siteIrrigationInfoDto) {
    const site = this.accountSites.find((a) => a.siteId == siteInfo.siteId);

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    if (siteInfo.fertigation_duration == null) {
      siteInfo.fertigation_mm = null;
    } else if (siteInfo.fertigation_duration == -1) {
      siteInfo.fertigation_mm = -1;
    } else {
      const validIndex = site.validTimeValues.findIndex((a) => a == siteInfo.fertigation_duration);

      siteInfo.fertigation_mm = site.validMillimeterValues[validIndex];

      this.checkSiteCards();
    }
  }

  public siteIrrigationPercentChanged(siteInfo: fuse.siteIrrigationInfoDto) {
    const site = this.accountSites.find((a) => a.siteId == siteInfo.siteId);

    site.allIrrigation_mm = null;
    site.allIrrigation_duration = null;
    site.allIrrigation_percent = null;

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    if (siteInfo.irrigation_percent == undefined) {
      siteInfo.irrigationVolume = undefined;
      siteInfo.irrigation_duration = null;
      siteInfo.irrigation_mm = null;

      const planInfo = this.planIrrigationInfos.find((a) => a.dayNumber == siteInfo.dayNumber);

      planInfo.totalVolume = undefined;
      planInfo.errorMessage = null;

      const planInfoAfterwards = this.planIrrigationInfos.filter((a) => a.dayNumber > siteInfo.dayNumber);

      planInfoAfterwards.forEach((siteInfoAfterwards) => {
        siteInfoAfterwards.siteIrrigationInfos.forEach((siteInfoItem) => {
          if (siteInfoItem.siteId == siteInfo.siteId) {
            siteInfoItem.soilMoisture = undefined;
          }
        });
      });
    } else {
      if (siteInfo.isScheduled == false && siteInfo.irrigation_percent != 0) {
        this._languageService.warning('WATER.IRRIGATION.WARNING.NOT_WATERING_DAY');
      }

      const validIndex = site.validPercentValues.findIndex((a) => a == siteInfo.irrigation_percent);

      siteInfo.irrigation_mm = site.validMillimeterValues[validIndex];
      siteInfo.irrigation_duration = site.validTimeValues[validIndex];
      siteInfo.irrigationDb = siteInfo.irrigation_mm;
      siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;
      siteInfo.warningIrrigation = null;
      siteInfo.warningPercent = null;
      siteInfo.warningRuntime = null;

      const planInfo = this.planIrrigationInfos.find((a) => a.dayNumber == siteInfo.dayNumber);

      planInfo.totalVolume = planInfo.siteIrrigationInfos.reduce((sum, siteInfo) => sum + siteInfo.irrigationVolume, 0);

      this.checkPlanInfo(planInfo);

      if (planInfo.irrigationPolicy == 'Must Not') {
        if (siteInfo.irrigationLocked && siteInfo.irrigation_mm != 0) {
          siteInfo.contradiction = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.CONTRADICTION_LOCKED');
        } else {
          siteInfo.contradiction = null;
        }
      } else {
        siteInfo.contradiction = null;
      }

      this.sitePredictSoilMoisture(siteInfo.siteId, siteInfo.dayNumber);
      this.checkSiteCards();
    }
  }

  public siteAllIrrigationMMChanged(site: fuse.irrigationPlanSite) {
    if (site.allIrrigation_mm == null) {
      site.allIrrigation_duration = null;
      site.allIrrigation_percent = null;

      return;
    }

    const validIndex = site.validMillimeterValues.findIndex((a) => a == site.allIrrigation_mm);

    site.allIrrigation_duration = site.validTimeValues[validIndex];
    site.allIrrigation_percent = site.validPercentValues[validIndex];

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    this.planIrrigationInfos.forEach((planInfo) => {
      if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
        return;
      }

      planInfo.totalVolume = 0;
      planInfo.errorMessage = null;

      planInfo.siteIrrigationInfos.forEach((siteDayInfo) => {
        if (siteDayInfo.siteId == site.siteId) {
          siteDayInfo.irrigation_mm = site.allIrrigation_mm;
          siteDayInfo.irrigation_duration = site.allIrrigation_duration;
          siteDayInfo.irrigation_percent = site.allIrrigation_percent;
          siteDayInfo.irrigationDb = siteDayInfo.irrigation_mm;
          siteDayInfo.irrigationVolume = siteDayInfo.irrigationDb * siteDayInfo.siteIrrigationArea * 10;
          siteDayInfo.warningIrrigation = null;
          siteDayInfo.warningPercent = null;
          siteDayInfo.warningRuntime = null;
          planInfo.totalVolume = planInfo.siteIrrigationInfos.reduce((sum, item) => sum + item.irrigationVolume, 0);

          this.checkPlanInfo(planInfo);

          if (planInfo.irrigationPolicy == 'Must Not') {
            if (siteDayInfo.irrigationLocked && siteDayInfo.irrigation_mm != 0) {
              siteDayInfo.contradiction = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.CONTRADICTION_LOCKED');
            } else {
              siteDayInfo.contradiction = null;
            }
          } else {
            siteDayInfo.contradiction = null;
          }
        }
      });
    });

    this.sitePredictSoilMoisture(site.siteId, this.adjustedTodayDayNumber);
    this.checkSiteCards();
  }

  public siteAllIrrigationDurationChanged(site: fuse.irrigationPlanSite) {
    if (site.allIrrigation_duration == null) {
      site.allIrrigation_mm = null;
      site.allIrrigation_percent = null;

      return;
    }

    const validIndex = site.validTimeValues.findIndex((a) => a == site.allIrrigation_duration);

    site.allIrrigation_mm = site.validMillimeterValues[validIndex];
    site.allIrrigation_percent = site.validPercentValues[validIndex];

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    this.planIrrigationInfos.forEach((planInfo) => {
      if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
        return;
      }

      planInfo.totalVolume = 0;
      planInfo.errorMessage = null;

      planInfo.siteIrrigationInfos.forEach((siteInfo) => {
        if (siteInfo.siteId == site.siteId) {
          siteInfo.irrigation_mm = site.allIrrigation_mm;
          siteInfo.irrigation_duration = site.allIrrigation_duration;
          siteInfo.irrigation_percent = site.allIrrigation_percent;
          siteInfo.irrigationDb = siteInfo.irrigation_mm;
          siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;
          siteInfo.warningIrrigation = null;
          siteInfo.warningPercent = null;
          siteInfo.warningRuntime = null;
          planInfo.totalVolume = planInfo.siteIrrigationInfos.reduce((sum, item) => sum + item.irrigationVolume, 0);

          this.checkPlanInfo(planInfo);

          if (planInfo.irrigationPolicy == 'Must Not') {
            if (siteInfo.irrigationLocked && siteInfo.irrigation_mm != 0) {
              siteInfo.contradiction = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.CONTRADICTION_LOCKED');
            } else {
              siteInfo.contradiction = null;
            }
          } else {
            siteInfo.contradiction = null;
          }
        }
      });
    });

    this.sitePredictSoilMoisture(site.siteId, this.adjustedTodayDayNumber);
    this.checkSiteCards();
  }

  public siteAllIrrigationPercentChanged(site: fuse.irrigationPlanSite) {
    if (site.allIrrigation_percent == null) {
      site.allIrrigation_mm = null;
      site.allIrrigation_duration = null;

      return;
    }

    const validIndex = site.validPercentValues.findIndex((a) => a == site.allIrrigation_percent);

    site.allIrrigation_mm = site.validMillimeterValues[validIndex];
    site.allIrrigation_duration = site.validTimeValues[validIndex];

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    this.planIrrigationInfos.forEach((planInfo) => {
      if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
        return;
      }

      planInfo.totalVolume = 0;
      planInfo.errorMessage = null;

      planInfo.siteIrrigationInfos.forEach((siteInfo) => {
        if (siteInfo.siteId == site.siteId) {
          siteInfo.irrigation_mm = site.allIrrigation_mm;
          siteInfo.irrigation_duration = site.allIrrigation_duration;
          siteInfo.irrigation_percent = site.allIrrigation_percent;
          siteInfo.irrigationDb = siteInfo.irrigation_mm;
          siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;
          siteInfo.warningIrrigation = null;
          siteInfo.warningPercent = null;
          siteInfo.warningRuntime = null;
          planInfo.totalVolume = planInfo.siteIrrigationInfos.reduce((sum, item) => sum + item.irrigationVolume, 0);

          this.checkPlanInfo(planInfo);

          if (planInfo.irrigationPolicy == 'Must Not') {
            if (siteInfo.irrigationLocked && siteInfo.irrigation_mm != 0) {
              siteInfo.contradiction = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.CONTRADICTION_LOCKED');
            } else {
              siteInfo.contradiction = null;
            }
          } else {
            siteInfo.contradiction = null;
          }
        }
      });
    });

    this.sitePredictSoilMoisture(site.siteId, this.adjustedTodayDayNumber);
    this.checkSiteCards();
  }

  public siteAllFertigationMMChanged(site: fuse.irrigationPlanSite) {
    if (site.allFertigation_mm == null) {
      site.allFertigation_duration = null;
      return;
    }

    if (site.allFertigation_mm == -1) {
      site.allFertigation_duration = -1;
    } else {
      const validIndex = site.validMillimeterValues.findIndex((a) => a == site.allFertigation_mm);
      site.allFertigation_duration = site.validTimeValues[validIndex];
    }

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;

    this.planIrrigationInfos.forEach((planInfo) => {
      if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
        return;
      }

      planInfo.siteIrrigationInfos.forEach((siteDayInfo) => {
        if (siteDayInfo.siteId == site.siteId) {
          siteDayInfo.fertigation_mm = site.allFertigation_mm;
          siteDayInfo.fertigation_duration = site.allFertigation_duration;
        }
      });
    });

    this.checkSiteCards();
  }

  public siteAllFertigationDurationChanged(site: fuse.irrigationPlanSite) {
    if (site.allFertigation_duration == null) {
      site.allFertigation_mm = null;

      return;
    }

    if (site.allFertigation_duration == -1) {
      site.allFertigation_mm = -1;
    } else {
      const validIndex = site.validTimeValues.findIndex((a) => a == site.allFertigation_duration);
      site.allFertigation_mm = site.validMillimeterValues[validIndex];
    }

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;
    this.planIrrigationInfos.forEach((planInfo) => {
      if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
        return;
      }

      planInfo.siteIrrigationInfos.forEach((siteInfo) => {
        if (siteInfo.siteId == site.siteId) {
          siteInfo.fertigation_mm = site.allFertigation_mm;
          siteInfo.fertigation_duration = site.allFertigation_duration;
        }
      });
    });

    this.checkSiteCards();
  }

  public toggleSiteIrrigationLock(siteInfo: fuse.siteIrrigationInfoDto) {
    //toggle doesn't change breeze entity
    siteInfo.irrigationLocked = !siteInfo.irrigationLocked;

    this.planIsChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;
  }

  public toggleCollapsed() {
    this.expandIsProcessing = true;

    this._timeout(() => {
      this.collapsed = !this.collapsed;

      this.accountSites.forEach((site) => {
        site.collapsed = this.collapsed;
      });

      this.expandIsProcessing = false;
    });
  }

  public siteExpand(site: fuse.irrigationPlanSite) {
    site.collapsed = false;
  }

  public siteCollapse(site: fuse.irrigationPlanSite) {
    site.collapsed = true;
  }

  private hasSubscription(subscriptionName: string): boolean {
    if (this.account) {
      return this.accountDetail.subscriptions.some((a) => a.name == subscriptionName);
    } else {
      return false;
    }
  }

  public optimiseIrrigationPlan() {
    if (this.hasIrrigationOptimiseSubscription) {
      if (this.hasDataChanges) {
        const alert = this._languageService
          .alert()
          .title('COMMON.ALERT')
          .htmlContent('WATER.IRRIGATION.WARNING.SAVE_BEFORE_OPTIMISE')
          .parent(angular.element(document.body))
          .ok('COMMON.OK');

        this._languageService.show(alert).then(() => {
          return;
        });
      } else {
        this._mdDialog.show({
          controller: IrrigationOptimiseDialogController,
          controllerAs: 'vm',
          templateUrl: 'src/app/pages/water/irrigation-plan/irrigation-optimise-dialog.html',
          parent: angular.element(document.body),
          locals: {
            planId: this.planId,
            irrigationPlanInfos: this.planIrrigationInfos,
          },
        }).then((returnData) => {
          if (!returnData?.dataRefreshRequired) {
            return;
          }

          this.planIsChanged = true;
          this._dataEntityService.hasDirtyCustomForm = true;

          this.planIrrigationInfos.forEach((planInfo) => {
            const resPlanInfo = returnData.irrigationPlanInfos.find(
              (a) => a.dayNumber == planInfo.dayNumber,
            ) as fuse.planIrrigationInfo;

            planInfo.totalVolume = resPlanInfo.totalVolume;
            planInfo.error = resPlanInfo.error;

            if (!planInfo.error) {
              this.checkPlanInfo(planInfo);
            } else {
              //optimise has error. will display error message
              planInfo.errorMessage = this._languageService.instant(resPlanInfo.errorMessage);
            }

            planInfo.siteIrrigationInfos.forEach((siteInfo) => {
              const resSiteInfo = resPlanInfo.siteIrrigationInfos.find((a) => a.siteId == siteInfo.siteId);

              siteInfo.irrigation_mm = Math.round(resSiteInfo.irrigation_mm * 10000) / 10000;
              siteInfo.irrigationDb = resSiteInfo.irrigation_mm;

              const irriSite = this.accountSites.find((a) => a.siteId == siteInfo.siteId);
              const validIndex = irriSite.validMillimeterValues.findIndex((a) => a == siteInfo.irrigation_mm);

              siteInfo.irrigation_duration = validIndex == -1 ? null : irriSite.validTimeValues[validIndex];
              siteInfo.irrigation_percent = validIndex == -1 ? null : irriSite.validPercentValues[validIndex];

              siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;
              siteInfo.soilMoisture = resSiteInfo.soilMoisture;
            });
          });

          this.checkSiteCards();

          if (this.apf.hasIrrigationPlanFull) {
            this._mdDialog.show({
              controller: IrrigationOptimiseCompleteDialogController,
              controllerAs: 'vm',
              templateUrl: 'src/app/pages/water/irrigation-plan/optimise-complete-dialog.html',
              parent: angular.element(document.body),
              clickOutsideToClose: false,
            });
          }
        });
      }
    } else {
      const alert = this._languageService
        .alert()
        .title('COMMON.ALERT')
        .htmlContent('WATER.IRRIGATION.WARNING.CONTACT_SWAN')
        .ok('COMMON.CLOSE');

      this._languageService.show(alert);
    }
  }

  public openSetAllInPlanDialog() {
    this._mdDialog.show({
      controller: SetPlanIrrigationDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-plan/setPlanIrrigation-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        irrigationUnitId: this.irrigationUnitId,
        fertigationUnitId: this.fertigationUnitId,
        isFertigationEnabled: this.fertigationEnabled,
        localeDate: null,
      },
    }).then((res) => {
      if (!res) {
        return;
      }

      this.planIsChanged = true;
      this._dataEntityService.hasDirtyCustomForm = true;

      const setting = res as ISetIrrigationSetting;

      if (setting.isIrrigationChanged) {
        this.accountSites.forEach((site) => {
          site.allIrrigation_mm = null;
          site.allIrrigation_duration = null;
          site.allIrrigation_percent = null;
        });

        this.planIrrigationInfos.forEach((planInfo) => {
          if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
            return;
          }

          planInfo.siteIrrigationInfos.forEach((siteInfo) => {
            const site = this.accountSites.find((a) => a.siteId == siteInfo.siteId);
            let irrigationApplication: number;
            let irrigationPercent: number;
            let irrigationRuntime: number;
            let steppedIrrigation: number;

            if (setting.irrigationPercent != null) {
              irrigationPercent = setting.irrigationPercent;
              irrigationApplication = (site.siteApplication100Pct * irrigationPercent) / 100;
              irrigationRuntime = irrigationApplication / siteInfo.siteIrrigationRate;
            } else if (setting.irrigationApplication != null) {
              irrigationApplication = setting.irrigationApplication;
              irrigationPercent = (irrigationApplication / site.siteApplication100Pct) * 100;
              irrigationRuntime = irrigationApplication / siteInfo.siteIrrigationRate;
            } else if (setting.irrigationRuntime != null) {
              irrigationRuntime = setting.irrigationRuntime;
              irrigationApplication = siteInfo.siteIrrigationRate * setting.irrigationRuntime;
              irrigationPercent = (irrigationApplication / site.siteApplication100Pct) * 100;
            }

            if (irrigationApplication == 0) {
              steppedIrrigation = 0;
            } else if (irrigationApplication <= site.siteApplicationMinimum) {
              steppedIrrigation = site.siteApplicationMinimum;
            } else {
              if (site.siteApplicationIncrement > 0) {
                let i = 0;

                for (; ; i++) {
                  if (site.siteApplicationMinimum + i * site.siteApplicationIncrement > site.siteApplicationMaximum) {
                    i--;
                    break;
                  }

                  if (irrigationApplication > site.siteApplicationMinimum + (i + 0.5) * site.siteApplicationIncrement)
                  {
                    continue;
                  }

                  break;
                }

                steppedIrrigation = site.siteApplicationMinimum + i * site.siteApplicationIncrement;
              }
            }

            const validIndex = site.validMillimeterValues.findIndex(
              (a) => a == Math.round(steppedIrrigation * 10000) / 10000,
            );

            siteInfo.irrigation_mm = site.validMillimeterValues[validIndex];
            siteInfo.irrigation_duration = site.validTimeValues[validIndex];
            siteInfo.irrigation_percent = site.validPercentValues[validIndex];
            siteInfo.irrigationDb = siteInfo.irrigation_mm;
            siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;

            if (siteInfo.irrigation_percent * 100 != irrigationPercent) {
              siteInfo.warningPercent = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.WARNING_PERCENT', {
                v0: Math.round(irrigationPercent),
                v1: Math.round(siteInfo.irrigation_percent * 100),
              });

              const irrigationOverrideUom = this._unitOfMeasureService.convertFromBase(
                'Fluid Depth',
                unitSizes.normal,
                irrigationApplication,
              );
              const irrigationSteppedUom = this._unitOfMeasureService.convertFromBase(
                'Fluid Depth',
                unitSizes.normal,
                siteInfo.irrigation_mm,
              );

              siteInfo.warningIrrigation = this._languageService.instant(
                'WATER.IRRIGATION.PLANNING_TAB.WARNING_IRRIGATION',
                {
                  v0: irrigationOverrideUom,
                  v1: this.fluidDepthNormalUnit,
                  v2: irrigationSteppedUom,
                  v3: this.fluidDepthNormalUnit,
                },
              );

              const runtimeOverrideStr = ConversionUtils.convertToHHMM(irrigationRuntime);
              const runtimeSteppedStr = ConversionUtils.convertToHHMM(siteInfo.irrigation_duration);

              siteInfo.warningRuntime = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.WARNING_RUNTIME', {
                v0: runtimeOverrideStr,
                v1: runtimeSteppedStr,
              });
            } else {
              siteInfo.warningIrrigation = null;
              siteInfo.warningPercent = null;
              siteInfo.warningRuntime = null;
            }

            if (planInfo.irrigationPolicy == 'Must Not') {
              if (siteInfo.irrigationLocked && siteInfo.irrigation_mm != 0) {
                siteInfo.contradiction = this._languageService.instant(
                  'WATER.IRRIGATION.PLANNING_TAB.CONTRADICTION_LOCKED',
                );
              } else {
                siteInfo.contradiction = null;
              }
            } else {
              siteInfo.contradiction = null;
            }
          });

          planInfo.totalVolume = planInfo.siteIrrigationInfos.reduce((sum, siteInfo) => sum + siteInfo.irrigationVolume, 0);

          this.checkPlanInfo(planInfo);
        });

        this.planPredictSoilMoisture(this.planId, this.adjustedTodayDayNumber);
      }

      if (setting.irrigationLockStatus == true) {
        this.planIrrigationInfos.forEach((planInfo) => {
          if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
            return;
          }

          planInfo.siteIrrigationInfos.forEach((site) => {
            site.irrigationLocked = true;
          });
        });
      }

      if (setting.irrigationLockStatus == false) {
        this.planIrrigationInfos.forEach((planInfo) => {
          if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
            return;
          }

          planInfo.siteIrrigationInfos.forEach((site) => {
            site.irrigationLocked = false;
          });
        });
      }

      if (setting.isFertigateFixed) {
        this.planIrrigationInfos.forEach((planInfo) => {
          if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
            return;
          }

          planInfo.siteIrrigationInfos.forEach((siteInfo) => {
            const site = this.accountSites.find((a) => a.siteId == siteInfo.siteId);
            let fertigationApplication: number;
            if (setting.fertigationApplication != null) {
              fertigationApplication = setting.fertigationApplication;
            } else if (setting.fertigationRuntime != null) {
              fertigationApplication = siteInfo.siteIrrigationRate * setting.fertigationRuntime;
            }
            let steppedFertigation: number;
            if (fertigationApplication == 0) {
              steppedFertigation = 0;
            } else if (fertigationApplication <= site.siteApplicationMinimum) {
              steppedFertigation = site.siteApplicationMinimum;
            } else {
              if (site.siteApplicationIncrement > 0) {
                let i = 0;
                for (; ; i++) {
                  if (site.siteApplicationMinimum + i * site.siteApplicationIncrement > site.siteApplicationMaximum) {
                    i--;
                    break;
                  }
                  if (fertigationApplication > site.siteApplicationMinimum + (i + 0.5) * site.siteApplicationIncrement)
                    continue;
                  break;
                }
                steppedFertigation = site.siteApplicationMinimum + i * site.siteApplicationIncrement;
              }
            }
            const validIndex = site.validMillimeterValues.findIndex(
              (a) => a == Math.round(steppedFertigation * 10000) / 10000,
            );
            siteInfo.fertigation_mm = site.validMillimeterValues[validIndex];
            siteInfo.fertigation_duration = site.validTimeValues[validIndex];
          });
        });
      }

      if (setting.isFertigateEntire) {
        this.planIrrigationInfos.forEach((planInfo) => {
          if (planInfo.dayNumber < this.adjustedTodayDayNumber) {
            return;
          }

          planInfo.siteIrrigationInfos.forEach((siteInfo) => {
            siteInfo.fertigation_mm = -1;
            siteInfo.fertigation_duration = -1;
          });
        });
      }

      this.checkSiteCards();
    });
  }

  public openSetOneDayInPlanDialog(planInfo: fuse.planIrrigationInfo) {
    this._mdDialog.show({
      controller: SetPlanIrrigationDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-plan/setPlanIrrigation-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        irrigationUnitId: this.irrigationUnitId,
        fertigationUnitId: this.fertigationUnitId,
        isFertigationEnabled: this.fertigationEnabled,
        localeDate: planInfo.localeDate,
      },
    }).then((res) => {
      if (!res) {
        return;
      }

      this.planIsChanged = true;
      this._dataEntityService.hasDirtyCustomForm = true;

      const setting = res as ISetIrrigationSetting;

      if (setting.isIrrigationChanged) {
        this.accountSites.forEach((site) => {
          site.allIrrigation_mm = null;
          site.allIrrigation_duration = null;
          site.allIrrigation_percent = null;
        });

        planInfo.siteIrrigationInfos.forEach((siteInfo) => {
          const site = this.accountSites.find((a) => a.siteId == siteInfo.siteId);
          let irrigationApplication: number;
          let irrigationPercent: number;
          let irrigationRuntime: number;
          let steppedIrrigation: number;

          if (setting.irrigationPercent != null) {
            irrigationPercent = setting.irrigationPercent;
            irrigationApplication = (site.siteApplication100Pct * irrigationPercent) / 100;
            irrigationRuntime = irrigationApplication / siteInfo.siteIrrigationRate;
          } else if (setting.irrigationApplication != null) {
            irrigationApplication = setting.irrigationApplication;
            irrigationPercent = (irrigationApplication / site.siteApplication100Pct) * 100;
            irrigationRuntime = irrigationApplication / siteInfo.siteIrrigationRate;
          } else if (setting.irrigationRuntime != null) {
            irrigationRuntime = setting.irrigationRuntime;
            irrigationApplication = siteInfo.siteIrrigationRate * irrigationRuntime;
            irrigationPercent = (irrigationApplication / site.siteApplication100Pct) * 100;
          }

          if (irrigationApplication == 0) {
            steppedIrrigation = 0;
          } else if (irrigationApplication <= site.siteApplicationMinimum) {
            steppedIrrigation = site.siteApplicationMinimum;
          } else {
            if (site.siteApplicationIncrement > 0) {
              let i = 0;

              for (; ; i++) {
                if (site.siteApplicationMinimum + i * site.siteApplicationIncrement > site.siteApplicationMaximum) {
                  i--;

                  break;
                }

                if (irrigationApplication > site.siteApplicationMinimum + (i + 0.5) * site.siteApplicationIncrement) {
                  continue;
                }

                break;
              }

              steppedIrrigation = site.siteApplicationMinimum + i * site.siteApplicationIncrement;
            }
          }

          const validIndex = site.validMillimeterValues.findIndex((a) => a == Math.round(steppedIrrigation * 10000) / 10000);

          siteInfo.irrigation_mm = site.validMillimeterValues[validIndex];
          siteInfo.irrigation_duration = site.validTimeValues[validIndex];
          siteInfo.irrigation_percent = site.validPercentValues[validIndex];
          siteInfo.irrigationDb = siteInfo.irrigation_mm;
          siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;

          if (siteInfo.irrigation_percent * 100 != irrigationPercent) {
            siteInfo.warningPercent = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.WARNING_PERCENT', {
              v0: Math.round(irrigationPercent),
              v1: Math.round(siteInfo.irrigation_percent * 100),
            });

            const irrigationOverrideUom = this._unitOfMeasureService.convertFromBase(
              'Fluid Depth',
              unitSizes.normal,
              irrigationApplication,
            );
            const irrigationSteppedUom = this._unitOfMeasureService.convertFromBase(
              'Fluid Depth',
              unitSizes.normal,
              siteInfo.irrigation_mm,
            );

            siteInfo.warningIrrigation = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.WARNING_IRRIGATION', {
              v0: irrigationOverrideUom,
              v1: this.fluidDepthNormalUnit,
              v2: irrigationSteppedUom,
              v3: this.fluidDepthNormalUnit,
            });

            const runtimeOverrideStr = ConversionUtils.convertToHHMM(irrigationRuntime);
            const runtimeSteppedStr = ConversionUtils.convertToHHMM(siteInfo.irrigation_duration);

            siteInfo.warningRuntime = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.WARNING_RUNTIME', {
              v0: runtimeOverrideStr,
              v1: runtimeSteppedStr,
            });
          } else {
            siteInfo.warningIrrigation = null;
            siteInfo.warningPercent = null;
            siteInfo.warningRuntime = null;
          }

          if (planInfo.irrigationPolicy == 'Must Not') {
            if (siteInfo.irrigationLocked && siteInfo.irrigation_mm != 0) {
              siteInfo.contradiction = this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.CONTRADICTION_LOCKED');
            } else {
              siteInfo.contradiction = null;
            }
          } else {
            siteInfo.contradiction = null;
          }
        });

        planInfo.totalVolume = planInfo.siteIrrigationInfos.reduce((sum, siteInfo) => sum + siteInfo.irrigationVolume, 0);

        this.checkPlanInfo(planInfo);
        this.planPredictSoilMoisture(this.planId, this.adjustedTodayDayNumber);
      }

      if (setting.irrigationLockStatus == true) {
        planInfo.siteIrrigationInfos.forEach((site) => {
          site.irrigationLocked = true;
        });
      }

      if (setting.irrigationLockStatus == false) {
        planInfo.siteIrrigationInfos.forEach((site) => {
          site.irrigationLocked = false;
        });
      }

      if (setting.isFertigateFixed) {
        planInfo.siteIrrigationInfos.forEach((siteInfo) => {
          const site = this.accountSites.find((a) => a.siteId == siteInfo.siteId);
          let fertigationApplication: number;

          if (setting.fertigationApplication != null) {
            fertigationApplication = setting.fertigationApplication;
          } else if (setting.fertigationRuntime != null) {
            fertigationApplication = siteInfo.siteIrrigationRate * setting.fertigationRuntime;
          }

          let steppedFertigation: number;

          if (fertigationApplication == 0) {
            steppedFertigation = 0;
          } else if (fertigationApplication <= site.siteApplicationMinimum) {
            steppedFertigation = site.siteApplicationMinimum;
          } else {
            if (site.siteApplicationIncrement > 0) {
              let i = 0;

              for (; ; i++) {
                if (site.siteApplicationMinimum + i * site.siteApplicationIncrement > site.siteApplicationMaximum) {
                  i--;

                  break;
                }

                if (fertigationApplication > site.siteApplicationMinimum + (i + 0.5) * site.siteApplicationIncrement) {
                  continue;
                }

                break;
              }

              steppedFertigation = site.siteApplicationMinimum + i * site.siteApplicationIncrement;
            }
          }

          const validIndex = site.validMillimeterValues.findIndex((a) => a == Math.round(steppedFertigation * 10000) / 10000);

          siteInfo.fertigation_mm = site.validMillimeterValues[validIndex];
          siteInfo.fertigation_duration = site.validTimeValues[validIndex];
        });
      }

      if (setting.isFertigateEntire) {
        planInfo.siteIrrigationInfos.forEach((siteInfo) => {
          siteInfo.fertigation_mm = -1;
          siteInfo.fertigation_duration = -1;
        });
      }

      this.checkSiteCards();
    });
  }

  public sitePredictSoilMoisture(siteId: number, dayNumber: number) {
    //prediction doesn't change breeze entity
    this.isSpinning = true;

    const params = {
      accountId: this.accountId,
      siteId: siteId,
      dayNumber: dayNumber,
      irrigationPlanInfos: this.planIrrigationInfos,
    } as fuse.sitePredictSoilMoistureDto;

    this._http.post(CommonHelper.getApiUrl('user/sitePredictSoilMoisture'), JSON.stringify(params)).then((response) => {
      this.isSpinning = false;

      if (response.data == null) {
        this._languageService.whoops();
      } else {
        const result = response.data as fuse.soilMoistureModel;

        if (result.succeeded) {
          this.planIsChanged = true;
          this._dataEntityService.hasDirtyCustomForm = true;

          result.data.forEach((soilPredictionValue: fuse.soilMoisturePrediction) => {
            const planInfo = this.planIrrigationInfos.find((a) => a.dayNumber == soilPredictionValue.dayNumber);
            const siteInfo = planInfo.siteIrrigationInfos.find((a) => a.siteId == siteId);

            siteInfo.soilMoisture = soilPredictionValue.soilMoisture;
            siteInfo.irrigation_mm = soilPredictionValue.irrigationPlanned;

            const irriSite = this.accountSites.find((a) => a.siteId == siteInfo.siteId);
            const validIndex = irriSite.validMillimeterValues.findIndex((a) => a == siteInfo.irrigation_mm);

            siteInfo.irrigation_duration = irriSite.validTimeValues[validIndex];
            siteInfo.irrigation_percent = irriSite.validPercentValues[validIndex];
            siteInfo.irrigationDb = siteInfo.irrigation_mm;
            siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;
          });
        } else {
          this._languageService.error(result.message);
        }
      }
    })
    .catch((reason) => {
      this.isSpinning = false;
    });
  }

  public planPredictSoilMoisture(planId: number, dayNumber: number) {
    //prediction doesn't change breeze entity
    this.isSpinning = true;

    const params = {
      accountId: this.accountId,
      planId: planId,
      dayNumber: dayNumber,
      irrigationPlanInfos: this.planIrrigationInfos,
    } as fuse.planPredictSoilMoistureDto;

    this._http.post(CommonHelper.getApiUrl('user/planPredictSoilMoisture'), JSON.stringify(params)).then((response) => {
      this.isSpinning = false;

      if (response.data == null) {
        this._languageService.whoops();
      } else {
        const result = response.data as fuse.soilMoistureModel;

        if (result.succeeded) {
          this.planIsChanged = true;
          this._dataEntityService.hasDirtyCustomForm = true;

          result.data.forEach((soilPredictionValue: fuse.soilMoisturePrediction) => {
            const planInfo = this.planIrrigationInfos.find((a) => a.dayNumber == soilPredictionValue.dayNumber);
            const siteInfo = planInfo.siteIrrigationInfos.find((a) => a.siteId == soilPredictionValue.siteId);

            siteInfo.soilMoisture = soilPredictionValue.soilMoisture;
            siteInfo.irrigation_mm = soilPredictionValue.irrigationPlanned;

            const irriSite = this.accountSites.find((a) => a.siteId == siteInfo.siteId);
            const validIndex = irriSite.validMillimeterValues.findIndex((a) => a == siteInfo.irrigation_mm);

            siteInfo.irrigation_duration = irriSite.validTimeValues[validIndex];
            siteInfo.irrigation_percent = irriSite.validPercentValues[validIndex];
            siteInfo.irrigationDb = siteInfo.irrigation_mm;
            siteInfo.irrigationVolume = siteInfo.irrigationDb * siteInfo.siteIrrigationArea * 10;
          });
        } else {
          this._languageService.error(result.message);
        }
      }
    })
    .catch(() => {
      this.isSpinning = false;
    });
  }

  public publishIrrigationPlan(showConfirmation = true) {
    let isValid = true;

    this.planIrrigationInfos.filter((a) => a.dayNumber >= this.adjustedTodayDayNumber).forEach((planDayInfo) => {
      if (planDayInfo.siteIrrigationInfos.some((a) => a.isValid == false)) {
        isValid = false;
      }
    });

    if (!isValid) {
      const alert = this._languageService
        .alert()
        .title('COMMON.ERROR')
        .htmlContent('WATER.IRRIGATION.WARNING.FERT_EXCEEDS_IRRIGATION')
        .parent(angular.element(document.body))
        .ok('COMMON.CANCEL');

      this._languageService.show(alert);

      return;
    }

    const publishPlan = async () => {
      this.isSpinning = true;

      const params = {
        accountId: this.accountId,
        planId: this.planId,
        fromDayNumber: this._dayNumberService.convertLocaleDateToLocaleDayNumber(new Date()),
        irrigationPlanInfos: this.planIrrigationInfos,
      } as fuse.irrigationPlanPublishDto;

      return this._http.post(CommonHelper.getApiUrl('user/irrigationplanpublish'), JSON.stringify(params)).then((response) => {
        this.isSpinning = false;

        if (!response.data) {
          this._languageService.whoops();
        } else {
          const result = response.data as fuse.irrigationPlanPublishDto;

          if (result.succeeded) {
            this.planIsChanged = false;
            this._dataEntityService.hasDirtyCustomForm = false;
            this.planLastPublishedDate = moment(result.publishedDate).toDate();
            this._languageService.showSaveSuccess();
          } else {
            this._languageService.error(result.message);
          }
        }
      })
      .catch((reason) => {
        this.isSpinning = false;
        this._languageService.whoops();

        console.log(reason);
      });
    };

    if (!showConfirmation) {
      return publishPlan();
    } else {
      const confirm = this._languageService
        .confirm()
        .title('COMMON.CONFIRM')
        .htmlContent('WATER.IRRIGATION.CONFIRM.SAVE')
        .parent(angular.element(document.body))
        .ok('COMMON.SAVE')
        .cancel('COMMON.CANCEL');

      return this._languageService.show(confirm).then(() => {
        publishPlan();
      });
    }
  }

  public resetIrrigationPlan() {
    if (this.planIsChanged) {
      const confirm = this._languageService.closeDialog();

      this._mdDialog.show(confirm).then(() => {
        this.getPlanSiteDailySummaries(this.planId, this.irriFromDayNumber, this.irriEndDayNumber);
      });
    }
  }

  private createIrrigationPlanInfos(dailySummaries: fuse.siteIrrigationInfoDto[]) {
    dailySummaries.forEach((dailySummary: fuse.siteIrrigationInfoDto) => {
      let siteSetting = this.sitesSettings.find((a) => a.assetId == dailySummary.siteId && a.dayNumber <= dailySummary.dayNumber);

      if (siteSetting == null) {
        siteSetting = this.sitesSettings
          .slice()
          .reverse()
          .find((a) => a.assetId == dailySummary.siteId && a.dayNumber > dailySummary.dayNumber);
      }

      if (siteSetting != null) {
        const localeDate = this._dayNumberService.convertDayNumberToLocaleDate(dailySummary.dayNumber);
        const planInfo = this.planInfo;
        const planOverride = this.thePlanOverrides.find(
          (a) => a.dayNumber <= dailySummary.dayNumber && a.endDayNumber >= dailySummary.dayNumber,
        );
        let planIrrigationInfo = this.planIrrigationInfos.find((a) => a.dayNumber == dailySummary.dayNumber);

        if (planIrrigationInfo == null) {
          planIrrigationInfo = {} as fuse.planIrrigationInfo;
          planIrrigationInfo.localeDate = localeDate;
          planIrrigationInfo.dayNumber = dailySummary.dayNumber;
          planIrrigationInfo.maximumVolume = planOverride == null ? planInfo.MaximumVolume : planOverride.maximumVolume;
          planIrrigationInfo.minimumVolume = planOverride == null ? planInfo.MinimumVolume : planOverride.minimumVolume;
          planIrrigationInfo.irrigationPolicy = planOverride == null ? WaterConstants.irrigationNormalPolicy : planOverride.irrigationPolicy;
          planIrrigationInfo.ignoreScheduledDays = planOverride == null ? null : !planOverride.observeScheduleDays;
          planIrrigationInfo.siteIrrigationInfos = [] as fuse.siteIrrigationInfoDto[];

          this.planIrrigationInfos.push(planIrrigationInfo);
        } else {
          planIrrigationInfo.localeDate = localeDate;
        }

        const siteIrrigationInfo = {} as fuse.siteIrrigationInfoDto;

        siteIrrigationInfo.siteId = siteSetting.assetId;
        siteIrrigationInfo.siteName = siteSetting.assetName;
        siteIrrigationInfo.siteIrrigationArea = siteSetting.siteIrrigationArea;
        siteIrrigationInfo.siteIrrigationRate = siteSetting.siteIrrigationRate;
        siteIrrigationInfo.soilMoistureLower = siteSetting.soilMoistureLower;
        siteIrrigationInfo.soilMoistureUpper = siteSetting.soilMoistureUpper;
        siteIrrigationInfo.dayNumber = dailySummary.dayNumber;
        siteIrrigationInfo.localeDate = localeDate;
        siteIrrigationInfo.soilMoistureDb = dailySummary.soilMoistureDb;
        siteIrrigationInfo.irrigationDb = dailySummary.irrigationDb;
        siteIrrigationInfo.irrigation_mm = Math.round(dailySummary.irrigation_mm * 10000) / 10000;
        siteIrrigationInfo.soilMoisture = dailySummary.soilMoisture;
        siteIrrigationInfo.fertigation_mm = Math.round(dailySummary.fertigation_mm * 10000) / 10000;

        if (dailySummary.dayNumber >= this.adjustedTodayDayNumber) {
          //plan
          const irriSite = this.accountSites.find((a) => a.siteId == siteIrrigationInfo.siteId);
          const validIndex = irriSite.validMillimeterValues.findIndex((a) => a == siteIrrigationInfo.irrigation_mm);

          siteIrrigationInfo.irrigation_duration = validIndex == -1 ? null : irriSite.validTimeValues[validIndex];
          siteIrrigationInfo.irrigation_percent = validIndex == -1 ? null : irriSite.validPercentValues[validIndex];
          siteIrrigationInfo.irrigationVolume = siteIrrigationInfo.irrigation_mm * siteSetting.siteIrrigationArea * 10;

          if (siteIrrigationInfo.fertigation_mm == -1) {
            siteIrrigationInfo.fertigation_duration = -1;
          } else {
            const fertigationIndex = irriSite.validMillimeterValues.findIndex((a) => a == siteIrrigationInfo.fertigation_mm);
            siteIrrigationInfo.fertigation_duration = fertigationIndex == -1 ? null : irriSite.validTimeValues[fertigationIndex];
          }
        } else {
          //applied
          siteIrrigationInfo.irrigation_duration = dailySummary.irrigationDb / siteSetting.siteIrrigationRate;
          siteIrrigationInfo.irrigation_percent = dailySummary.irrigationDb / siteSetting.application100Percent;
          siteIrrigationInfo.irrigationVolume = dailySummary.irrigationDb * siteSetting.siteIrrigationArea * 10;
        }

        siteIrrigationInfo.irrigationLocked = dailySummary.irrigationLocked;
        siteIrrigationInfo.isScheduled = dailySummary.isScheduled;
        //used for backend
        siteIrrigationInfo.application100Percent = siteSetting.application100Percent;
        siteIrrigationInfo.applicationIncrement = siteSetting.applicationIncrement;
        siteIrrigationInfo.maximumApplication = siteSetting.maximumApplication;
        siteIrrigationInfo.minimumApplication = siteSetting.minimumApplication;

        siteIrrigationInfo.rainfallObs = dailySummary.rainfallObs;
        siteIrrigationInfo.soilSaturationPoint = siteSetting.soilSaturationPoint;

        if (planIrrigationInfo.irrigationPolicy == 'Must Not') {
          if (siteIrrigationInfo.irrigationLocked && siteIrrigationInfo.irrigation_mm != 0) {
            siteIrrigationInfo.contradiction = this._languageService.instant(
              'WATER.IRRIGATION.PLANNING_TAB.CONTRADICTION_LOCKED',
            );
          } else {
            siteIrrigationInfo.contradiction = null;
          }
        } else {
          siteIrrigationInfo.contradiction = null;
        }

        planIrrigationInfo.siteIrrigationInfos.push(siteIrrigationInfo);
      }
    });

    this.planIrrigationInfos.forEach((planIrrigationInfo: fuse.planIrrigationInfo) => {
      if (planIrrigationInfo.siteIrrigationInfos.some((a) => a.irrigationDb == null)) {
        planIrrigationInfo.totalVolume = null;
      } else {
        planIrrigationInfo.totalVolume = planIrrigationInfo.siteIrrigationInfos.reduce(
          (sum, siteInfo) => sum + siteInfo.irrigationVolume,
          0,
        );

        this.checkPlanInfo(planIrrigationInfo);
      }
    });
  }

  public siteGraph(siteInfo: fuse.irrigationPlanSite) {
    const siteIrrigationInfos = [] as fuse.siteIrrigationInfoDto[];

    this.planIrrigationInfos.forEach((planIrrigationInfo: fuse.planIrrigationInfo) => {
      let siteIrrigationInfo = planIrrigationInfo.siteIrrigationInfos.find((a) => a.siteId == siteInfo.siteId);

      if (siteIrrigationInfo == null) {
        siteIrrigationInfo = {} as fuse.siteIrrigationInfoDto;
        siteIrrigationInfo.siteId = siteInfo.siteId;
        siteIrrigationInfo.siteName = siteInfo.siteName;
        siteIrrigationInfo.localeDate = planIrrigationInfo.localeDate;
        siteIrrigationInfo.dayNumber = planIrrigationInfo.dayNumber;
      }

      siteIrrigationInfos.push(siteIrrigationInfo);
    });

    this._mdDialog.show({
      controller: SiteGraphDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-plan/siteGraph-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        siteInfo: siteInfo,
        siteIrrigationInfos: siteIrrigationInfos,
        forecastRainUsePct: this.accountDetail.forecastRainUsePct,
      },
    });
  }

  private viewIrrigationSetting() {
    this.primaryTab = IrrigationPrimaryTabEnums.IrrigationSetting;
  }

  private viewIrrigationSites() {
    this.primaryTab = IrrigationPrimaryTabEnums.IrrigationSites;
  }

  public policyTextColor(policy: string): string {
    if (policy == WaterConstants.irrigationNormalPolicy) {
      return null;
    }

    if (policy == WaterConstants.irrigationMustPolicy) {
      return 'blue';
    }

    if (policy == WaterConstants.irrigationMustNotPolicy) {
      return 'orange';
    }

    return null;
  }

  public totalVolumeBackgroundColor(planInfo: fuse.planIrrigationInfo): string {
    if (planInfo.totalVolume != 0) {
      if (planInfo.irrigationPolicy == WaterConstants.irrigationMustNotPolicy) {
        return '#0084CA'; //blue
      } else {
        if (planInfo.totalVolume < planInfo.minimumVolume) {
          return 'orange';
        } else if (planInfo.totalVolume <= planInfo.maximumVolume) {
          return '#3FA441'; //green
        } else {
          return '#0084CA'; //blue
        }
      }
    } else {
      if (
        planInfo.irrigationPolicy == WaterConstants.irrigationMustNotPolicy ||
        planInfo.irrigationPolicy == WaterConstants.irrigationNormalPolicy
      ) {
        return '#3FA441'; //green
      } else {
        return 'orange';
      }
    }
  }

  private checkPlanInfo(planInfo: fuse.planIrrigationInfo) {
    if (planInfo.irrigationPolicy == WaterConstants.irrigationMustNotPolicy) {
      if (planInfo.totalVolume > 0) {
        planInfo.errorMessage = this._languageService.instant('WATER.IRRIGATION.ERROR.IRRIGATION_BUT_MUST_NOT');
      } else {
        planInfo.errorMessage = null;
      }
    } else if (planInfo.irrigationPolicy == WaterConstants.irrigationMustPolicy) {
      if (planInfo.totalVolume == 0) {
        planInfo.errorMessage = this._languageService.instant('WATER.IRRIGATION.ERROR.MUST_BUT_NO_IRRIGATION');
      } else if (planInfo.maximumVolume != null && planInfo.totalVolume > planInfo.maximumVolume) {
        planInfo.errorMessage = this._languageService.instant('WATER.IRRIGATION.ERROR.VOLUME_EXCEEDS_MAX', {
          volume: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, planInfo.maximumVolume),
          uom: this.volumeNormalUnit,
        });
      } else if (planInfo.totalVolume < planInfo.minimumVolume) {
        planInfo.errorMessage = this._languageService.instant('WATER.IRRIGATION.ERROR.VOLUME_BELOW_MIN', {
          volume: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, planInfo.minimumVolume),
          uom: this.volumeNormalUnit,
        });
      } else {
        planInfo.errorMessage = null;
      }
    } else if (planInfo.irrigationPolicy == WaterConstants.irrigationNormalPolicy) {
      if (planInfo.maximumVolume != null && planInfo.totalVolume > planInfo.maximumVolume) {
        planInfo.errorMessage = this._languageService.instant('WATER.IRRIGATION.ERROR.VOLUME_EXCEEDS_MAX', {
          volume: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, planInfo.maximumVolume),
          uom: this.volumeNormalUnit,
        });
      } else if (planInfo.totalVolume != 0 && planInfo.totalVolume < planInfo.minimumVolume) {
        planInfo.errorMessage = this._languageService.instant('WATER.IRRIGATION.ERROR.VOLUME_BELOW_MIN', {
          volume: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, planInfo.minimumVolume),
          uom: this.volumeNormalUnit,
        });
      } else {
        planInfo.errorMessage = null;
      }
    }
  }

  public openIrrigationHelpDialog(keyword: string) {
    this._mdDialog.show({
      controller: IrrigationHelpDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-plan/irrigationHelp-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        keyword: keyword,
      },
    });
  }

  public isAnySiteSelected(): boolean {
    if (this.accountSites.some((a) => a.isInPlan && a.isSelected)) {
      return true;
    }

    return false;
  }

  public openUpdateMultiSitesDialog() {
    this._mdDialog.show({
      controller: IrrigationUpdateMultiSitesDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-plan/irrigationUpdateMultiSites-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        applicationType: this.applicationType,
      },
    }).then((res) => {
      if (!res) {
        return;
      }

        const irrigationSiteSettings = res.irrigationSiteSettings as IIdNameItem[];
        const selectedSites = this.accountSites.filter((a) => a.isInPlan && a.isSelected);

        irrigationSiteSettings.forEach((setting) => {
          selectedSites.forEach((site) => {
            if (setting.name == 'pattern') {
              site.sitePattern = setting.value as string;
            }

            if (setting.name == 'smbPreference') {
              site.siteSmbPreference = setting.value as string;
            }

            if (setting.name == 'priority') {
              site.sitePriority = setting.value as number;
            }

            if (this.applicationType == 0) {
              if (setting.name == 'applicationMinimum') {
                site.siteApplicationMinimum = setting.value as number;
                site.siteApplicationMinimumRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationMinimum,site.irrigationRate);
              }

              if (setting.name == 'applicationIncrement') {
                site.siteApplicationIncrement = setting.value as number;
                site.siteApplicationIncrementRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationIncrement, site.irrigationRate);
              }

              if (setting.name == 'application100Percent') {
                site.siteApplication100Pct = setting.value as number;
                site.siteApplication100PctRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplication100Pct, site.irrigationRate);
              }

              if (setting.name == 'applicationMaximum') {
                site.siteApplicationMaximum = setting.value as number;
                site.siteApplicationMaximumRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationMaximum, site.irrigationRate);
              }
            } else {
              if (setting.name == 'applicationMinimum') {
                site.siteApplicationMinimumRuntime = setting.value as string;
                site.siteApplicationMinimum = this.convertToMillimeter(site.siteApplicationMinimumRuntime, site.irrigationRate);
              }

              if (setting.name == 'applicationIncrement') {
                site.siteApplicationIncrementRuntime = setting.value as string;
                site.siteApplicationIncrement = this.convertToMillimeter(site.siteApplicationIncrementRuntime, site.irrigationRate);
              }

              if (setting.name == 'application100Percent') {
                site.siteApplication100PctRuntime = setting.value as string;
                site.siteApplication100Pct = this.convertToMillimeter(site.siteApplication100PctRuntime, site.irrigationRate);
              }

              if (setting.name == 'applicationMaximum') {
                site.siteApplicationMaximumRuntime = setting.value as string;
                site.siteApplicationMaximum = this.convertToMillimeter(site.siteApplicationMaximumRuntime, site.irrigationRate);
              }
            }
          });
        });

        const query = breeze.EntityQuery.from('Site');
        const accountSiteEntities = this.entityManager.executeQueryLocally(query) as Site[]; // query the cache (synchronous)

        selectedSites.forEach((site) => {
          const siteEntity = accountSiteEntities.find((a) => a.AssetId == site.siteId);

          siteEntity.Application100Pct = site.siteApplication100Pct;
          siteEntity.ApplicationIncrements = site.siteApplicationIncrement;
          siteEntity.ApplicationMaximum = site.siteApplicationMaximum;
          siteEntity.ApplicationMinimum = site.siteApplicationMinimum;
          siteEntity.IrrigationPattern = site.sitePattern;
          siteEntity.Priority = site.sitePriority;
          siteEntity.SmbPreference = site.siteSmbPreference;
        });

        setTimeout(() => {
          this.setControlsDirty();
        }, 100);
      });
  }

  private calculatePlanMaximumVolume() {
    this.plan100PercentVolume = 0;
    this.planMaximumVolume = 0;
    this.planTheoreticalVolume = 0;

    if (this.accountSites != null) {
      this.accountSites.filter((a) => a.isInPlan).forEach((site) => {
        let siteSetting = this.sitesSettings.find(
          (a) => a.assetId == site.siteId && a.dayNumber <= this.adjustedTodayDayNumber,
        );

        if (siteSetting == null) {
          siteSetting = this.sitesSettings
            .slice()
            .reverse()
            .find((a) => a.assetId == site.siteId && a.dayNumber > this.adjustedTodayDayNumber);
        }

        if (siteSetting.application100Percent != null) {
          this.plan100PercentVolume += siteSetting.application100Percent * siteSetting.siteIrrigationArea * 10;
        }

        if (siteSetting.maximumApplication != null) {
          this.planMaximumVolume += siteSetting.maximumApplication * siteSetting.siteIrrigationArea * 10;
        }

        this.planTheoreticalVolume += siteSetting.siteIrrigationRate * 24 * siteSetting.siteIrrigationArea * 10;
      });

      this.plan100PercentVolume = Math.round(this.plan100PercentVolume);
      this.planMaximumVolume = Math.round(this.planMaximumVolume);
      this.planTheoreticalVolume = Math.round(this.planTheoreticalVolume);
    }
  }

  public selectAllCheckedSites() {
    const planSites = this.accountSites.filter((a) => a.isInPlan) as fuse.irrigationPlanSite[];

    if (this.isSelectAllCheckedSites) {
      planSites.forEach((site) => {
        site.isSelected = true;
      });
    } else {
      planSites.forEach((site) => {
        site.isSelected = false;
      });
    }
  }

  public selectCheckedSiteChanged() {
    const planSites = this.accountSites.filter((a) => a.isInPlan) as fuse.irrigationPlanSite[];

    if (planSites.some((a) => a.isSelected == false)) {
      this.isSelectAllCheckedSites = false;
    } else {
      this.isSelectAllCheckedSites = true;
    }
  }

  private daySum(planInfos: fuse.planIrrigationInfo[], dayNumber: number): number {
    if (planInfos == null) {
      return null;
    }

    return planInfos.filter((a) => a.dayNumber == dayNumber).reduce((sum, item) => sum + item.totalVolume, 0);
  }

  private weeklySum(planInfos: fuse.planIrrigationInfo[], dayNumber: number): number {
    if (planInfos == null) {
      return null;
    }

    let weeklySum = 0;

    for (let i = 0; i < 7; i++) {
      const value = planInfos.filter((a) => a.dayNumber == dayNumber + i).reduce((sum, item) => sum + item.totalVolume, 0);
      weeklySum += value;
    }

    if (Number.isNaN(weeklySum)) {
      return null;
    } else {
      return weeklySum;
    }
  }

  private weeklyMillimeterSum(planInfos: fuse.planIrrigationInfo[], siteId: number, dayNumber: number): number {
    if (planInfos == null) {
      return null;
    }

    let weeklySum = 0;

    for (let i = 0; i < 7; i++) {
      const planInfo = planInfos.find((a) => a.dayNumber == dayNumber + i);

      if (planInfo != null) {
        const siteIrrigationInfos = planInfo.siteIrrigationInfos;
        const value = siteIrrigationInfos.find((a) => a.siteId == siteId && a.dayNumber == dayNumber + i)?.irrigation_mm;

        weeklySum += value;
      }
    }

    if (Number.isNaN(weeklySum)) {
      return null;
    } else {
      return weeklySum;
    }
  }

  private weeklyRuntimeRound(planInfos: fuse.planIrrigationInfo[], siteId: number, dayNumber: number): string {
    if (planInfos == null) {
      return null;
    }

    let weeklySum = 0;

    for (let i = 0; i < 7; i++) {
      const planInfo = planInfos.find((a) => a.dayNumber == dayNumber + i);

      if (planInfo != null) {
        const siteIrrigationInfos = planInfo.siteIrrigationInfos;
        const value = siteIrrigationInfos.find((a) => a.siteId == siteId && a.dayNumber == dayNumber + i)?.irrigation_duration;

        weeklySum += value;
      }
    }

    return ConversionUtils.convertToHHMM(weeklySum);
  }

  private weeklyVolumeSum(planInfos: fuse.planIrrigationInfo[], siteId: number, dayNumber: number): number {
    if (planInfos == null) {
      return null;
    }

    let weeklySum = 0;

    for (let i = 0; i < 7; i++) {
      const planInfo = planInfos.find((a) => a.dayNumber == dayNumber + i);

      if (planInfo != null) {
        const siteIrrigationInfos = planInfo.siteIrrigationInfos;
        const value = siteIrrigationInfos.find((a) => a.siteId == siteId && a.dayNumber == dayNumber + i)?.irrigationVolume;

        weeklySum += value;
      }
    }

    if (Number.isNaN(weeklySum)) {
      return null;
    } else {
      return weeklySum;
    }
  }

  private splitString(input: string, length: number): string[] {
    const outArray = [];

    if (input == null || input.length < length) {
      outArray.push(input);

      return outArray;
    }

    const chunks = input.split(' ');

    for (let i = 0; i < chunks.length; i++) {
      if (chunks[i].length > length) {
        const firstPart = chunks[i].substring(0, length);
        const remainPart = chunks[i].substring(length);

        chunks.splice(i, 1);
        chunks.splice(i, 0, firstPart);
        chunks.splice(i + 1, 0, remainPart);
      }
    }

    let combineStr = '';

    for (let i = 0; i < chunks.length; i++) {
      if (combineStr.length + chunks[i].length <= length) {
        combineStr += chunks[i] + ' ';
      } else {
        outArray.push(combineStr);
        i--;
        combineStr = '';
      }
    }

    outArray.push(combineStr);

    return outArray;
  }

  private convertToMillimeter(runtimeStr: string, irrigationRate: number): number | null {
    if (!irrigationRate || !runtimeStr) {
      return null;
    }

    const hhmm = runtimeStr.split(':');

    if (hhmm.length != 2) {
      return null;
    }

    const hrs = parseFloat(hhmm[0]);
    const mins = parseFloat(hhmm[1]);

    if (isNaN(hrs) || isNaN(mins)) {
      return null;
    }

    const runtime = hrs + (mins / 60);

    return runtime * irrigationRate;
  }

  public openPrintIrrigationPlanDialog() {
    this._mdDialog.show({
      controller: PrintIrrigationPlanDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/irrigation-plan/print-irrigation-plan-dialog.html',
      clickOutsideToClose: false,
    }).then((response) => {
      if (response && response.print) {
        this.isSpinning = true;

        this._timeout(() => {
          this.createIrrigationReport();
          this.isSpinning = false;
        });
      }
    });
  }

  public createIrrigationReport() {
    const irrigationReportSetting = this._localStorageService.get('irrigationReportSetting') as IIrrigationReportSetting;

    let siteLines = 0;
    const siteReportSettings = [] as IIrrigationReportRowSetting[];

    siteReportSettings.push({
      id: siteLines++,
      baseName: WaterConstants.irrigationReport_soilMoisture,
      name: WaterConstants.irrigationReport_soilMoisture,
    });

    if (irrigationReportSetting.irrigationMillimeter) {
      siteReportSettings.push({
        id: siteLines++,
        baseName: WaterConstants.irrigationReport_irrigation,
        name: `${WaterConstants.irrigationReport_irrigation}, (${this.fluidDepthNormalUnit})`,
      });
    }

    if (irrigationReportSetting.irrigationRuntime) {
      siteReportSettings.push({
        id: siteLines++,
        baseName: WaterConstants.irrigationReport_runtime,
        name: WaterConstants.irrigationReport_runtime,
      });
    }

    if (irrigationReportSetting.irrigationVolume) {
      siteReportSettings.push({
        id: siteLines++,
        baseName: WaterConstants.irrigationReport_volume,
        name: `${WaterConstants.irrigationReport_volume}, (${this.volumeNormalUnit})`,
      });
    }

    if (irrigationReportSetting.irrigationProportion) {
      siteReportSettings.push({
        id: siteLines++,
        baseName: WaterConstants.irrigationReport_proportion,
        name: WaterConstants.irrigationReport_proportion,
      });
    }

    const docDefinition = {
      info: { title: this._languageService.normInstant('COMMON.IRRIGATION_REPORT') },
      pageMargins: WaterConstants.irrigationReport_oneLineMargin,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      header: () => {
        return {
          alignment: 'justify',
          columns: [
            {
              text: this.accountDetail.companyName,
              style: 'page_header',
              width: 360,
            },
            {
              text: this._languageService.normInstant('COMMON.IRRIGATION_SCHEDULE'),
              style: 'page_header',
            },
            {
              image: this.reportLogo,
              width: (this.logoWidth * 30) / this.logoHeight,
              height: 30,
            },
          ],
          margin: [40, 20],
        };
      },
      footer: (currentPage: number, pageCount: number) => {
        return {
          margin: [40, 40],
          columns: [
            {
              text: `${this._languageService.normInstant('COMMON.PRINTED')} ${new Date().toString('HH:mm dd-MMM-yyyy')}`,
              alignment: 'left',
            },
            {
              text: `${currentPage.toString()} ${this._languageService.normInstant('COMMON.OF')} ${pageCount}`,
              alignment: 'right',
            },
          ],
        };
      },
      content: [
        {
          table: {
            pageBreak: 'after',
            dontBreakRows: true,
            headerRows: 3,
            widths: [80, 100, '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
            body: [],
          },
          layout: {
            hLineWidth: (i, node) => {
              if ((i - 3) % siteLines === 0 || i === node.table.body.length) return 2;
              else return 1;
            },
            vLineWidth: (i, node) => {
              return i === 5 ? 1 : i > 2 ? 1 : 1;
            },
            hLineColor: (i, node) => {
              return i === 2 || i === node.table.body.length ? 'black' : 'gray';
            },
            vLineColor: (i, node) => {
              return i === 5 || i === 12 ? 'black' : 'gray';
            },
          },
        },
      ],
      styles: {
        page_header: {
          fontSize: 18,
          bold: true,
        },
        page_header_small: {
          fontSize: 12,
        },
        header: {
          fontSize: 9,
          bold: true,
        },
        header_underline: {
          fontSize: 9,
          decoration: 'underline',
          bold: true,
          alignment: 'center',
        },
        subheader: {
          fontSize: 9,
          bold: true,
        },
        subheader_center: {
          fontSize: 9,
          bold: true,
          alignment: 'center',
        },
        cell: {
          alignment: 'center',
          fontSize: 9,
        },
        boldcell: {
          alignment: 'center',
          fontSize: 9,
          bold: true,
        },
        greyCell: {
          alignment: 'center',
          fontSize: 9,
          color: 'grey',
        },
        leftcell: {
          fontSize: 9,
        },
        rightcell: {
          fontSize: 9,
          alignment: 'right',
        },
      },
    };

    const tableBody = [];

    //#region table header
    const tableHeaderLine1 = [];

    tableHeaderLine1.push(
      {
        border: [false, false, false, false],
        colSpan: 2,
        text: [
          {
            fontSize: 12,
            text: `${this._languageService.normInstant('COMMON.PLAN')}: `,
          },
          {
            bold: true,
            fontSize: 12,
            text: StringUtils.truncate(this.planInfo.Asset.Name, 30),
          },
        ],
      },
      {},
    );

    tableHeaderLine1.push(
      {
        border: [false, false, true, false],
        colSpan: 3,
        style: 'header_underline',
        text: this._languageService.normInstant('COMMON.IRRIGATION_ACTUAL'),
      },
      {},
      {},
    );

    tableHeaderLine1.push(
      {
        border: [false, false, true, false],
        colSpan: 7,
        style: 'header_underline',
        text: this._languageService.instant('WATER.IRRIGATION.PLANNING_TAB.IRRIGATION_SCHEDULED_DATES', {
          fromDate: DateUtils.Locale.asDateDefault(this._dayNumberService.convertDayNumberToDate(this.irriFromDayNumber + 3)),
          endDate: DateUtils.Locale.asDateDefault(this._dayNumberService.convertDayNumberToDate(this.irriEndDayNumber - 1)),
        }),
      },
      {},
      {},
      {},
      {},
      {},
      {},
    );

    tableHeaderLine1.push({
      border: [false, false, false, false],
      text: '',
    });

    tableBody.push(tableHeaderLine1);

    const tableHeaderLine2 = [];

    tableHeaderLine2.push({
      border: [false, false, false, false],
      text: '',
    });

    tableHeaderLine2.push({
      border: [false, false, false, false],
      text: '',
    });

    for (let i = 0; i < 10; i++) {
      tableHeaderLine2.push({
        border: [false, false, i == 2 || i == 9 ? true : false, false],
        style: 'cell',
        text: DateUtils.Locale.asDateDayWithWeekday(this._dayNumberService.convertDayNumberToDate(this.irriFromDayNumber + i)),
      });
    }

    tableHeaderLine2.push({
      border: [false, false, false, false],
      style: 'subheader_center',
      text: this._languageService.normInstant('COMMON.TOTAL'),
    });

    tableBody.push(tableHeaderLine2);

    const tableHeaderLine3 = [];

    tableHeaderLine3.push({
      border: [false, false, false, true],
      style: 'subheader',
      text: this._languageService.normInstant('COMMON.SITE'),
    });

    tableHeaderLine3.push({
      border: [false, false, false, true],
      style: 'subheader',
      text: `${this._languageService.normInstant('COMMON.IRRIGATION_DAILY_TOTAL')} (${this.volumeNormalUnit}):`,
    });

    for (let i = 0; i < 10; i++) {
      const value = this.daySum(this.planIrrigationInfos, this.irriFromDayNumber + i);
      const toUomValue = this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, value);

      tableHeaderLine3.push({
        border: [false, false, i == 2 || i == 9 ? true : false, true],
        style: 'cell',
        text: toUomValue,
      });
    }

    const weeklyValue = this.weeklySum(this.planIrrigationInfos, this.irriFromDayNumber + 3);
    const toWeeklyUomValue = this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, weeklyValue);

    tableHeaderLine3.push({ text: toWeeklyUomValue, style: 'boldcell', border: [false, false, false, true] });
    tableBody.push(tableHeaderLine3);
    //#endregion

    if (siteLines == 2) {
      docDefinition.pageMargins = WaterConstants.irrigationReport_twoLineMargin;
    } else if (siteLines == 3) {
      docDefinition.pageMargins = WaterConstants.irrigationReport_threeLineMargin;
    } else if (siteLines == 4) {
      docDefinition.pageMargins = WaterConstants.irrigationReport_fourLineMargin;
    } else if (siteLines == 5) {
      docDefinition.pageMargins = WaterConstants.irrigationReport_fiveLineMargin;
    }

    this.accountSites.filter((a) => a.isInPlan).forEach((site) => {
      let skipSite = false;

      if (irrigationReportSetting.printAllSites == false) {
        if (
          this.planIrrigationInfos.some(
            (a) =>
              a.dayNumber > this.irriFromDayNumber + 2 &&
              a.siteIrrigationInfos.some((a) => a.siteId == site.siteId && a.irrigation_mm > 0),
          ) == false
        ) {
          skipSite = true;
        }
      }

      if (skipSite == false) {
        const siteNamePart = this.splitString(site.siteName, 14);

        siteReportSettings.forEach((line) => {
          const siteLine = [];

          siteLine.push({
            bold: true,
            border: [false, false, false, line.id != siteLines - 1 ? false : true],
            style: 'leftcell',
            text: siteNamePart[line.id],
          });

          siteLine.push({
            border: [true, false, true, true],
            style: 'leftcell',
            text: this._languageService.normInstant('COMMON.' + line.name),
          });

          for (let i = 0; i < 10; i++) {
            let cellFillColor = '#dddddd';
            let cellStyle = 'greyCell';
            const cellBorder = [true, false, true, true];
            const planInfo = this.planIrrigationInfos.find((a) => a.dayNumber == this.irriFromDayNumber + i);

            if (planInfo != null) {
              const siteInfo = planInfo.siteIrrigationInfos.find((a) => a.siteId == site.siteId);

              if (siteInfo != null) {
                if (i > 2 && siteInfo.irrigation_mm != 0) {
                  cellFillColor = '#ffffff';
                  cellStyle = 'cell';
                }

                if (line.baseName == WaterConstants.irrigationReport_soilMoisture) {
                  const backColor =
                    siteInfo.soilMoisture < siteInfo.soilMoistureLower
                      ? '#FABD33'
                      : siteInfo.soilMoisture < siteInfo.soilMoistureUpper
                        ? '#3FA441'
                        : '#0084CA';

                  if (irrigationReportSetting.soilMoisture) {
                    if (siteInfo.soilMoisture != null) {
                      siteLine.push({
                        border: cellBorder,
                        fillColor: backColor,
                        style: 'cell',
                        text: `${NumberUtils.round(siteInfo.soilMoisture, 1)}%`,
                      });
                    } else {
                      siteLine.push({
                        border: cellBorder,
                        fillColor: cellFillColor,
                        style: 'cell',
                        text: '',
                      });
                    }
                  } else {
                    siteLine.push({
                      border: cellBorder,
                      fillColor: backColor,
                      style: 'cell',
                      text: '',
                    });
                  }
                } else if (line.baseName == WaterConstants.irrigationReport_irrigation) {
                  const value = siteInfo.irrigation_mm;
                  const toUomValue = this._unitOfMeasureService.convertFromBase('Fluid Depth', unitSizes.normal, value);

                  siteLine.push({
                    text: toUomValue,
                    border: cellBorder,
                    style: cellStyle,
                    fillColor: cellFillColor,
                  });
                } else if (line.baseName == WaterConstants.irrigationReport_runtime) {
                  siteLine.push({
                    border: cellBorder,
                    fillColor: cellFillColor,
                    style: cellStyle,
                    text: ConversionUtils.convertToHHMM(siteInfo.irrigation_duration),
                  });
                } else if (line.baseName == WaterConstants.irrigationReport_proportion) {
                  siteLine.push({
                    border: cellBorder,
                    fillColor: cellFillColor,
                    style: cellStyle,
                    text: `${NumberUtils.round(siteInfo.irrigation_percent * 100)}%`,
                  });
                } else if (line.baseName == WaterConstants.irrigationReport_volume) {
                  const value = siteInfo.irrigationVolume;
                  const toUomValue = this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, value);

                  siteLine.push({
                    border: cellBorder,
                    fillColor: cellFillColor,
                    style: cellStyle,
                    text: toUomValue,
                  });
                }
              } else {
                siteLine.push({
                  border: cellBorder,
                  fillColor: cellFillColor,
                  style: 'cell',
                  text: '',
                });
              }
            } else {
              siteLine.push({
                border: cellBorder,
                fillColor: cellFillColor,
                style: 'cell',
                text: '',
              });
            }
          }

          if (line.baseName == WaterConstants.irrigationReport_soilMoisture) {
            siteLine.push({
              border: [true, false, false, true],
              text: '',
            });
          } else if (line.baseName == WaterConstants.irrigationReport_irrigation) {
            const value = this.weeklyMillimeterSum(this.planIrrigationInfos, site.siteId, this.irriFromDayNumber + 3);
            const toUomValue = this._unitOfMeasureService.convertFromBase('Fluid Depth', unitSizes.normal, value);

            siteLine.push({
              border: [true, false, false, true],
              style: 'boldcell',
              text: toUomValue,
            });
          } else if (line.baseName == WaterConstants.irrigationReport_runtime) {
            siteLine.push({
              border: [true, false, false, true],
              style: 'boldcell',
              text: this.weeklyRuntimeRound(this.planIrrigationInfos, site.siteId, this.irriFromDayNumber + 3),
            });
          } else if (line.baseName == WaterConstants.irrigationReport_proportion) {
            siteLine.push({
              border: [true, false, false, true],
              text: '',
            });
          } else if (line.baseName == WaterConstants.irrigationReport_volume) {
            const value = this.weeklyVolumeSum(this.planIrrigationInfos, site.siteId, this.irriFromDayNumber + 3);
            const toUomValue = this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, value);

            siteLine.push({
              border: [true, false, false, true],
              style: 'boldcell',
              text: toUomValue,
            });
          }

          tableBody.push(siteLine);
        });
      }
    });

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    docDefinition.content[0].table.body = tableBody;

    const pdf = pdfMake.createPdf(docDefinition);

    pdf.download(`${this.accountDetail.companyName}_${this.planInfo.Asset.Name}.pdf`);
  }

  public irrigationUnitChanged(): void {
    if (this.irrigationUnitId != 2) {
      this.fertigationUnitId = this.irrigationUnitId;
    }

    const irrigationReportSetting = this._localStorageService.get('irrigationReportSetting') as IIrrigationReportSetting;

    irrigationReportSetting.displayUnitId = this.irrigationUnitId;

    this._localStorageService.set('irrigationReportSetting', irrigationReportSetting);
  }

  public changeApplicationType(): void {
    const irrigationReportSetting = this._localStorageService.get('irrigationReportSetting') as IIrrigationReportSetting;

    irrigationReportSetting.applicationType = this.applicationType;

    this._localStorageService.set('irrigationReportSetting', irrigationReportSetting);

    if (this.applicationType == 0) {
      this.accountSites.forEach((site) => {
        if (site.irrigationRate != null) {
          if (site.siteApplication100Pct != null) {
            site.siteApplication100PctRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplication100Pct, site.irrigationRate);
          } else {
            site.siteApplication100PctRuntime = null;
          }

          if (site.siteApplicationIncrement != null) {
            site.siteApplicationIncrementRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationIncrement, site.irrigationRate);
          } else {
            site.siteApplicationIncrementRuntime = null;
          }

          if (site.siteApplicationMaximum != null) {
            site.siteApplicationMaximumRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationMaximum, site.irrigationRate);
          } else {
            site.siteApplicationMaximumRuntime = null;
          }

          if (site.siteApplicationMinimum != null) {
            site.siteApplicationMinimumRuntime = WaterHelpers.getRuntimeForIrrigationRate(site.siteApplicationMinimum, site.irrigationRate);
          } else {
            site.siteApplicationMinimumRuntime = null;
          }
        } else {
          site.siteApplication100PctRuntime = null;
          site.siteApplicationIncrementRuntime = null;
          site.siteApplicationMaximumRuntime = null;
          site.siteApplicationMinimumRuntime = null;
        }
      });
    } else {
      this.accountSites.forEach((site) => {
        if (site.irrigationRate) {
          if (site.siteApplication100PctRuntime != null) {
            site.siteApplication100Pct = this.convertToMillimeter(site.siteApplication100PctRuntime, site.irrigationRate);
          } else {
            site.siteApplication100Pct = null;
          }

          if (site.siteApplicationIncrementRuntime != null) {
            site.siteApplicationIncrement = this.convertToMillimeter(site.siteApplicationIncrementRuntime, site.irrigationRate);
          } else {
            site.siteApplicationIncrement = null;
          }

          if (site.siteApplicationMaximumRuntime != null) {
            site.siteApplicationMaximum = this.convertToMillimeter(site.siteApplicationMaximumRuntime, site.irrigationRate);
          } else {
            site.siteApplicationMaximum = null;
          }

          if (site.siteApplicationMinimumRuntime != null) {
            site.siteApplicationMinimum = this.convertToMillimeter(site.siteApplicationMinimumRuntime, site.irrigationRate);
          } else {
            site.siteApplicationMinimum = null;
          }
        } else {
          site.siteApplication100Pct = null;
          site.siteApplicationIncrement = null;
          site.siteApplicationMaximum = null;
          site.siteApplicationMinimum = null;
        }
      });
    }

    this._timeout(() => this.setControlsDirty(), 100);
  }

  private checkSiteCards() {
    let isValid = true as boolean;

    this.planIrrigationInfos.filter((a) => a.dayNumber >= this.adjustedTodayDayNumber).forEach((planDayInfo) => {
      planDayInfo.siteIrrigationInfos.forEach((siteInfo) => {
        if (this.fertigationEnabled) {
          if (siteInfo.fertigation_mm != -1) {
            if (siteInfo.fertigation_mm > siteInfo.irrigation_mm) {
              siteInfo.isValid = false;
              isValid = false;
            } else {
              siteInfo.isValid = true;
            }
          } else {
            siteInfo.isValid = true;
          }
        } else {
          siteInfo.isValid = true;
        }
      });
    });

    if (isValid == false) {
      this._languageService.error('WATER.IRRIGATION.WARNING.FERT_EXCEEDS_IRRIGATION');
    }
  }
}

angular.module('app.water').controller('IrrigationPlanController', IrrigationPlanController);
