import * as angular from 'angular';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { AccountsService } from '@services/administration/accounts.service';
import { BaseController } from 'src/app/base.controller';

export class IrrigationOptimiseDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _interval: angular.IIntervalService;
  private _accountsService: AccountsService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  public ignoreScheduledDays = false;
  public isSyncMaximumPercent = false;
  public isIncludeLockedSites = false;
  public isSyncInfoShowed = false;
  public isIncludeInfoShowed = false;
  public isOptimising = false;
  public minimumDate: Date;
  public maximumDate: Date;
  public isProgressCancelled = false;
  public optimisingPercent: number;
  public optimisingMessage: string;

  private _planId: number;
  private startFromDate: Date;
  private optimisingInterval: angular.IPromise<void>;
  private invocationKey: string;
  private irrigationPlanInfos = [] as fuse.planIrrigationInfo[];

  constructor(
    $interval: angular.IIntervalService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
    irrigationPlanInfos: fuse.planIrrigationInfo[],
    planId: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._interval = $interval;
    this._mdDialog = $mdDialog;
    this._accountsService = AccountsService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this._planId = planId;
    this.irrigationPlanInfos = irrigationPlanInfos;

    $scope.$on('$destroy', () => {
      this._interval.cancel(this.optimisingInterval);
      this.optimisingInterval = null;
    });
  }

  $onInit() {
    const localeTodayDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.minimumDate = this._dayNumberService.convertDayNumberToDate(localeTodayDayNumber);
    this.startFromDate = this.minimumDate;
    this.maximumDate = this.minimumDate.clone().addDays(6);
  }

  public optimise() {
    const optimiseProgress = () => {
      const model = {} as fuse.irrigationOptimiseProgressDto;

      model.invocationKey = this.invocationKey;
      model.cancelled = this.isProgressCancelled;

      this._accountsService.getIrrigationOptimiseProgress(model).then((res: fuse.optimisingProgressDetails) => {
        if (!res.cancelled) {
          this.optimisingPercent = res.optimisingPercent;
          this.optimisingMessage = this._languageService.instant(res.message);
        } else {
          this._interval.cancel(this.optimisingInterval);
          this.optimisingInterval = null;
          this.optimisingMessage = this._languageService.instant('COMMON.CANCELLING');
        }
      });
    };

    this.isOptimising = true;
    this.optimisingMessage = this._languageService.instant('COMMON.INITIALISING');
    this.invocationKey = this.guidGenerator();
    this.isProgressCancelled = false;
    this.optimisingPercent = 0;
    this.optimisingInterval = this._interval(optimiseProgress, 2000, 0, true, this);

    const model = {} as fuse.irrigationOptimiseDto;

    model.invocationKey = this.invocationKey;
    model.accountId = this.accountId;
    model.planId = this._planId;
    model.startFromDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.startFromDate);
    model.ignoreScheduledDays = this.ignoreScheduledDays;
    model.isSyncMaximumPercent = this.isSyncMaximumPercent;
    model.isIncludeLockedSites = this.isIncludeLockedSites;
    model.irrigationPlanInfos = this.irrigationPlanInfos;

    this._accountsService.irrigationOptimise(model).then((res: fuse.irrigationOptimiseDto) => {
      this._interval.cancel(this.optimisingInterval);
      this.optimisingInterval = null;

      if (res.succeeded) {
        this._mdDialog.hide({ dataRefreshRequired: true, irrigationPlanInfos: res.irrigationPlanInfos });

        let message = this._languageService.instant('WATER.IRRIGATION.OPTIMISE_DIALOG.SUCCESS_MESSAGE1');

        if (res.message) {
          message += this._languageService.instant('WATER.IRRIGATION.OPTIMISE_DIALOG.SUCCESS_MESSAGE2', {
            warning: res.message,
          });
        }

        if (this.apf.hasIrrigationPlanFull) {
          message += this._languageService.instant('WATER.IRRIGATION.OPTIMISE_DIALOG.SUCCESS_MESSAGE3');
        }

        this._languageService.successMessage(message);
      } else {
        this.isOptimising = false;
        this._languageService.warning(res.message);
      }
    })
    .catch((error) => {
      this.isOptimising = false;
      this._interval.cancel(this.optimisingInterval);
      this.optimisingInterval = null;
      this._languageService.error('WATER.IRRIGATION.OPTIMISE_DIALOG.FAILED_MESSAGE');
    })
    .finally(() => {});
  }

  public cancelOptimise() {
    this.isProgressCancelled = true;
  }

  public closeDialog() {
    this._interval.cancel(this.optimisingInterval);
    this.optimisingInterval = null;
    this._mdDialog.hide({ dataRefreshRequired: false });
  }

  public isSyncMaximumPercentChanged() {
    if (this.isSyncMaximumPercent == false) this.isIncludeLockedSites = false;
  }

  public toggleSyncInfo(event: MouseEvent) {
    this.isSyncInfoShowed = !this.isSyncInfoShowed;
    event.stopPropagation();
  }

  public toggleIncludeInfo(event: MouseEvent) {
    this.isIncludeInfoShowed = !this.isIncludeInfoShowed;
    event.stopPropagation();
  }

  private guidGenerator(): string {
    const S4 = () => ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
    return S4() + S4() + S4() + S4() + S4() + S4();
  }
}

angular.module('app.water').controller('IrrigationOptimiseDialogController', IrrigationOptimiseDialogController);
