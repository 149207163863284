// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#irrigation-logs md-input-container {
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  width: 160px;
}
#irrigation-logs table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
#irrigation-logs table thead tr {
  text-align: left;
  background-color: aliceblue;
  border-bottom: 1px solid #ccc;
}
#irrigation-logs table thead tr th {
  padding: 12px;
  color: #000;
}
#irrigation-logs table thead tr th:first-child {
  min-width: 120px;
}
#irrigation-logs table thead tr th:nth-child(2) {
  min-width: 180px;
}
#irrigation-logs table tbody tr {
  border-bottom: 1px solid #ccc;
}
#irrigation-logs table tbody tr td {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/irrigation-plan/irrigation_logs.scss"],"names":[],"mappings":"AACI;EACI,SAAA;EACA,UAAA;EACA,sBAAA;EACA,YAAA;AAAR;AAEI;EACI,WAAA;EACA,iBAAA;EACA,yBAAA;AAAR;AAEY;EACI,gBAAA;EACA,2BAAA;EACA,6BAAA;AAAhB;AACgB;EACI,aAAA;EACA,WAAA;AACpB;AAAoB;EACI,gBAAA;AAExB;AAAoB;EACI,gBAAA;AAExB;AAIY;EACI,6BAAA;AAFhB;AAGgB;EACI,aAAA;AADpB","sourcesContent":["#irrigation-logs {\r\n    md-input-container {\r\n        margin: 0;\r\n        padding: 0;\r\n        vertical-align: bottom;\r\n        width: 160px;\r\n    }\r\n    table {\r\n        width: 100%;\r\n        border-spacing: 0;\r\n        border-collapse: collapse;\r\n        thead {\r\n            tr {\r\n                text-align: left;\r\n                background-color: aliceblue;\r\n                border-bottom: 1px solid #ccc;\r\n                th {\r\n                    padding: 12px;\r\n                    color: #000;\r\n                    &:first-child {\r\n                        min-width: 120px;\r\n                    }\r\n                    &:nth-child(2) {\r\n                        min-width: 180px;\r\n                    }\r\n                }\r\n            }\r\n        }\r\n        tbody {\r\n            tr {\r\n                border-bottom: 1px solid #ccc;\r\n                td {\r\n                    padding: 12px;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
