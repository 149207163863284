// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#set-plan-irrigation-dialog md-input-container {
  margin: 4px;
  padding: 0;
  vertical-align: bottom;
}
#set-plan-irrigation-dialog md-input-container md-select {
  width: 160px;
  text-align: center;
}
#set-plan-irrigation-dialog md-input-container input {
  width: 120px;
  text-align: center;
}
#set-plan-irrigation-dialog md-input-container input .md-errors-spacer {
  min-height: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/irrigation-plan/setPlanIrrigation-dialog.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,UAAA;EACA,sBAAA;AAAR;AACQ;EACI,YAAA;EACA,kBAAA;AACZ;AACQ;EACI,YAAA;EACA,kBAAA;AACZ;AAAY;EACI,aAAA;AAEhB","sourcesContent":["#set-plan-irrigation-dialog {\r\n    md-input-container {\r\n        margin: 4px;\r\n        padding: 0;\r\n        vertical-align: bottom;\r\n        md-select {\r\n            width: 160px;\r\n            text-align: center;\r\n        }\r\n        input {\r\n            width: 120px;\r\n            text-align: center;\r\n            .md-errors-spacer {\r\n                min-height: 0;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
