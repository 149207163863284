// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#allocation-summary-info {
  padding: 8px;
  margin: 8px;
  border: #555 solid 1px;
}
#allocation-summary-info form > div {
  width: 100%;
}
#allocation-summary-info form md-input-container {
  margin: 0;
  vertical-align: bottom;
  min-width: 100px;
}
#allocation-summary-info form md-input-container label {
  font-weight: bold;
}
#allocation-summary-info form md-input-container .md-errors-spacer {
  min-height: 0px;
}
#allocation-summary-info form md-input-container md-datepicker {
  display: block;
}
#allocation-summary-info form md-input-container md-datepicker .md-datepicker-input {
  min-width: 100px;
}
#allocation-summary-info form md-input-container md-datepicker .md-datepicker-button {
  display: none;
}
#allocation-summary-info form md-label {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/allocation/components/allocation-summary-info.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,sBAAA;AACF;AAEI;EACE,WAAA;AAAN;AAGI;EACE,SAAA;EACA,sBAAA;EACA,gBAAA;AADN;AAEM;EACE,iBAAA;AAAR;AAGM;EACE,eAAA;AADR;AAIM;EACE,cAAA;AAFR;AAIQ;EACE,gBAAA;AAFV;AAKQ;EACE,aAAA;AAHV;AAQI;EACE,iBAAA;AANN","sourcesContent":["#allocation-summary-info {\r\n  padding: 8px;\r\n  margin: 8px;\r\n  border: #555 solid 1px;\r\n\r\n  form {\r\n    >div {\r\n      width: 100%;\r\n    }\r\n\r\n    md-input-container {\r\n      margin: 0;\r\n      vertical-align: bottom;\r\n      min-width: 100px;\r\n      label {\r\n        font-weight:bold;\r\n      }\r\n\r\n      .md-errors-spacer {\r\n        min-height: 0px;\r\n      }\r\n\r\n      md-datepicker {\r\n        display: block;\r\n\r\n        .md-datepicker-input {\r\n          min-width: 100px;\r\n        }\r\n\r\n        .md-datepicker-button {\r\n          display: none;\r\n        }\r\n      }\r\n    }\r\n\r\n    md-label {\r\n      font-weight: bold;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
