// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#irrigation-help-dialog p {
  padding-left: 1.5em;
  text-indent: -1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/irrigation-plan/irrigationHelp-dialog.scss"],"names":[],"mappings":"AACI;EACI,mBAAA;EACA,mBAAA;AAAR","sourcesContent":["#irrigation-help-dialog {\r\n    p {\r\n        padding-left: 1.5em;\r\n        text-indent: -1.5em;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
