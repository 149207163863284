import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { TimeService } from '@services/time.service';
import { ProgramDay } from './irrigation-controller.controller';
import { IrrigationProtocol } from '../../../_DBContext/IrrigationProtocol';
import { BaseController } from 'src/app/base.controller';

/** Depending on arguments, may be used to 1) add new controller program protocol, 2) edit existing protocol, 3) send program to controller */
export class ProtocolDialogController extends BaseController {
  public name: string;
  public nameExists: boolean;
  public regexTimeInput: RegExp;
  public regexTimeInputMin5Minutes: RegExp;

  public fertOpts = ['First', 'Middle', 'Last'];
  public timeInputs = ['WindowStart', 'WindowEnd', 'MinimumPulseMinutes', 'GapMinutes'];
  public Times = {}; // Stores time inputs in HH:mm string format
  public protocol: IrrigationProtocol;
  public protocols: IrrigationProtocol[] = null;
  public programDays: ProgramDay[] = [];
  public irrigStartTime: number;

  public timeInputError = 'CONTROLLERS.ERROR_HHMM';
  public time5minError = 'CONTROLLERS.ERROR_HHMM_RANGE';
  public irrigationDayErr: any;

  public title = 'CONTROLLERS.ADD_PROGRAM';
  public buttonText = 'COMMON.SAVE';
  public sendProgramMode = false;
  public daysString: string;
  public accountCurrentTime: Date;
  public includesCurrentDay: boolean;
  public currentDay: number;
  public invalidWindow: boolean;
  public fertigationEnabled: boolean;
  public showError = false;
  public errorMsg: string;

  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _timeService: TimeService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    TimeService: TimeService,
    params,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._timeService = TimeService;

    this.regexTimeInput = SWANConstants.RegexTimeInput;
    this.regexTimeInputMin5Minutes = SWANConstants.RegexTimeInputMin5Minutes;
    this.fertigationEnabled = params.fertigationEnabled;

    // If list of protocols provided, we're in Send mode
    this.protocols = params.protocols;
    this.sendProgramMode = !!params.protocols;
    this.irrigStartTime = params.irrigStartTime;

    if (params.days) {
      this.programDays = params.days;
      this.daysString = params.days.map((d) => d['date']).join('<br>');
    }

    let isAddProtocol = false;

    if (params.protocol) {
      this.protocol = params.protocol;
    } else {
      isAddProtocol = true;

      this.protocol = new IrrigationProtocol();
      this.protocol.WindowEnd = this.protocol.WindowStart = TimeService.timeSpanFormat(params.irrigStartTime);
      this.protocol.AssetId = params.assetId;
    }

    this.setTitles(isAddProtocol);
    this.formatTimeInputs();

    this.errorMsg = 'CONTROLLERS.' + (this.sendProgramMode ? 'FIX_BEFORE_SEND' : 'FIX_BEFORE_SAVE');
  }

  $onInit() {
    this.accountCurrentTime = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId); // today - now
    this.currentDay = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.includesCurrentDay = !!this.programDays.find((pd) => pd.dayNumber == this.currentDay);
  }

  /** Check that irrigation window doesn't cross irrigation day start */
  public irrigationWindowCheck() {
    const element = this.scope['protocolForm'].WindowStart;

    this.invalidWindow = false;

    if (element.$error['pattern']) {
      return;
    }

    const windowStart = this._timeService.parseToTotalMinutes(this.Times['WindowStart']);
    let windowEnd = this._timeService.parseToTotalMinutes(this.Times['WindowEnd']);
    let dayCutoff = this.irrigStartTime;

    if (dayCutoff < windowStart) {
      dayCutoff += 1440;
    }

    if (windowEnd <= windowStart) {
      windowEnd += 1440;
    }

    let err = null;

    if (windowStart < dayCutoff && windowEnd > dayCutoff) {
      this.invalidWindow = true;

      err = this._languageService.instant('CONTROLLERS.ERROR_DAY_CUTOFF', {
        time: this._timeService.timeString(this.irrigStartTime, true),
      });
    } else if (this.sendProgramMode && this.includesCurrentDay && this.invalidWindowStart()) {
      this.invalidWindow = true;

      err = this._languageService.instant('CONTROLLERS.ERROR_PAST_WINDOW');
    }

    return this.setError(element, err, true);
  }

  public handleSelectProtocol() {
    this.formatTimeInputs();
    this.irrigationWindowCheck();
  }

  public saveOrSend() {
    if (!this.irrigationWindowCheck() || !this.checkValid()) {
      return;
    }

    this.timeInputs.forEach((input) => {
      if (this.Times[input] == '24:00') this.Times[input] = '00:00';

      // Saved as Breeze object, but send-program takes timespan inputs in different string format.
      this.protocol[input] = this._timeService.timeSpanFormat(this.Times[input]);
      this.protocol[input + 'Display'] = this.Times[input];
    });

    this._mdDialog.hide(this.protocol);
  }

  public closeDialog() {
    this.protocol.entityAspect?.rejectChanges();
    this._mdDialog.hide();
  }

  private setError(element, err, forceShow = false) {
    if (!element) {
      return true;
    }

    if (err) {
      element.$error = { err: true };
      element.$invalid = true;

      if (isNaN(err)) {
        this.irrigationDayErr = err;
      }

      if (forceShow) {
        element.$setTouched();
      }

      return false;
    } else {
      element.$error = {};
      element.$invalid = false;
      element.$validate(); // seems to be necessary to force dropdown lists to clear errors

      return true;
    }
  }

  /** Today's irrigation cannot be set to start before current time, or less than 20 minutes from now */
  private invalidWindowStart() {
    if (this.includesCurrentDay) {
      let protocolStartMins = this._timeService.parseToTotalMinutes(this.Times['WindowStart']);

      if (protocolStartMins < this.irrigStartTime) {
        // If window start < irrigation start, then this is effectively tomorrow
        protocolStartMins += 1440;
      }

      let currentTimeMins = this.accountCurrentTime.getMinutes() + this.accountCurrentTime.getHours() * 60;

      if (currentTimeMins < this.irrigStartTime) {
        currentTimeMins += 1440;
      }

      return protocolStartMins <= currentTimeMins + 20;
    }

    return false;
  }

  private formatTimeInputs() {
    this.timeInputs.forEach((input) => {
      this.Times[input] = this._timeService.toReadable(this.protocol[input] || 0);
    });
  }

  // Force check on all form inputs and return true only if all valid
  private checkValid() {
    angular.forEach(this.scope['protocolForm'], (field) => {
      if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
        field.$setDirty();
        field.$setTouched();
      }
    });

    if (this.scope['protocolForm'].$invalid || this.invalidWindow) {
      this.showError = true;

      return false;
    }

    return true;
  }

  private setTitles(isAddProtocol: boolean) {
    this.title = 'CONTROLLERS.ADD_PROGRAM';
    this.buttonText = 'COMMON.SAVE';

    if (this.sendProgramMode) {
      this.title = 'CONTROLLERS.SEND_PROGRAM';
      this.buttonText = 'CONTROLLERS.SEND';
    } else if (this.protocol) {
      this.title = isAddProtocol ? 'CONTROLLERS.ADD_PROTOCOL' : 'CONTROLLERS.EDIT_PROTOCOL';
    }
  }
}

angular.module('app.water').controller('ProtocolDialogController', ProtocolDialogController);
