import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class IrrigationHelpDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  public title: string;
  public keyword: string;
  public fluidDepthNormalUnit: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    keyword: string,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.keyword = keyword;
    this.title = LanguageService.instant(`WATER.IRRIGATION.HELP_DIALOG.${keyword.toUpperCase()}`);
    this.fluidDepthNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.water').controller('IrrigationHelpDialogController', IrrigationHelpDialogController);
