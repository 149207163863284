import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class AddSiteInPlanDialogController extends BaseController {
  public dialogSites = [] as fuse.irrigationPlanSite[];

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    dialogSites: fuse.irrigationPlanSite[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.dialogSites = dialogSites;
  }

  public addPlanSites() {
    this._mdDialog.hide({ dataRefreshRequired: true, dialogSites: this.dialogSites });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public isDialogValid(): boolean {
    return this.dialogSites.some((a) => a.isInPlan);
  }
}

angular.module('app.water').controller('AddSiteInPlanDialogController', AddSiteInPlanDialogController);
