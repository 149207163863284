import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class AddPlanDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private irrigationPlans = [] as fuse.irrigationPlanInfoDto[];
  public planName: string;
  public planMinimumVolume: number;
  public planMaximumVolume?: number;
  public planNameExists = false;
  public volumeNormalUnit: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    irrigationPlans: fuse.irrigationPlanInfoDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.irrigationPlans = irrigationPlans;
    this.volumeNormalUnit = this._unitOfMeasureService.getUnitLabel('Volume', unitSizes.normal);
  }

  public addPlan() {
    const newPlanInfo = {
      assetName: this.planName,
      minimumVolume: this.planMinimumVolume,
      maximumVolume: this.planMaximumVolume,
    } as fuse.irrigationPlanInfoDto;
    this._mdDialog.hide(newPlanInfo);
  }

  public closeDialog() {
    if (this.scope['addNewPlanForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // cancel,
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public checkPlanName() {
    if (this.planName) {
      if (this.irrigationPlans.some((a) => a.assetName.toLowerCase() == this.planName.toLowerCase())) {
        this.planNameExists = true;
      } else {
        this.planNameExists = false;
      }
    } else {
      this.planNameExists = false;
    }
  }
}

angular.module('app.water').controller('AddPlanDialogController', AddPlanDialogController);
