// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#irrigation-settings md-input-container {
  margin: 0;
  padding: 0;
  width: 160px;
}
#irrigation-settings md-input-container .md-errors-spacer {
  min-height: 0px;
}
#irrigation-settings #setting-form > div {
  padding-left: 24px;
  border-bottom: 1px solid #ccc;
  min-height: 48px;
}
#irrigation-settings #override-table table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
#irrigation-settings #override-table table thead tr {
  height: 48px;
  background-color: #666;
}
#irrigation-settings #override-table table thead tr th {
  color: #fff;
  text-align: center;
}
#irrigation-settings #override-table table tbody tr {
  border-bottom: 1px solid #ccc;
}
#irrigation-settings #override-table table tbody tr td {
  text-align: center;
}
#irrigation-settings #override-table table tbody tr td .md-input {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/irrigation-plan/irrigation_settings.scss"],"names":[],"mappings":"AACI;EACI,SAAA;EACA,UAAA;EACA,YAAA;AAAR;AACQ;EACI,eAAA;AACZ;AAEI;EACI,kBAAA;EACA,6BAAA;EACA,gBAAA;AAAR;AAGQ;EACI,WAAA;EACA,iBAAA;EACA,yBAAA;AADZ;AAGgB;EACI,YAAA;EACA,sBAAA;AADpB;AAEoB;EACI,WAAA;EACA,kBAAA;AAAxB;AAKgB;EACI,6BAAA;AAHpB;AAIoB;EACI,kBAAA;AAFxB;AAGwB;EACI,kBAAA;AAD5B","sourcesContent":["#irrigation-settings {    \r\n    md-input-container {\r\n        margin: 0;\r\n        padding: 0;\r\n        width: 160px;\r\n        .md-errors-spacer {\r\n            min-height: 0px;\r\n        }\r\n    }\r\n    #setting-form > div {\r\n        padding-left: 24px;\r\n        border-bottom: 1px solid #ccc;\r\n        min-height: 48px;\r\n    }\r\n    #override-table {\r\n        table {\r\n            width: 100%;\r\n            border-spacing: 0;\r\n            border-collapse: collapse;\r\n            thead {\r\n                tr {\r\n                    height: 48px;\r\n                    background-color: #666;\r\n                    th {\r\n                        color: #fff;\r\n                        text-align: center;\r\n                    }\r\n                }\r\n            }\r\n            tbody {\r\n                tr {\r\n                    border-bottom: 1px solid #ccc;\r\n                    td {\r\n                        text-align: center;\r\n                        .md-input {\r\n                            text-align: center;\r\n                        }\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
