import * as angular from 'angular';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { IIrrigationReportSetting } from '../WaterConstants';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class PrintIrrigationPlanDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _localStorageService: LocalStorageService;

  public irrigationReportSetting = {} as IIrrigationReportSetting;
  public fluidDepthNormalUnit: string;
  public volumeNormalUnit: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    LocalStorageService: LocalStorageService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._localStorageService = LocalStorageService;

    this.irrigationReportSetting = this._localStorageService.get('irrigationReportSetting');
    this.volumeNormalUnit = UnitOfMeasureService.getUnitLabel('Volume', unitSizes.normal);
    this.fluidDepthNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
  }

  public print() {
    this._localStorageService.set('irrigationReportSetting', this.irrigationReportSetting);
    this._mdDialog.hide({ print: true });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.water').controller('PrintIrrigationPlanDialogController', PrintIrrigationPlanDialogController);
